import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { withTheme } from 'styled-components';
import {
	getSectionMemo,
	getCurrentQuestionSet,
} from '../../redux/selector/questions';
import QuestionLibrary from '../../Components/QuestionsLib/QuestionLibrary';
import Content from '../../layout/content';
import { ButtonPrimary } from '../../Components/UIElements';

const NewBuildMessageContainer = styled.div`
	display: flex;
	text-align: center;
	font-size: 24px;
	height: 65vh;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

const NewBuildMessageText = styled.div`
	margin-bottom: 25px;
	width: 80%;
`;

const btnStyles = {
	'min-width': '200px', 
}

const onClickHandler = () => {
	if ('caches' in window) {
		window.caches.keys().then((names) => {
			names.forEach((name) => {
				window.caches.delete(name);
			});
		});
	}

	window.location.reload(true);
}

function NewQuestions(props) {
	const { leftData, loading, isReviewRoute, reload, questionsLayout, showNewBuildMessage } = props;
	const {
		theme: {
			pageLayout,
			pageId,
			config: {
				theme: {
					global: {
						background,
					},
				},
			},
		},
	} = props;
	if (showNewBuildMessage) {
		return (
		<NewBuildMessageContainer>
			<NewBuildMessageText>
			A new version of this app is deployed. 
			Please click reload button to continue the application with the latest updates.
			</NewBuildMessageText>
			<ButtonPrimary 
				label='Reload'
				btnType='rounded'
				style={ btnStyles }
				onClick={ onClickHandler }
			/>
		</NewBuildMessageContainer>
		);
	}

	return (
		<Content
			leftData={ leftData }
			loading={ loading }
			reload={ reload }
			layout={ questionsLayout || pageLayout }
			background={ background }
		>
			<QuestionLibrary
				isReviewRoute={ isReviewRoute }
				layout={ questionsLayout || pageLayout }
				page=""
				pageId={ pageId === 'questions' ? 'q' : pageId }
				{ ...props }
			/>
		</Content>
	);
}

function mapStateToProps(state, ownProps) {
	const currentQuestionSet = getCurrentQuestionSet(state);
	const sectionMem = getSectionMemo(state);
	const sectionData = sectionMem(ownProps);
	const showNewBuildMessage = get(state, 'isNewBuildAvailable', false);
	let leftData;
	for (let i = 0; i < sectionData.length; i += 1) {
		const question = sectionData[i];
		const leftQuestionList = get(question, 'leftQuestionList', []);
		if (leftQuestionList.length > 0) {
			leftData = leftQuestionList;
		}
	}
	return {
		leftData,
		questions:currentQuestionSet && currentQuestionSet.questionList,
		uid: state.uid,
		loading: state.questions.isLoading,
		reload: state.questions.reload,
		questionsLayout: state.questions.layout,
		showNewBuildMessage,
	};
}

export default withTheme(withRouter(
	connect(mapStateToProps, null)(NewQuestions),
));
