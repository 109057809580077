/* eslint-disable import/no-extraneous-dependencies,react/no-danger */
import React from 'react';
import { Button } from 'crux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import './ReviewLabel.css';
import { ErrorTypes } from '../../../util';
import { getNextQuestionSet } from '../../../redux/actions';
import { validateInnerHtml } from '../../../utils';
import { getCurrentQuestionSet } from '../../../redux/selector/questions';

const ButtonWrapper = styled.div`
  margin-bottom: 30px;
  & > button {
    padding-right: 0;
  }
`;
class ReviewLabel extends React.Component {
  componentDidMount() {
    const {
      questionObj: { 
        question_id: questionId,
        response
      }
    } = this.props;

    if (response) {
      const { handleLabelChange } = this.props;
      handleLabelChange(questionId, response, ErrorTypes.noError);
    }
  }

  render() {
    const {
      questionObj: {
        question_id: questionId,
        question_text: questionText,
        question_type: questionType,
        is_edit_icon_visible: isEditable,
        presentation_type: presentationType,
        orientation,
        response,
      },
      uid,
      isReview,
      makeAPICall,
      isSmallScreen,
    } = this.props;

    let _orientation = orientation;
    if (isReview) {
      _orientation = 'rows';
    }
    if (questionId.match('Signature') || isSmallScreen) {
      _orientation = 'columns';
    }
    let responseText = response;
    if (response) {
      const { label } = response;
      if (label) responseText = label;
    }

    const displayUpdateButton = questionType === 'group' && isEditable;
    const handleUpdate = () => {
      const params = {
        uid,
        q_id: questionId,
      };
      makeAPICall(params);
    };

    const getQuestionTextClass = () => {
      let className = 'display_review_question ';
      if (questionType === 'group') className += 'group-question ';
      else if (questionType === 'text' && presentationType !== 'review') className += '';
      else className += 'display_bold';
      return className;
    };

    return (
      <div key={ questionId }>
        <div className={ `display_review_flex_center ${_orientation}` }>
          <div
            className={ getQuestionTextClass() }
            htmlFor={ questionId }
            dangerouslySetInnerHTML={ { __html: questionText } }
          />
          {displayUpdateButton ? (
            <ButtonWrapper>
              <Button
                label="Update"
                type="text"
                handleButtonClick={ handleUpdate }
              />
            </ButtonWrapper>
          ) : (
            <div
              className={ `display_review_answer ${_orientation}` }
              dangerouslySetInnerHTML={ {__html: validateInnerHtml(responseText) } }
            />
          )}
        </div>
        <div className="review-dotted-line-break" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { review } = getCurrentQuestionSet(state);
  return {
    isReview: review && review.isReview,
    uid: state.uid,
    isSmallScreen: state.meta.isSmallScreen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    makeAPICall: (params) => {
      dispatch(getNextQuestionSet('/questions', params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewLabel);
