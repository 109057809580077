// import postQuestion from "../actions/questionAction";
import get from 'lodash/get';
import last from 'lodash/last';
import { 
	GET_NEW_QUESTION, 
	API_IN_PROGRESS, 
	UPDATE_QUESTION_ANSWER, 
	GET_NEW_QUESTION_START, 
	GET_QUESTIONS_SUCCESS, 
	SET_CURRENT_BREADCRUMB, 
	GET_REVIEW_SUCCESS, 
	GET_QUOTE_SUCCESS,
	GET_QUESTION_FAILURE,
	GET_DOCUMENT_SUCCESS,
	GET_PLAN_SUCCESS,
	GET_QUOTEREVIEW_SUCCESS,
	GET_SIGNATURE_SUCCESS,
	GET_PAYMENT_SUCCESS,
	SET_ACTION_SUCCESS_MESSAGE,
	SET_ACTION_FAILURE_MESSAGE,
	GET_TIMEOUT_SUCCESS,
	GET_CHALLENGE_SUCCESS,
	GET_RIDERS_SUCCESS,
	GET_COMPLETED_SUCCESS,
	GET_LOGIN_SUCCESS,
	GET_KNOCKOUT_SUCCESS,
	GET_PROCESSING_SUCCESS,
	GET_UNSUBSCRIBED_SUCCESS,
	UPDATE_DATA_SELECTOR_OPTIONS,
	TOGGLE_ESIGN_BUTTON,
	UPDATE_LIST_SECTION,
	GET_PRODUCTS_SUCCESS,
	SET_POPUP_DETAILS,
	GET_INTERVIEW_SUCCESS,
	GET_LAUNCH_SUCCESS,
	SET_MEMBER_DATA_INDEX,
	GET_STATIC_SUCCESS,
	SET_SINGLE_SELECT_FOCUS,
	SET_CSRF_TOKEN
} from '../types';
import HELPER, { getCurrentBreadcrumb } from './helper';
import { 
	isListQuestion, 
	getListDisplayMode, 
	hasListItems, 
	getAddButtonText, 
	getQuestionStatus,
} from '../actions/listQuestionHelper';

const initialState = {
	breadcrumbs: {
		currentBreadcrumb: {},
		isBreadcrumbLoaded: true,
		breadcrumbList: [],
	},
	isLoading: true,
	currentPageSeqNumber: 0,
	sectionIndex: 0,
	isListAddAction: false,
	activeSubsectionId: '',
	actionMessage: {
		type: '',
		message: '',
	},
	eSign: {
		hasESign: false,
		eSignEnable: false,
	},
	loader: {
		message: 'Just a moment...',
	},
	otherParams: {},
	memberDataIndex: null,
	sections: {
		'testSections': [
			{
				id: '12345',
				answersArray: [],
				errorsArray: [],
				answeredListArray:[],
				otherParams: {},
				errors: {},
				pageDesc: {},
				pageSeqNumber: 0,
				questionList: {},
				leftQuestionList: [],
				layoutRatio: {},
				requiredSchema: {},
				enableContinue: false,
				list: {
					isList: false,
					listDisplay: false,
					hasItems: false,
					addButtonText: 'Add',
					originalQuestions: [],
					questionObj: {},
				},
				listIndex: -1,
				listEditIndex: -1,
				listEditQId: '',
				questionObj: {},
			},
		],
	},
	popupQuestions: [],
	popupDetails: { show: false, question: '' },
	csrfToken: "<default-token>",
	ssoLogin: {
		enable: false
	}
};

const updateBreadcrumbs = (breadcrumbs = {}, payload) => {
	const breadcrumbList = payload.reduce((acc, breadcrumb) => {
		acc.push({
			...breadcrumb,
			id:
				breadcrumb.breadcrumb_id.toLowerCase() ||
				breadcrumb.title.replace(/ /g, '').toLowerCase(),
		});
		return acc;
	}, []);
	return {
		...breadcrumbs,
		breadcrumbList,
		currentBreadcrumb: getCurrentBreadcrumb(breadcrumbList),
		isBreadcrumbLoaded: true,
	}
}

const getLeftAndRightData = (questionItem, skip) => {
	const layout = get(questionItem, 'properties.layout', undefined);
	let leftQuestionList;
	let rightQuestionList;
	if(!skip && layout && layout.value === 'two_columns') {
		const allQuestions = get(questionItem, 'questions', []);
		for (let i=0; i< allQuestions.length; i+=1) {
			const question = allQuestions[i];
			const displayPosition = get(question, 'properties.display_position.value', '');
			if(displayPosition === 'left') {
				leftQuestionList = HELPER.insertReflexiveQuestion(question)
			} else if(displayPosition === 'right') {
				rightQuestionList = HELPER.insertReflexiveQuestion(question);
			}
		}
		return {
			layoutRatio: layout.size,
			leftQuestionList,
			rightQuestionList,
		};
	}
	rightQuestionList = HELPER.insertReflexiveQuestion(questionItem);
	return { layoutRatio: {}, leftQuestionList: [], rightQuestionList }
}

const getFormattedReviewResponse = (questionObj) => {
	const questionsList = [];
	if (questionObj && questionObj.length) {
		questionObj.forEach(ques => {
			if (ques.display_type === 'questions_group') {
				const breadcrumbQuestions = ques.questions;
				// const breadcrumbGroup = { ...ques, questions: [] };
				// questionsList.push(breadcrumbGroup);
				questionsList.push(...[
					{
						...ques,
						isBreadcrumbCard: true,
						questions: [],
					},
					...breadcrumbQuestions,
				]);
			}
			else questionsList.push(ques);
		})
	}
	return questionsList;
}

const isReviewSection = (questionsList) => {
	if (questionsList && questionsList.length) {
		for(let i = 0; i < questionsList.length; i += 1) {
			if(questionsList[i].presentation_type === 'review') return true;
		}
	}
	return false;
}

const resetBreadcrumbData = (action) => {
	const { payload, type } = action;
	const currentStatus = get(payload, 'other_params.current_status', '');
	const questionObj = get(payload, 'data.questionnarie.questions', {});

	let allQuestions = [];
	allQuestions = get(payload, 'data.questionnarie.questions.questions', []);

	const isReviewStatus = currentStatus === 'review' || currentStatus === 'quotereview';
	if (isReviewStatus) {
		const questionsList = getFormattedReviewResponse(allQuestions);
		allQuestions = questionsList;
	}

	return allQuestions.map((questionItem, index) => {
		const {
			layoutRatio,
			leftQuestionList,
			rightQuestionList,
		} = getLeftAndRightData(questionItem, type === GET_QUOTE_SUCCESS || isReviewStatus);
		const requiredSchema = HELPER.getRequiredSchema(rightQuestionList);
		const isList = isListQuestion(rightQuestionList, isReviewStatus);
		const originalQuestions = isList ? HELPER.getOriginalQuestions(questionItem) : [];
		const answersArray = HELPER.buildAnswersArray(rightQuestionList, originalQuestions);
		const listDisplay = getListDisplayMode(rightQuestionList);
		const questionStatus = getQuestionStatus(rightQuestionList);

		// Review Page Related Conditions
		const isReview = isReviewSection(rightQuestionList)
		const showReviewContinueButton = false;
		let enableContinue;
		if (isReviewStatus) {
			enableContinue = true;
		} else {
			enableContinue = HELPER.shouldEnableContinue(
				requiredSchema,
				answersArray,
				[],
				isList,
				listDisplay,
				questionStatus,
			);
		}
		return {
			id: HELPER.getRandomId(),
			questionList: rightQuestionList,
			answeredListQuestion: HELPER.getAnsweredLisQuestionIds(rightQuestionList),
			leftQuestionList,
			layoutRatio,
			requiredSchema,
			answersArray,
			errorsArray: [],
			enableContinue,
			dataSelector: new Map(),
			otherParams: {
				...payload.other_params,
			},
			errors: {
				...payload.errors,
			},
			pageDesc: {
				...payload.page_desc,
			},
			review: {
				isReview,
				showReviewContinueButton,
			},
			/**
			 * Completed would be false for last section so last section
			 * avoid sending index _modify_flag=1 this issue is caused
			 * when resetBreadcrumbData called when answering reflexive
			 * question in the last section in the
			 * page NAT-1162
			 */
			completed: allQuestions.length - 1 !== index,
			list: {
				isList,
				listDisplay,
				hasItems: hasListItems(rightQuestionList),
				addButtonText: getAddButtonText(rightQuestionList),
				questionObj: questionItem,
				originalQuestions,
			},
			questionObj,
		};
	});
}

const updatedBreadcrumbData = (currentBreadcrumbData = [], action) => {

	const { payload, params, type } = action;
	const questionObj = get(payload, 'data.questionnarie.questions', {});
	const currentStatus =  get(payload, 'other_params.current_status', '');
	const isReview = currentStatus === 'review' || currentStatus === 'quotereview';
    const hasModal = get(payload, 'other_params.has_modal', false);
	const breadcrumbQuestions = last(get(payload, 'data.questionnarie.questions.questions', null));
	const breadcrumbQLength = get(payload, 'data.questionnarie.questions.questions', []).length
	
	// Resets Breadcrumb Data for the following current_status routes
	const resetBreadcrumbRoutes = currentStatus === 'quote' || currentStatus === 'signature' || currentStatus === 'payment';

	/**
	 * If currentBreadcrumbData is an empty array and payload
	 * contains questions, then it means, user has refreshed
	 * the page. In this case all of the
	 * questions need to be copied, other attributes like
	 * required schema, answer
	 * array etc needs to be updated.
	 */
	if (
		isReview ||
		resetBreadcrumbRoutes ||
		currentBreadcrumbData.length === 0 ||
		params.isReflexive ||
		params.validate ||
		params.breadcrumb_nav_flag ||
		params.prev_page_flag ||
		params.reset_sections_data ||
		(breadcrumbQuestions && ('collapse' in breadcrumbQuestions)) ||
		breadcrumbQLength < currentBreadcrumbData.length ||
		hasModal
	) {
		return resetBreadcrumbData(action);
	}

	let questionList = [];
	if (breadcrumbQuestions) {
		let newBreadcrumbData = currentBreadcrumbData;
		const { isReflexive, sectionIndex } = params;
		if (isReflexive) {
			questionList = HELPER.insertReflexiveQuestion(breadcrumbQuestions);
			newBreadcrumbData = HELPER.replaceSectionQuestion(currentBreadcrumbData, questionList, sectionIndex);
			return newBreadcrumbData;
		}
		newBreadcrumbData = HELPER.removeDuplicate(currentBreadcrumbData, breadcrumbQuestions);
		const {
			layoutRatio,
			leftQuestionList,
			rightQuestionList,
		} = getLeftAndRightData(breadcrumbQuestions, type === 'GET_QUOTE_SUCCESS');
		const requiredSchema = HELPER.getRequiredSchema(rightQuestionList);
		const listDisplay = getListDisplayMode(rightQuestionList);

		let isList = false;
		const listError =  getQuestionStatus(rightQuestionList)
		if (Object.keys(layoutRatio).length === 0) {
			isList = isListQuestion(rightQuestionList, isReview);
		}

		const originalQuestions = isList ? HELPER.getOriginalQuestions(breadcrumbQuestions) : [];
		const answersArray = HELPER.buildAnswersArray(rightQuestionList, originalQuestions);

		return [
			...newBreadcrumbData,
			{
				id: HELPER.getRandomId(),
				questionList: rightQuestionList,
				answeredListQuestion: HELPER.getAnsweredLisQuestionIds(rightQuestionList),
				leftQuestionList,
				layoutRatio,
				requiredSchema,
				answersArray,
				errorsArray: [],
				enableContinue: isReview ? true : HELPER.shouldEnableContinue(requiredSchema, [], [], isList, listDisplay, listError),
				otherParams: {
					...payload.other_params,
				},
				errors: {
					...payload.errors,
				},
				pageDesc: {
					...payload.page_desc,
				},
				list: {
					isList,
					listDisplay,
					hasItems: hasListItems(rightQuestionList),
					addButtonText: getAddButtonText(rightQuestionList),
					questionObj: breadcrumbQuestions,
					originalQuestions,
				},
				questionObj,
			},
		]
	}
	return currentBreadcrumbData;
}

const listSectionUpdates = (state = {}, listQIds) => {
	const { errorsArray, answersArray, questionList } = state;
	let _errorsArray = errorsArray.filter((item) => !listQIds.includes(item.question_id))
	return {
		...state,
		errorsArray: _errorsArray,
		answersArray: answersArray.filter((item) => !listQIds.includes(item.question_id)),
		enableContinue: !getQuestionStatus(questionList) && _errorsArray.length === 0,
	}
}

const questionUpdates = (state = {}, action) => {
	const {
		questionId,
		response,
		questionResponse,
		isError,
		isHidden,
		propQuestion,
		reset,
		isControlledInput,
		listParentId,
		listIndex,
		eSign,
		isChangeEvent
	} = action.payload;
	const answersArray = HELPER.addOrReplace(
		state.answersArray,
		{
			question_id: questionId,
			answer: response,
			immediateParent: propQuestion ? propQuestion.immediateParent : '',
			parentId: propQuestion ? propQuestion.parentId : '',
			parentListQuestionId: get(action, 'payload.propQuestion.properties.parent_list_id', ''),
			reset,
			listParentId,
			listIndex,
		},
		'answer',
	);
	const nList = questionId.match(/_\$ureify_/g);
	const skipUpdate = nList && nList.length === 2;
	const questionList = skipUpdate ? state.questionList : 
						HELPER.addOrReplace(state.questionList, { question_id: questionId, is_hidden: isHidden || false }, 'is_hidden');
	const currentStatus = get(action, 'payload.other_params.current_status', '');
	const isList = isListQuestion(state.questionList, currentStatus === 'review');
	const listDisplay = getListDisplayMode(state.questionList);
	const listError = getQuestionStatus(state.questionList);
	const errorsArray = HELPER.addOrReplace(state.errorsArray, { question_id: questionId, isError }, 'isError')
						.filter((errorElement) => errorElement && errorElement.isError);

	/**
	 * isError is undefined when the page is reloaded
	 * disable continue if we there's any invalid question
	 */
	 let enableContinue = true;
	 if(isError === undefined && questionList){
		questionList.forEach(question => {
			const questionStatusMessage = get(question, 'question_status_message', '');
			if(!isChangeEvent && questionStatusMessage !== ''){
				enableContinue = false;
			}
		})
	 }

	switch (action.type) {
		case UPDATE_QUESTION_ANSWER:
			return {
				...state,
				questionList: HELPER.findAndUpdateResponse(questionList, questionId, response, questionResponse, isControlledInput),
				enableContinue: enableContinue && 
					HELPER.shouldEnableContinue(state.requiredSchema, answersArray, errorsArray, isList, listDisplay, listError, eSign),
				enableAdd: HELPER.shouldEnableContinue(state.requiredSchema, answersArray, errorsArray),
				answersArray,
				errorsArray,
			};
		default:
			return state;
	}
}

const getSelectorOptions = ( parentQuestion, currentSelectedOptions, currentOption) => {
	const responseOptions = get(parentQuestion.questions[0], 'response_options', []);
	const currentQuestionId = get(parentQuestion.questions[0], 'question_id', '');
	const sortedOption = [...currentOption].sort(HELPER.getSortOrder('target_question_id'));
	let isPresent = false;
	let dataSelectorOptions = currentSelectedOptions.get(currentQuestionId);
	let selectorIndex = -1;
	let count = 0;
	responseOptions.forEach(response => {
		count = 0;
		const sortedResponse = [...response].sort(HELPER.getSortOrder('target_question_id'));
		for(let index = 0; index < sortedResponse.length; index+=1) {
			if(sortedResponse[index].response.value === sortedOption[index].response.value ){
				count +=1;
			}
		}
		if(count === sortedOption.length){
			isPresent = true;
		}
	})
	if(Array.isArray(dataSelectorOptions)) {
	 	dataSelectorOptions.forEach((dataSelector, index) => {
			count = 0;
			const sortedResponse = [...dataSelector].sort(HELPER.getSortOrder('target_question_id'));;
			if(sortedResponse.length === currentOption.length) {
				for(let i = 0; i < sortedResponse.length; i+=1) {
					if(sortedResponse[i].target_question_id === sortedOption[i].target_question_id ){
						count +=1;
					}
				}
				if(count === sortedOption.length){
					selectorIndex = index;
				}
			}
		});
   	}
   	else {
		dataSelectorOptions = [];
   	}
	if(selectorIndex > -1){
		dataSelectorOptions.splice(selectorIndex, 1);
	}
	if(!isPresent) {
		dataSelectorOptions.push(currentOption);
		currentSelectedOptions.set(currentQuestionId, dataSelectorOptions);
	}
	return currentSelectedOptions;
}

const updateDataSelectorOptions = ( currentSection, action ) => {
   	let currentDataSelector = currentSection.dataSelector;
   	const currentOption = [];
   	const { parentQuestion } = action.payload;
   	const selectorOptions = get(parentQuestion.questions[0], 'selector_options', []);
   	selectorOptions.forEach(option => {
	    const answersArray = HELPER.getAnswersArray(currentSection.answersArray, option.id);
	    if(answersArray.length > 0){
		   currentOption.push({ target_question_id: option.id, response: { value : answersArray[0].answer } });
		}
	});
   	currentDataSelector = getSelectorOptions(parentQuestion, currentDataSelector, currentOption);
   	return { ...currentSection, dataSelector: currentDataSelector };
}


export default function questions(state = initialState, action) {
	let currentBreadcrumb = null;
	let breadcrumbs = {};
	let currentPageSeqNumber = -1;
	let prevSections = [];
	let updatedSectionQuestions;
	let subSectionId;
	let actionMessage = {};
	let bannerInfo='';
	let footerNotes=[];
	let custCareTel='';
	let policyExpiry='';
	let reviseButtonLabel='';
	let footerBanner=null;
	switch (action.type) {
		case GET_NEW_QUESTION_START:
			return {
				...state,
				isLoading: true,
				reload:action.params.reload,
				actionMessage: action.params.actionMessage,
				loadingSection: action.params.sectionIndex,
				isReflexive: action.params.isReflexive,
				validate: action.params.validate,
				isListAddAction: action.params.isListAddAction,
				breadcrumbNavigation: action.params.breadcrumbNavigation,
			}
		case GET_NEW_QUESTION:
		case GET_QUESTIONS_SUCCESS:
		case GET_DOCUMENT_SUCCESS:
			breadcrumbs = updateBreadcrumbs(state.breadcrumbs, get(action, 'payload.breadcrumbs'));
			currentBreadcrumb = breadcrumbs.currentBreadcrumb;
			currentPageSeqNumber = get(action, 'payload.data.questionnarie.page_sequence_number', -1);
			return {
				...state,
				currentPageSeqNumber,
				breadcrumbs,
				activeSubsectionId: currentBreadcrumb.id,
				otherParams: get(action, 'payload.other_params', {}),
				layout: get(action, 'payload.data.questionnarie.questions.properties.layout.value', 'double') === 'one_column' ? 'single' : '',
				sections: {
					...state.sections,
					[currentBreadcrumb.id]: updatedBreadcrumbData(state.sections[currentBreadcrumb.id], action),
				},
				isLoading: false,
				loader: {
					...state.loader,
					message: get(action, 'payload.other_params.message', 'Just a moment...'),
				},
				popupQuestions: get(action, 'payload.data.popup_questions', []),
				popupDetails: initialState.popupDetails,
				/* memberDataIndex is used only on 
				 * beneficiary prefill page, anytime we move away from that page, 
				 * this variable should be cleared. 
				 * Conservatively, whenever question api is called, this variable should be cleared. */
				memberDataIndex: null, 
			};
		case GET_QUOTE_SUCCESS:
		case GET_PLAN_SUCCESS:
		case GET_QUOTEREVIEW_SUCCESS:
		case GET_SIGNATURE_SUCCESS:
		case GET_PAYMENT_SUCCESS:
		case GET_TIMEOUT_SUCCESS:
		case GET_RIDERS_SUCCESS:
		case GET_COMPLETED_SUCCESS:
		case GET_LOGIN_SUCCESS:
		case GET_KNOCKOUT_SUCCESS:
		case GET_PROCESSING_SUCCESS:
		case GET_UNSUBSCRIBED_SUCCESS:
		case GET_PRODUCTS_SUCCESS:
		case GET_INTERVIEW_SUCCESS:
		case GET_CHALLENGE_SUCCESS:
		case GET_REVIEW_SUCCESS:
		case GET_STATIC_SUCCESS:
		case GET_LAUNCH_SUCCESS:
			bannerInfo=get(action,'payload.data.questionnarie.questions.header','');
			footerNotes=get(action,'payload.data.questionnarie.questions.footer_notes',[]);
			custCareTel=get(action,'payload.data.questionnarie.questions.cust_care_tel','');
			policyExpiry=get(action,'payload.data.questionnarie.questions.policy_expiry_date','');
			reviseButtonLabel=get(action,'payload.data.questionnarie.questions.revise_button_text','');
			footerBanner=get(action,'payload.data.questionnarie.questions.footer_banner',null);
			subSectionId = get(action,'payload.other_params.current_status', '');
			currentPageSeqNumber = get(action, 'payload.data.questionnarie.page_sequence_number', -1);
			return {
				...state,
				bannerInfo,
				footerNotes,
				custCareTel,
				policyExpiry,
				reviseButtonLabel,
				footerBanner,
				activeSubsectionId: subSectionId,
				currentPageSeqNumber,
				otherParams: get(action, 'payload.other_params', {}),
				breadcrumbs,
				sections: {
					...state.sections,
					[subSectionId]: updatedBreadcrumbData(state.sections[subSectionId], action),
				},
				isLoading: false,
				loader: {
					...state.loader,
					message: get(action, 'payload.other_params.message', 'Just a moment...'),
				},
				popupQuestions: get(action, 'payload.data.popup_questions', []),
				popupDetails: initialState.popupDetails,
			};
		case UPDATE_QUESTION_ANSWER:
			prevSections = state.sections;
			updatedSectionQuestions = questionUpdates(state.sections[action.activeSubsectionId][action.payload.sectionIndex], action);
			prevSections[action.activeSubsectionId][action.payload.sectionIndex] = updatedSectionQuestions;
			return {
				...state,
				sections: prevSections,
			};
		case UPDATE_LIST_SECTION:
			prevSections = state.sections;
			updatedSectionQuestions = listSectionUpdates(
				state.sections[action.activeSubsectionId][action.payload.sectionIndex],
				action.payload.listQIds,
			)
			prevSections[action.activeSubsectionId][action.payload.sectionIndex] = updatedSectionQuestions;
			return {
				...state,
				sections: prevSections,
			}
		case UPDATE_DATA_SELECTOR_OPTIONS:
            prevSections = state.sections;
			updatedSectionQuestions = updateDataSelectorOptions(state.sections[action.activeSubsectionId][action.payload.sectionIndex], action)
			return {
				...state,
				sections: prevSections,
			}
		case API_IN_PROGRESS:
			return {
				...state,
				isLoading: true,
				loadingSection: 0,
			}
		case SET_CURRENT_BREADCRUMB:
			return {
				...state,
				breadcrumbs: {
					...state.breadcrumbs,
					currentBreadcrumb: getCurrentBreadcrumb(state.breadcrumbs.breadcrumbList, action.id),
					breadcrumbList: state.breadcrumbs.breadcrumbList.map(
						(breadcrumb) =>  { return { ...breadcrumb, active: (breadcrumb.id === action.id) } }
					),
				},
			};
		case 'SET_LIST_INDEX':
			return {
				...state,
				listIndex: action.index,
			}
		case 'SET_REFLEXIVE_LIST_INDEX':
			return {
				...state,
				listEditIndex: action.index,
				listEditQId: action.id,
			}
		case SET_ACTION_SUCCESS_MESSAGE:
			actionMessage = get(action, 'payload.actionMessage', { type: '', message: '' });
			return {
				...state,
				actionMessage: {
					type: actionMessage.type,
					message: actionMessage.message,
				},
			}
		case GET_QUESTION_FAILURE:
		case SET_ACTION_FAILURE_MESSAGE:
			actionMessage = get(action, 'payload.actionMessage', { type: '', message: '' });
			return {
				...state,
				isLoading: false,
				actionMessage: {
					type: actionMessage.type,
					message: actionMessage.message,
				},
			};	
		case TOGGLE_ESIGN_BUTTON:
			return {
				...state,
				eSign: {
					hasESign: true,
					eSignEnable: action.enable,
				},
			}
		case SET_POPUP_DETAILS:
			return {
				...state,
				popupDetails: action.payload,
			}
		case SET_MEMBER_DATA_INDEX:
			return {
				...state,
				memberDataIndex: action.index,
			}
		case SET_SINGLE_SELECT_FOCUS:
			return {
				...state,
				shouldFocusSingleSelect: action.shouldFocusSingleSelect
			}
		case SET_CSRF_TOKEN:
			return {
				...state,
				csrfToken: action.csrfToken
			}
		default:
			return state;
	}
}

