/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import 'font-awesome/css/font-awesome.min.css';
import styled from 'styled-components';
import './footer.css';
import SocialLinks from './SocialLinks';
import FooterLinks from './FooterLinks';
import FooterNotes from './FooterNotes';
import FooterBottomLinks from './FooterBottomLinks';
import FooterTelephone from './FooterTelephone';
import FooterExpiry from './FooterExpiry';

const FooterRoot = styled.div`
  background-color: ${(props) =>
    props.backgroundColor || 'rgba(59, 83, 105, 1)'};
  color: ${(props) => props.color};
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 2px solid #b3b3b3;
  font-family: ${(props) => props.fontFamily || 'inherit'};
`;

const Img = styled.img`
  height: auto;
  width: auto;
  max-width: 150px;
`;

// eslint-disable-next-line no-unused-vars
const Bold = styled.p`
  font-weight: bold;
`;

const GlobalFooter = styled.div`
  padding: 10px 0;
  background: ${({ background }) => background};
`;

const Partners = styled.div`
  margin: 20px 0;
`;
const Footer = ({ footer, custCareTel, policyExpiry, agentParams }) => {
  const {
    color,
    background,
    link,
    altText,
    logo,
    phoneText,
    phoneNumber,
    footerLinksTop,
    socialLinks,
    disclaimerDesc,
    footerLinksBottom,
    isHTML,
    footerHTML,
  } = footer;

  const footerText = `${agentParams.agentName} ${agentParams.agentPhone ? `| ` : ``}`;
  return (
    <FooterRoot
      color={ color }
      name="FooterRoot"
      backgroundColor={ background }
      fontFamily={ footer.fontFamily }
    >
      <GlobalFooter background={ background } className="global-footer">
        {isHTML ? (
          <>
            {custCareTel && <FooterTelephone custCareTel={ custCareTel } />}
            <div className="container" dangerouslySetInnerHTML={ { __html: footerHTML.data } } />
            <FooterNotes />
            {policyExpiry && <FooterExpiry policyExpiry={ policyExpiry } />}
          </>
        ) : (
          <div className="container">
            { logo &&
              <div className="header-information-footer">
                <div className="company-logo">
                  <a href={ link } target="_blank" rel="noopener noreferrer">
                    <Img src={ logo } alt={ altText } />
                  </a>
                </div>
                <div className="contact-number">
                  <div>
                    <strong>{`${phoneText} `}</strong>
                    {phoneNumber ? (
                      <a href={ `tel:${phoneNumber}` }>{`(${phoneNumber})`}</a>
                    ) : null}
                  </div>
                </div>
              </div>
            } 
            {socialLinks?.length > 0 && <SocialLinks links={ socialLinks } />}
            <div className="footer-text-container">
              <div className="footer-agent-name-container">
                <div>
                {footerText} {agentParams.agentPhone && <a href={ `tel:${agentParams.agentPhone}` }> {agentParams.agentPhone} </a>}
                </div>
              </div>
              <FooterLinks links={ footerLinksTop } />
              {footer.businessPartners && (
                <Partners>
                  <span className="disclaimer-text">
                    {footer.businessPartners}
                  </span>
                  <FooterLinks links={ footer.footerBusinessLinks } />
                </Partners>
              )}
              <p className="disclaimer-text">{disclaimerDesc}</p>
              {footer.footerTextBottom && (
                <div className="footer-text-bottom" 
                     dangerouslySetInnerHTML={ { __html: `&copy${new Date().getFullYear()} ` + footer.footerTextBottom } }/>
              )}
              <div className="alan-style">{agentParams.alanNumber}</div>
              <FooterBottomLinks links={ footerLinksBottom } />
            </div>
          </div>
        )}
      </GlobalFooter>
    </FooterRoot>
  );
}

Footer.propTypes = {
  footer: PropTypes.object.isRequired,
};

export default Footer;
