/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CheckboxGroup } from 'crux';
import { withTheme } from 'styled-components';
import get from 'lodash/get';
import { updateAnswersArray } from '../redux/actions';
import { getWidth } from './utils';
import TextElement from '../Components/UIElements/TextElement';
import SelectWrapper from './SelectWrapper';
import { parseHtml } from '../utils';

const getSelectedOptions = (response) => {
	if (!response) {
		return [];
	}
	if (response && response.length) {
		return response.map((r) => r.id);
	}
	return [];
};

const MultiSelect = ({ question, handleChange, theme, handlePopup }) => {
	const {
		pageId,
		config: {
			theme: {
				pageOverrides,
				components,
				// eslint-disable-next-line no-unused-vars
				global: { colorScheme },
			},
		},
	} = theme;

	const multiSelectProps = get(components, 'multiSelect', null);
	const themeConfiguration = { ...multiSelectProps }

	const {
		question_text: questionText,
		display_type: displayType,
		response_options: options,
		response,
		orientation,
	} = question;
	return (
		<fieldset>
			<legend style={ { fontSize:0,margin:0 } }>
				{parseHtml(questionText, handlePopup)}
			</legend>
			<SelectWrapper width={ getWidth('multiSelect', pageId, pageOverrides) }>
				<TextElement
					styleType="label"
					text={ questionText }
					sectionIndex={ 99 }
				/>
				{
					displayType === 'checkbox' && (
					<CheckboxGroup
						orientation={ orientation || 'columns' }
						selectedOptions={ getSelectedOptions(response) }
						handlePopup = { handlePopup }
						onChange={ (e, option) => {
							const { checked } = e.target;
							handleChange(
								{
									id: option.id,
									checked,
									type: 'array',
								},
								false,
								true
							);
						} }
						options={ options }
						color="blue"
						alignTopWithLabel
						themeConfiguration={ themeConfiguration }
					/>)
				}
			</SelectWrapper>
		</fieldset>
	);
};

MultiSelect.propTypes = {
	/* eslint-disable react/forbid-prop-types */
	question: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		handleChange: (response, preventReflexiveCall, isChangeEvent) => {
			const questionId = get(props, 'question.question_id', '');
			const { sectionIndex } = props;
			dispatch(
				updateAnswersArray({
					response,
					questionId,
					sectionIndex,
					preventReflexiveCall,
					reset: get(props, 'question.validations.reset_on', null), 
					isChangeEvent
				}),
			);
		},
	};
};
export default withTheme(
	connect(null, mapDispatchToProps)(MultiSelect),
);
