import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import { getSections, getCurrentBreadcrumbs } from './questions';

export const getListProps = createSelector(
  [getSections, getCurrentBreadcrumbs],
  (sections, currentBreadcrumb) => memoize( sectionIndex => {
    if (isEmpty(currentBreadcrumb)) {
      return {}
    }
    const currentSection = sections[currentBreadcrumb.id];
    const listQuestion = currentSection[sectionIndex]
    if (listQuestion) {
      const { isList, listDisplay, hasItems, addButtonText, originalQuestions, questionObj } = listQuestion.list;
      return {
        isList,
        listDisplay,
        hasItems,
        addButtonText,
        originalQuestions,
        questionObj,
      }
    }
    return {}
  }),
)


/**
 * @description This function is supposed to provide the title for accordions.
 * This is limited to insurance and beneficiary. This should ideally come from backend,
 * but at the moment, custom code works fine.
 * @param {Array} listQuestion 
 * @param {boolean} isReviewPage
 */
export const getListTitle = (listQuestion, isReviewPage) => {
  let title = '';
  try {
    // if insurance question
    if (listQuestion[0].question_id.match(/insurance/i)) {
      title = listQuestion[0].response
    }

    // if beneficiary or child rider question
    // beneFirstname to handle beneficiary details in review page
    if (listQuestion[0].question_id.match(/beneficiary/i) || listQuestion[0].question_id.match(/childrider/i) ||
      (isReviewPage && listQuestion[0].question_id.match(/benefirstname/i))) {
        let firstName, lastName, allocValue, benefitPercentage;
        listQuestion.forEach(question => {
          if(question.question_id && question.question_id.match(/firstname/i)){
            firstName = question.response;
          }
          else if(question.question_id && question.question_id.match(/lastname/i)){
            lastName = question.response;
          }
          else if(question.question_id && question.question_id.match(/alloc/i)){
            allocValue = question.response;
          }
          else if(question.question_id && question.question_id.match(/benefitpercentage/i)){
            benefitPercentage = question.response;
          }
        });
        if(lastName) {
          title = `${lastName}, `;
        }
        if(firstName) {
          title += `${firstName} `;
        }
        if(allocValue) {
          title +=  `(${allocValue}%)`;
        }
        if(!allocValue && benefitPercentage) {
          title += `${benefitPercentage}%`;
        }
      }
  } catch (e) {
    // error.
    title = ''
  }
  return title;
}
