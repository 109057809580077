import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import ActionButtons from '../../QuestionsLib/ActionButtons';
import {
	getEnableAdd,
	getContinueState,
} from '../../../redux/selector/validations';
import { closeAddListForm } from '../../../redux/actions/listQuestion';

const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 20px;
	@media only screen and (max-width: 767px) {
		justify-content: center;
		margin-right: 0;
	}
`;

const CloseButton = styled.button`
	margin-left: 0%;
	background: transparent;
	border: 0;
	outline: none;
	font-size: 20px;
	color: red;
	z-index: 123123;
	transition: all 0.2s ease-out;
	&:hover {
		transform: scale(1.5);
		transition: all 0.2s ease-in;
	}
	&:focus {
		outline: none;
	}
	@media only screen and (max-width: 790px) {
		margin-left: 93%;
	}
`;

function ListQuestionAction({
	enableContinue,
	sectionIndex,
	closeButton,
	handleClose,
	enableAdd,
	listQIds,
	listId,
	listAddButtonLabel,
}) {
	const handleClick = (e)=>{
		handleClose(sectionIndex)
		e.preventDefault();
		e.stopPropagation();
	}
	if (closeButton) {
		return (
			<CloseButton
				onClick={ handleClick }
				name="close-button"
			>
				<i className="fa fa-close" />
			</CloseButton>
		);
	}
	return (
		<ActionWrapper name="action-wrapper">
			<ActionButtons
				sectionIndex={ sectionIndex }
				isList
				isListAddButton
				enableContinue={ enableContinue }
				enableAdd={ enableAdd }
				questionIds={ listQIds }
				listId={ listId }
				listAddButtonLabel={ listAddButtonLabel }
			/>
		</ActionWrapper>
	);
}

const mapStateToProps = (state, props) => {
	const id = get(props, 'match.params.id', '');
	const { requiredListQIds } = props
	const { enableContinue } = getContinueState(
		state,
		props.sectionIndex,
		id,
	);
	return {
		enableContinue,
		enableAdd: getEnableAdd(state, props.sectionIndex, id, requiredListQIds),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleClose: (sectionIndex) => {
			dispatch(closeAddListForm(sectionIndex));
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ListQuestionAction),
);
