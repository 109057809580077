import get from 'lodash/get';

const shape = {
  radio_button: 'oval',
  radio: 'classic',
  cards: 'card',
  product_card: 'product_card',
};

const getShape = ({ questionId, singleSelect, displayType }) => {
  const { shapeOverride, plansPage } = singleSelect;
  const field = Object.keys(shapeOverride).find(key => questionId.search(key) !== -1)
  if(shapeOverride[field]) {
    return shapeOverride[field]
  }
  return {
    shape: shape[displayType],
    overlap: false,
    labelWidth: (plansPage && plansPage.labelWidth) || null,
  };
};

const getWidth = (component, pageId, pageOverrides, size = '') => {
  if ( size ){
    return size;
  }
  if (pageOverrides[pageId] && pageOverrides[pageId][component]) {
    return pageOverrides[pageId][component].width;
  }
  return 'L';
};

const shouldShowPageInfo = (pageId, pageOverrides) => {
  if (pageOverrides[pageId]) {
    return pageOverrides[pageId].showPageInfo;
  }
  return false;
}

const getLabelProps = (
  componentType,
  componentProperties,
  pageId,
  pageOverrides,
) => {
  if (pageOverrides[pageId] && pageOverrides[pageId][componentType]) {
    return {
      ...pageOverrides[pageId][componentType],
    };
  }
  return {
    ...componentProperties[componentType],
  };
};

const getTooltipProps = (tooltipTitle, tooltipBody, tooltipHTML) => {
  if (tooltipTitle && tooltipBody) {
    return {
      hasTooltip: true,
      tooltipTitle,
      tooltipBody,
    }
  }
  if (tooltipTitle) {
    return {
      hasTooltip: true,
      tooltipTitle,
      tooltipBody: '',
    }
  }
  if (tooltipBody) {
    return {
      hasTooltip: true,
      tooltipTitle: '',
      tooltipBody,
    }
  }
  if (tooltipHTML) {
    return {
      hasTooltip: true,
      tooltipHTML,
    }
  }
  return {};
}

// This utility trims forward and backward slashes from the pattern value.
const trimSlashes = (str) => {
  let actualPattern = str;
  if (actualPattern.slice(-1) === '/' && actualPattern.slice(0, 1) === '/') {
    actualPattern = actualPattern.slice(1, actualPattern.length - 1);
  }
  return actualPattern;
};

const getValidators = (question,isPrefillValid) => {

  const {
    validations,
    properties,
    is_editable: isEditable,
    is_readonly: isReadOnly,
    display_type: displayType,
    question_text: questionText,
    question_status: questionStatus,
    question_status_message: questionStatusMessage,
  } = question;

  const {
    auto_format: autoFormat,
    pattern,
    metric,
    step,
    min_value: minValue,
    max_value: maxValue,
    min_length: minLength,
    max_length: maxLength,
    precision,
    placeholder_text: placeholderText,
    phone_number_validation:phoneNumberValidation,
    checksum
  } = validations;

  // Calendar Popper Properties
  let popperMinValue;
  let popperMaxValue;
  if (properties && properties.range) {
    const { range } = properties;
    popperMinValue = range.min;
    popperMaxValue = range.max;
  }

  const disabled = !isEditable || isReadOnly;
  const required = get(validations, 'required', {
    value: false,
    error_message: '',
  })

  let customError = {};

  let placeholder = placeholderText && placeholderText.hide ? '' : questionText.replace(/(<([^>]+)>)/gi, '');
  if (placeholderText && placeholderText.value) {
    placeholder = placeholderText.value;
  }

  let responseFormat;
  if (autoFormat && autoFormat.value) {
    responseFormat = autoFormat.value.toUpperCase();
    autoFormat.value = autoFormat.value.toUpperCase();
  }

  let updatedAutoFormat = autoFormat;
  if (autoFormat && autoFormat.type === 'currency') {
    updatedAutoFormat = {
      ...autoFormat,
      format: 'en-US',
      preserveFormattingInCb: true,
    };
    placeholder = autoFormat.value;
  } else if (autoFormat && autoFormat.value.indexOf('00') > -1) {
    responseFormat = placeholderText.value;
    updatedAutoFormat.value = placeholderText.value;
  }

  let updatedPatternValue;
  if (pattern && pattern.value) {
    updatedPatternValue = {
      ...pattern,
      value: trimSlashes(pattern.value),
    };
  }
  customError = {
    error: questionStatus === 'invalid' || isPrefillValid === false,
    error_message: questionStatusMessage || validations.error_message || (validations.max_length ? validations.max_length.error_message : ""),
  }


  if (displayType === 'slider') {
    return {
      maxValue: parseInt(maxValue.value, 10),
      step: parseInt(step.value, 10),
      minValue: parseInt(minValue.value, 10),
      currency: updatedAutoFormat,
    };
  }

  return {
    metric,
    pattern: updatedPatternValue,
    maxLength,
    minLength,
    autoFormat: updatedAutoFormat,
    currency: updatedAutoFormat,
    step: step && step.value ? step.value : '',
    minValue,
    maxValue,
    precision,
    required,
    disabled,
    placeholder,
    customError,
    responseFormat,
    popperMinValue,
    popperMaxValue,
    phoneNumberValidation,
    checksum
  };
};

const showPopper = (hasCalendarPopper, validators) => {
  const formatValue = get(validators, 'autoFormat.value').toLowerCase();
  if (!formatValue.includes('y')) return false;
  return hasCalendarPopper;
}

const getCsvString = ( options ) => {
  const valueArray = [];
  options.forEach(option => option.response && option.response.value && valueArray.push(option.response.value));
  return valueArray.join('; ');
}

const isPresent = ( array, property, value) => {
  let isPresentInArray = false;
   array.forEach(val => {
     if(val[property] === value) {
       isPresentInArray = true
     }
   });
   return isPresentInArray;
}

const getDataSelectorOptions = (options)=> {
  const dataSelectorOptions = [];
   if(options && Array.isArray(options)){
   options.forEach((option, index) => {
     const selectorOption = { id: index, label: getCsvString(option) };
     if(!isPresent(dataSelectorOptions, 'label', selectorOption.label)){
      dataSelectorOptions.push(selectorOption);
     }
   });
  }
   return dataSelectorOptions;
}

// Get response by id
const getResponse = (id, options) => {
  let response = { value: '' };
  options.forEach(option => {
    if(option.target_question_id === id){
      response = option.response;
    }
  })
  return response;
}

// Maps response options and selector options
const getMappedOptions = ( responseOptions, selectorOptions) => {
  const mappedOptions = []; 
  responseOptions.forEach(responseOption => {
    const options = [];
    selectorOptions.forEach(selectorOption => {
      let currentOption = { target_question_id: selectorOption.id };
      const response = getResponse(selectorOption.id, responseOption);
      currentOption = { ...currentOption, response };
      options.push(currentOption);
    })
    mappedOptions.push(options);
  })
  return mappedOptions;
}

const getSliderLabels = (minValue, maxValue, stepValue, marks) => {
  const labels = {}
  let index = minValue;
  while(index <= maxValue){
    labels[index.toString()] = index;
    index += (marks && marks.label_value ? marks.label_value : 1) * stepValue
  }
  return labels
}

export {
  getShape,
  getWidth,
  getLabelProps,
  getValidators,
  shouldShowPageInfo,
  getTooltipProps,
  showPopper,
  getDataSelectorOptions,
  getMappedOptions,
  getSliderLabels
};
