import { executeAuthRequest, getSSOTraits } from '@sureifylabs/ext-frontend-identity';
import LocalStorageServices from './util/localStorageServices';
import { SET_ACTION_FAILURE_MESSAGE } from './redux/types';
import Traits, { traitsAlias }  from './types/ssoEnumTraits';
import { pageRouter } from './util/router';

/**
 * @description After successful login, constructs redirect url based on user info
 * @param {String} url 
 * @param {String} accessToken 
 * @param {String} baseAppUrl 
 * @returns 
 */

const ssoTraits = [
	Traits.EMAIL,
	Traits.AGENTNUMBER,
	Traits.FIRSTNAME, 
	Traits.LASTNAME, 
	Traits.ROLE, 
	Traits.USERID,
	Traits.PHONE
]

const getQueryParamStringFromTraits = (traitsMap) => {
	let queryString = '\?';
	ssoTraits.forEach((trait, index) => {
		const traitValue = traitsMap.get(trait);
		if(traitsAlias[trait] && traitValue){
			if(index < ssoTraits.length - 1){
				queryString += `${traitsAlias[trait]}=${traitValue}&`
			}
			else{
				queryString += `${traitsAlias[trait]}=${traitValue}`
			}
		}
	})
	return queryString;
}

async function getRedirectURlfromTraits(dispatch, url, baseAppUrl) {
    try {
		console.debug('fetching SSO Traits');
        const traitsMap = await getSSOTraits(url);
	    console.debug('traitsMap', traitsMap);
	    if(!(traitsMap && traitsMap.size)){
		    dispatch({
			    type: SET_ACTION_FAILURE_MESSAGE,
			    payload: {
				    actionMessage: {
					    type: 'error',
					    message: 'We cannot proceed you to the application.Please try login again after sometime.',
				    },
			    },
		    });
		    pageRouter(dispatch, 'authError');
		    return false;
	    }
    	ssoTraits.forEach((attr) => traitsMap && !traitsMap.has(attr) && console.error(`Required attribute ${attr} is missing`));
        return `traits${getQueryParamStringFromTraits(traitsMap)}`;
    }
    catch (e) {
        console.error(e);
    }
    return '';
}

/**
 * @description After Login, redirection handler to the next step. 
 * @param {Object} store 
 * @param {Env} ssoAuthConfig-environment specific 
 */
async function ssoDone(store, ssoAuthConfig) {
	const { dispatch } = store;
	const ssoAccessToken = LocalStorageServices.getSSOAccessToken();
	if (ssoAccessToken) {
		console.debug('SSO Access token is set and sending to getRedirectURlfromTraits');
		LocalStorageServices.setSSOAccessToken(ssoAccessToken);
		dispatch({
			type: SET_ACTION_FAILURE_MESSAGE,
			payload: {
				actionMessage: {
					type: 'success',
					message: 'Login Success! Redirecting to main page',
				},
			},
		});
		const { FRONTEND_BASE_URL, LIFETIME_AUTH_URL } = ssoAuthConfig;
		const redirectUrl = await getRedirectURlfromTraits(dispatch, LIFETIME_AUTH_URL, FRONTEND_BASE_URL);
		console.debug('Access tokens all set. Redirection to main page');
		if(redirectUrl) {
            pageRouter(dispatch, redirectUrl);
        }
	}
    else {
		console.error('SSO done but didn\'t get access token');
		dispatch({
			type: SET_ACTION_FAILURE_MESSAGE,
			payload: {
				actionMessage: {
					type: 'error',
					message: 'Login Failed! Please try again',
				},
			},
		});
		pageRouter(dispatch, 'authError');
	}
}

/**
 * @description Handles IDP/SP Initiated SSO login.
 * @param {Object} store 
 * @param {Object} config 
 * @param {Object} ssoAuthConfig 
 */
export default async function ssoAuth(store, config, ssoAuthConfig) {
    const ssoAccessToken = LocalStorageServices.getSSOAccessToken();
    const { features:{ ssoLogin } } = config;
    const { enable } = ssoLogin;
	const { LIFETIME_AUTH_URL, SSO_USER_TYPE, SSO_REDIRECT_URL, IS_SSO_IDP_INITIATED_FLOW } = ssoAuthConfig;
	const { pathname } = window.location;
	let routeName = pathname ? pathname.replace(/\//g, '') : '';
	console.debug(`ssologin enable ${enable} --> ssoAccesstoken --> ${ssoAccessToken} ---> href -->${window.location.href}`);

	if(routeName === 'ssodone' && SSO_REDIRECT_URL.includes(pathname)) {
		console.debug('SSO flow done. Calling ssoDone function to property set access token');
		await ssoDone(store, ssoAuthConfig);
	} 
    else {
		console.debug('initiating flow now');
		try {
			await executeAuthRequest({
				baseUri: LIFETIME_AUTH_URL,
				userType: SSO_USER_TYPE,
				redirectUrl: SSO_REDIRECT_URL,
				isIdPInitiatedFlow: IS_SSO_IDP_INITIATED_FLOW,
			});
		} catch (e) {
			console.error('There was an error while calling executeAuthRequest function');
			console.error(e);
		}
	}
}