/* eslint-disable import/no-cycle */
import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { Button, Tooltip } from 'crux';
import QuestionRenderer from '../Components/QuestionRenderer';
import HELPER from '../redux/reducers/helper';

const ButtonContainer = styled.div`
  margin-top: -20px;
  margin-left: -10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const updateParentIndex = (listQ, index) => {
  const immediateParent = listQ[0].question_id
  const flatList = [];
  HELPER.flattenQuestions(listQ, flatList);
  return flatList.map(q => {
    return {
      ...q,
      parentId: `${get(q, 'properties.parent_list_id', '')}_${index}`,
      immediateParent,
    }
  });

}

const displayRemoveLink = (list, index) => {
  return index + 1 < list.length;
}

const NListQuestions = ({
  list,
  removeButtonText,
  removeTooltip,
  removeItem,
  isReview,
}) => (
    <>
    {
      Object.keys(list).map(key => {
        return list[key].map((listQ, index) => {
          return (
            <div data-name='n-list-display'>
              <QuestionRenderer rebuild={ false } questionList={ updateParentIndex(listQ, index) } />
              {
                displayRemoveLink(list[key], index) && !isReview &&
                <ButtonContainer>
                  <Button
                    color='green'
                    label={ removeButtonText }
                    disabled={ false }
                    type='text'
                    handleButtonClick={ () => { removeItem(key, index) } }
                  />
                  <Tooltip tooltipHTML={ removeTooltip } />
                </ButtonContainer>
              }
            </div>
          )
        })
      })
    }
  </>
)

export default NListQuestions;
