import React from 'react';
import { Toaster } from 'crux';
import { connect } from 'react-redux';

const Toast = ({ type, message, hidePopper, timeout }) => {
	return (
		<Toaster
			intent={ type }
			message={ message }
			showToaster
			hideCallback={ hidePopper }
			timeout={ timeout }
		/>
	);
};

const mapStateToProps = () => {};
const mapDispatchToProps = () => {
	return {
		hidePopper: () => {
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Toast);
