import styled from 'styled-components';

const QuestionText = styled.h3`
	font-size: 18px;
	color: #5d5d5d;
`;

const QuestionDescription = styled.h4`
	color: #5d5d5d;
	font-size: 16px;
`;

const InputWrapper = styled.div`
	display: flex;
	@media (min-width: 360px) {
		/* margin-right: 15px; */
	}
	`;

const SelectWrapper = styled.div`
${(props)=>props.floatingLabel?'':
   'margin-top: 11px;'}
  /* margin-right: 15px; */
`

const Wrapper = styled.div `
  margin: ${({ customWrapperMargin })=>customWrapperMargin||'0 0 12% 0'};
  .rangeslider {
    margin: ${props => props.margin ? props.margin : '100px 0'}
  }

  .rangeslider__handle {
    background: ${props => props.background ? `${props.background  }!important` : 'rgba(226, 226, 226, 1)' };
  }

  .rangeslider__handle-tooltip {
    color: ${props => props.background ? `${props.background  }!important` : 'rgba(41, 101, 204, 1);'};
    text-align: center;
    font-size: 30px;
  }
`

export { QuestionDescription, QuestionText, InputWrapper, SelectWrapper, Wrapper };
