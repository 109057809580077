import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import Loader from '../../cruxComponents/Loader';
import Left from './Left';
import Right from './Right';

const Cover = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.25);
	cursor: not-allowed;
	z-index: 7111995;
`;

const MainContainer = styled.main`
	background : ${(background, layout) => (layout === 'auto') ? '#ffffff' : background};
`;

const MainWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: ${({ isSmallScreen }) => isSmallScreen ? 'column' : 'row'};
`;

const Content = ({ children, layout, loading, theme, leftData, reload }) => {
	const {
		isSmallScreen,
		activeSubsectionId,
		config: {
			theme: {
				global: {
					background,
					leftSidebarColor,
				},

			},
		},
	} = theme;

	if (layout === 'auto') {
		return (
			<MainContainer
				data-name="main"
				leftSidebarColor={ leftSidebarColor }
				layout={ layout }
				background={ background }>
				{children}
			</MainContainer>
		);
	}

	const skipPageLoader =
		(layout === 'double' ||
		activeSubsectionId !== '' )
		&& !reload

 	return (
		<MainContainer
			data-name="main"
			layout={ layout }
			background={ background }
		>
			{loading && !skipPageLoader ? <Loader /> :
			(
				<MainWrapper isSmallScreen={ isSmallScreen } data-name="main">
					{loading && <Cover/>}
					<Left
						leftSidebarColor={ leftSidebarColor }
						layout={ layout }
						isSmallScreen={ isSmallScreen }
						leftData={ leftData }
					/>
					<Right 
						background={ background } 
						isSmallScreen={ isSmallScreen } 
						content={ children } 
						layout={ layout } 
						leftData={ leftData }
					/>
				</MainWrapper>
			)}
		</MainContainer>
	);
};

Content.defaultProps = {
	loading: false,
	leftData: [],
	layout: 'single',
};

Content.propTypes = {
	leftData: PropTypes.array,
	layout: PropTypes.string,
	children: PropTypes.node.isRequired,
	loading: PropTypes.bool,
};

export default withTheme(Content);
