const commonCardStyles = `
  display: flex;
  flex-direction: row;
  background-size: 100% 100%;
  border-radius: 8px;
`;

const smCardStyles = `
  ${commonCardStyles}
  height: 80px;
  width: 100%;
  @media (min-width: 1281px) {
    ${commonCardStyles}
    height: 80px;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    ${commonCardStyles}
    height: 80px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    ${commonCardStyles}
    height: 80px;
  }
  @media (max-width: 767px) {
  }
`;

const mdCardStyles = `
  ${commonCardStyles}
  @media (min-width: 1281px) {
    ${commonCardStyles}
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    ${commonCardStyles}
  }
  @media (min-width: 981px) and (max-width: 1024px) {
    ${commonCardStyles}
  }
  @media (min-width: 481px) and (max-width: 980px) {
    width: 100%;
  }
  @media only screen and (min-width:320px) and (max-width:480px){
    width: 100%;
  }
`;

const lgCardStyles = `
  ${commonCardStyles}
  height: 237px;
  width: 780px;
  @media (min-width: 1025px) and (max-width: 1280px) {
    ${commonCardStyles}
    height: 237px;
    width: 780px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    ${commonCardStyles}
    height: 237px;
    width: 780px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: auto;
    height: 154px;
  }
  @media only screen and (min-width:320px) and (max-width:480px) {
    width: auto;
    height: 154px;
  }    
`;

const bnCardStyles = `
  ${commonCardStyles}
  height: 112px;
  width: 780px;
  background: rgba(255, 255, 255, 1);
  @media (min-width: 1025px) and (max-width: 1280px) {
    ${commonCardStyles}
    height: 112px;
    width: 780px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    ${commonCardStyles}
    height: 112px;
    width: 540px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    height: 80px;
  }
  @media only screen and (min-width:320px) and (max-width:480px) {
    width: 100%;
    height: 80px;
  }
`;

  export { smCardStyles, mdCardStyles, lgCardStyles, bnCardStyles };
