/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { debounce, get } from 'lodash';
import { setSmallScreen } from './redux/actions/meta';
import {
  checkmodalReferenceIdString,
  checksQuotes,
  checksTextWithinQuotes,
} from './constants/regex';

/**
 * @description checks the innerHTML string for script tag.
 * @param str
 * @returns {string}
 */
const validateInnerHtml = (str) => {
  if (!str) return '';
  if (typeof str !== 'string') return str;
  try {
    if (str.match(/<script>/gi)) {
      throw new Error('Cannot insert script tags');
    }
    return str;
  } catch (e) {
    console.error(e);
    return '';
  }
};

/**
 * @description builds the nested array based on reflexive_index.
 * e.g. [q0, q0, [q1, q1, [q2, q2]], q0, q0]
 * @param list flattened list of questions
 * @param index
 * @returns {*}
 */
function rebuildQuestionList(list, index, doneIndex = -1) {
  return list.reduce((acc, q, i) => {
    if (q.reflexive_index === undefined) {
      q.reflexive_index = 0;
    }
    if (i >= doneIndex && !q.used && q.reflexive_index === index) {
      acc.push(q);
      q.used = true;
    }
    if (!q.used && q.reflexive_index > index) {
      acc.push(rebuildQuestionList(list, q.reflexive_index, i));
    }
    if (i > doneIndex && q.reflexive_index < index) {
      doneIndex = 99;
    }
    return acc;
  }, []);
}

function getRandomId() {
  return window.crypto
    .getRandomValues(new Uint32Array(1))
    .toString(36)
    .substring(7);
}

function resizeHandler(dispatch, getState) {
  const { meta } = getState();
  const { isSmallScreen } = meta;
  if (window.innerWidth < 768 && !isSmallScreen) {
    dispatch(setSmallScreen(true));
  } else if (isSmallScreen && window.innerWidth > 767) {
    dispatch(setSmallScreen(false));
  }
}

const _resizeHandler = debounce(resizeHandler, 500);

function resizeListener({ dispatch, getState }) {
  _resizeHandler(dispatch, getState);
}

function singleButtonPage(id) {
  return id === 'quotereview' || id === 'review' || id === 'login';
}

function isReview(id) {
  return id === 'quotereview' || id === 'review';
}

const buttonLabelMap = {
  login: 'Login',
  signature: 'Begin E-sign',
  quote: 'Get your quote',
  quotereview: 'Continue to application',
  review: 'Continue to application',
};

function isIe() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE');

  // If Internet Explorer, return version number
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
    return true;
  }
  return false;
}

function sanitize$uriefyQuestion(questionId) {
  try {
    return questionId.split(/_\$ureify_/)[1];
  } catch (e) {
    console.error('Failed to sanitize question id');
    return null;
  }
}

function isMatch(question, id) {
  if (
    question &&
    (question.question_id.indexOf(id) > -1 ||
      id.indexOf(question.question_id) > -1)
  ) {
    return true;
  }
  return false;
}

function lookUp(questionsArr, id) {
  return questionsArr.reduce((acc, q) => {
    if (acc) return acc;
    if (isMatch(q, id)) return q;
    if (q.questions) return lookUp(q.questions, id);
    return null;
  }, null);
}

function questionLookup(questionObj, id) {
  return lookUp([questionObj], id);
}

// Utility to flatten out nested questions
const flattenQuestions = (questions, newList) => {
  if (questions.length) {
    questions.forEach((question) => {
      newList.push({
        ...question,
      });
      if (question.questions && question.submission_type !== 'all') {
        flattenQuestions(question.questions, newList);
      }
    });
  }
};

const insertReflexiveQuestions = (item) => {
  const reflexiveQ = [];
  flattenQuestions(item, reflexiveQ);
  return reflexiveQ;
};

// Utility for scrolling to top of the screen.
const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const getPopupDetails = (question, popupQuestions) => {
  let modalReferenceId;
  let popupQuestion;
  const options = get(question, 'response_options', []);
  const questionText = get(question, 'question_text', '');
  // store modal_reference_id in variable
  if (question.display_type === 'checkbox') {
    const modalReferenceOption = options.filter((option) => {
      const label = get(option, 'label', '');
      return /modal_reference_id=/i.test(label);
    })[0];
    if (modalReferenceOption) {
      modalReferenceId = modalReferenceOption.label
        .match(checksTextWithinQuotes)[0]
        .replace(checksQuotes, '');
    }
  } else if (questionText.match(checkmodalReferenceIdString)) {
    modalReferenceId = questionText
      .match(checkmodalReferenceIdString)[0]
      .split('=')[1]
      .replace(checksQuotes, '');
  }
  // use modalReferenceId to find the question with same id in the popupQuestions array
  if (popupQuestions && popupQuestions.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    popupQuestion = popupQuestions.filter((ques) => {
      return ques.question_id === modalReferenceId;
    })[0];
  }
  // render the Modal with popupQuestions data
  if (popupQuestion) {
    return {
      show: true,
      question: popupQuestion,
    };
  }
  return { show: false, question: '' };
};

const parseHtml = (htmlText, clickHandler) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      clickHandler(e);
    }
  };
  return ReactHtmlParser(htmlText, {
    // eslint-disable-next-line consistent-return
    transform: (node) => {
      if (
        node.name === 'a' &&
        node.attribs &&
        node.attribs.modal_reference_id
      ) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return (
          <a
            onClick={clickHandler}
            onKeyDown={(e) => handleKeyDown(e)}
            role="button"
            tabIndex="0"
          >
            {node.children[0].data}
          </a>
        );
      }
    },
  });
};

function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod|mac/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
}

export {
  validateInnerHtml,
  rebuildQuestionList,
  getRandomId,
  resizeListener,
  isReview,
  singleButtonPage,
  buttonLabelMap,
  isIe,
  sanitize$uriefyQuestion,
  questionLookup,
  insertReflexiveQuestions,
  scrollTop,
  getPopupDetails,
  parseHtml,
  getMobileOperatingSystem,
};
