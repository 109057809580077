import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import utilReducer from './utilReducers';
import questions from './questions';
import meta from './meta';
import agentParams from './agentParams';
import auth from './auth';
import nList from './nlist';
import config from './config'
import setNewBuildAvailable from './newBuild';
import { SET_UID, SET_CLUB_CODE } from '../types';

const uid = (state = '', action) => {
  switch(action.type) {
    case SET_UID:
      return action.uid;
    default:
      return state;
  }
}

const clubCode = (state = '', action) => {
  switch(action.type) {
    case SET_CLUB_CODE:
      return action.payload;
    default:
      return state;
  }
}

const createRootReducer = (history) =>
	combineReducers({
    auth,
		router: connectRouter(history),
		uid,
    clubCode,
		utilReducer,
		questions,
    meta,
    agentParams,
    nList,
    config,
    isNewBuildAvailable: setNewBuildAvailable,
	});

export default createRootReducer;
