const initialState = {
  cje : null,
}


export default function appConfig(state = initialState, action) {
  switch(action.type) {
    case 'APP_CONFIG':
      return action.payload;
    case 'SET_CJEVENT_ID' :
      return {
        ...state,
        cje : action.payload,
      }
    default:
      return state;
  }
}  