import styled from 'styled-components';

const getBgColor = (props) => {
	const { isSmallScreen, layout, background } = props;
	if (isSmallScreen && layout === 'single') {
		return background;
	}
	if (isSmallScreen) {
		return '#ffffff';
	}
	if (layout === 'auto') {
		return background;
	}
	return background;
};

const getRestStyles = (props) => {
	const { isIe, isSmallScreen, height } = props;
	if (isIe) {
		return `
			position: relative;
			margin-bottom: -100px;
		`;
	}
	if (isSmallScreen) {
		return `
			position: relative;
			height: 75px;
		`;
	}
	return `
		position: fixed;
		height: ; ${height || '75px'}
	`;
}

const HeaderWrapper = styled.div`
	background: ${(props) => getBgColor(props)};
	display: flex;
	width: 100%;
	opacity: ${({ fade }) => (fade ? '0' : '1')};
	transition: opacity 0.3s ease-in-out;
	z-index: 100;
	${(props) => getRestStyles(props)};
	border-bottom: 1px solid black;
	height:80px;
	align-items: center;
`;

const Logo = styled.div`
	margin-top: ${({ isSmallScreen }) => isSmallScreen ? '5px' : '0px'};
	margin-left: 5%;
	img {
		max-width: 150px;
		height: auto;
	}
	flex-grow: 1;
`;

const Links = styled.div`
	flex-grow: 3;
`;

const ButtonWrapper = styled.div`
	position: fixed;
	right: 20px;
	top: 20px;
	display: flex;
	align-items: center;
	margin: 8px 15px;
	z-index: 110;
	& > button {
		font-weight: bold;
		background-color: white;
	}
`;


const HeaderText = styled.div`
	display: flex;
	position: relative;
	right: 10%;
	margin-top: 1.5%;
	@media screen and (max-width: 720px) {
		right: 0%;
		padding-left: 15%;
	}
`

const PhoneNumber = styled.a`
	color: rgb(0, 82, 156);
	font-family: 'Open Sans';
	font-size: 18px;
	font-weight: 700;
	height: 24px;
	line-height: 24px;
	width: 125px;
	text-decoration: none;
`

export { HeaderWrapper, Logo, Links, ButtonWrapper, HeaderText, PhoneNumber };
