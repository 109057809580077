import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Alert, Card } from 'crux';
import Section from './Section';
import ScrollToTop from '../../ScrollToTop';

const Questions = ({
  errors,
  sectionData,
  index,
  processing,
  theme,
  changeMode,
  isSmallScreen,
}) => {
  const {
    errorMessage,
    errorTitle,
    hasError,
  } = errors;

  const {
    pageId,
    pageLayout,
  } = theme

  const {
    config: {
      theme: {
        components: {
          card: { padding, marginLeft },
        },
        global: { errorMessageColor, errorFontSize },
      },
    },
  } = theme;

  const {
    id,
    review,
    questionList,
  } = sectionData;

  return (
    <Card 
      key={ id }
      noStyle={ pageLayout ==='auto' }
      layout={ pageLayout }
      padding={ isSmallScreen && padding }
      marginLeft= { marginLeft }
    >
      { (hasError && !window.location.pathname.includes('timeout')) && ( // need to change timeout check
        <ScrollToTop>
          <Alert
            message={ errorMessage }
            type="error"
            title={ errorTitle }
            color={ errorMessageColor }
            fontSize={ errorFontSize }
          />
        </ScrollToTop>
      )}
      <Section
        questionList={ questionList }
        key={ id }
        sectionIndex={ index }
        pageId={ pageId }
        reviewProps={ review }
        processing={ processing }
        changeMode= { changeMode }
      />
    </Card>
  )
}

Questions.propTypes = {
  sectionData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  processing: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(Questions);
