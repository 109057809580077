/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { validateInnerHtml } from '../../utils';
import { FooterNote, FooterWrapper } from './styles';

function FooterNotes(props) {
  const { footerNotes, layout } = props;
  return (
    footerNotes.length > 0 && (
      <FooterWrapper layout={ layout }>
        {footerNotes.map((footerNote) => (
          <FooterNote styles={ { color: 'white', fontSize: '14px' } }>
            <p dangerouslySetInnerHTML={ { __html: validateInnerHtml(footerNote) } }/>
          </FooterNote>)
        )}
      </FooterWrapper>
    )
  );
}

const mapStateToProps = (state) => {
  const footerNotes = get(state, 'questions.footerNotes', []);

  return {
    footerNotes,
  };
};
export default connect(mapStateToProps)(FooterNotes);
