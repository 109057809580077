const localPortMap = {
  nw: 8080,
  as: 8008,
  lc: 8181,
  am: 8081,
  rg: 8081,
  bh: 8181,
  bl: 8181,
  aaa: 8181,
}

export default function getHost () {
  const env = process.env.REACT_APP_NODE_ENV;
  const cId = process.env.REACT_APP_CARRIER_ID;

  if (env === 'local') {
    return {
      REACT_APP_BASE_URL: `http://localhost:${localPortMap[cId]}/api`,
      WS_BASE_URL: `http://localhost:${localPortMap[cId]}`,
    }
  }
  return {
    REACT_APP_BASE_URL: `${window.location.origin}/api`,
    WS_BASE_URL: window.location.origin,
  }
}
