import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line no-unused-vars
const widthMap = {
  S: '100px',
  M: '260px',
  L: '360px',
  XL: '400px',
};

const Wrapper = styled.div`
  margin-bottom: ${({ removeMargin }) => (removeMargin ? '0' : '20px')};
  max-width: 100%;
`;

const SelectWrapper = ({ width, children, removeMargin }) => {
  return (
    <Wrapper name="select-wrapper" width={ width } removeMargin={ removeMargin }>
      {children}
    </Wrapper>
  );
};

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-right: 10px;
    margin-bottom: 15px;
  }
`;

export default SelectWrapper;
