import React from 'react';
import styled from 'styled-components';
import './Loader.css';

/*
The height and width was previously overridden by the css classes, now I(@nishith) have made 
this to get adjusted using props isReflexive
if isReflexive=true then loader behaves as reflexive loader 
*/
const TextStyled = styled.div`
  margin-top: 45px;
  height: 44px;
  font-size: 36px;
  font-weight: 400;
  color: rgba(71, 100, 126, 1);
  line-height: 44px;
  @media only screen and (min-width:320px) and (max-width:480px){
    left: 20px;
    margin-top:30px
  }
`;

const AnimatedDiv = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${props => (props.isReflexive ? '30px' : '64px')};
  height: ${props => (props.isReflexive ? '30px' : '64px')};
  margin: 6px;
  border: ${props => (props.isReflexive ? '3px solid rgba(0, 122, 137, 1)' : '6px solid rgba(0, 122, 137, 1)')} ;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgba(0, 122, 137, 1) transparent transparent transparent;
`;

// Ring is the div container which holds the div animations which animates like a spinner
const Ring = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => (props.isReflexive ? '30px' : '64px')};
  height: ${props => (props.isReflexive ? '30px' : '64px')};
  top: 50%;
  user-select: none;
`;

const ImageStyled = styled.img`
max-width:250px;
`;

const AlignCenter = styled.div`
  text-align: center;
`;

const TextMessage = styled.p`
  color: rgba(71, 100, 126, 1);
`;

export default props => {
  const { isReflexive, imageUrl, review } = props;
  if (isReflexive) {
    return (
      <Ring  isReflexive={ isReflexive } className="lds-ring">
        <AnimatedDiv isReflexive={ isReflexive }/>
        <AnimatedDiv isReflexive={ isReflexive } />
        <AnimatedDiv isReflexive={ isReflexive } />
        <AnimatedDiv isReflexive={ isReflexive }/>
        <AnimatedDiv isReflexive={ isReflexive }/>
        <AnimatedDiv isReflexive={ isReflexive } />
        <AnimatedDiv isReflexive={ isReflexive } />
      </Ring>
    )
  }

  return (
    <AlignCenter>
      <ImageStyled src={ imageUrl } alt='Loading. Please wait.' />
      <TextStyled>Just a moment ...</TextStyled>
      {
        review && <TextMessage>Gathering your answers...</TextMessage>
      }
    </AlignCenter>
  )
}