import React from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getCurrentBreadcrumbsList } from '../../../redux/selector/questions';
import { getBreadcrumbStatus } from '../../../util';

const Layout = styled.div`
	height: 80px;
	background: #ffffff;
	border-radius: 8px;
	margin-bottom: 10px;
	width: 80%;
	box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.2);
	@media (min-width: 1281px) {
		height: 80px;
		background: #ffffff;
		border-radius: 10px;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		height: 80px;
		background: #ffffff;
		border-radius: 10px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		height: 80px;
		background: #ffffff;
		border-radius: 10px;
	}
	@media (max-width: 767px) {
		width: 100vw;
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
		height: auto;
		background: none;
		margin-bottom: 0;
	}
`;

const InfoContainer = styled.div`
	padding: 13px 0 13px 150px;
	@media (min-width: 1281px) {
		padding: 13px 0 13px 20px;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		padding: 13px 0 13px 20px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		padding: 13px 0 13px 20px;
	}
	@media (min-width: 481px) and (max-width: 767px) {
		flex-direction: row;
		display: flex;
		padding: 0;
	}
	@media only screen and (min-width: 320px) and (max-width: 480px) {
		flex-direction: row;
		display: flex;
		padding: 0;
	}
`;

const Label = styled.span`
	color: #424242;
	font-size: 14px;
	@media (min-width: 1281px) {
		color: #424242;
		font-size: 14px;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		color: #424242;
		font-size: 14px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		color: #424242;
		font-size: 14px;
	}
	@media (min-width: 481px) and (max-width: 767px) {
		align-self: flex-start;
	}
	@media only screen and (min-width: 320px) and (max-width: 480px) {
		align-self: flex-start;
	}
`;

const Percentage = styled.span`
	color: #2965cc;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	@media (min-width: 1281px) {
		color: #2965cc;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		color: #2965cc;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		color: #2965cc;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
	}
	@media (min-width: 481px) and (max-width: 767px) {
		align-self: flex-end;
	}
	@media only screen and (min-width: 320px) and (max-width: 480px) {
		align-self: flex-end;
	}
`;

const ProgressHolder = styled.div`
	width: 100%;
	height: 8px;
	display: flex;
	flex-direction: row;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	@media (min-width: 1281px) {
		width: 100%;
		height: 8px;
		display: flex;
		flex-direction: row;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		width: 100%;
		height: 8px;
		display: flex;
		flex-direction: row;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		width: 100%;
		height: 8px;
		display: flex;
		flex-direction: row;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	@media (min-width: 481px) and (max-width: 767px) {
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
	}
	@media only screen and (min-width: 320px) and (max-width: 480px) {
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
	}
`;

const Done = styled.div`
	flex: ${(props) => props.percentage};
	background: #2965cc;
	border-bottom-left-radius: 10px;
	transition: all 0.5s linear;
	@media (min-width: 1281px) {
		flex: ${(props) => props.percentage};
		background: #2965cc;
		border-bottom-left-radius: 10px;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		flex: ${(props) => props.percentage};
		background: #2965cc;
		border-bottom-left-radius: 10px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		flex: ${(props) => props.percentage};
		background: #2965cc;
		border-bottom-left-radius: 10px;
	}
	@media (min-width: 481px) and (max-width: 767px) {
		border-bottom-left-radius: 0px;
	}
	@media only screen and (min-width: 320px) and (max-width: 480px) {
		border-bottom-left-radius: 0px;
	}
`;

const Pending = styled.div`
	flex: ${(props) => props.percentage};
	background-color: #b3b3b3;
	border-bottom-right-radius: 10px;
	@media (min-width: 1281px) {
		flex: ${(props) => props.percentage};
		background-color: #b3b3b3;
		border-bottom-right-radius: 10px;
	}
	@media (min-width: 1025px) and (max-width: 1280px) {
		flex: ${(props) => props.percentage};
		background-color: #b3b3b3;
		border-bottom-right-radius: 10px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		flex: ${(props) => props.percentage};
		background-color: #b3b3b3;
		border-bottom-right-radius: 10px;
	}
	@media (min-width: 481px) and (max-width: 767px) {
		border-bottom-right-radius: 0px;
	}
	@media only screen and (min-width: 320px) and (max-width: 480px) {
		border-bottom-right-radius: 0px;
	}
`;

const FlexedDiv = styled.div`
	flex: 1;
`;

// Progress bar component accepts progress, isSmallScreen as props
const Progress = ({ progress, isSmallScreen }) => {
  return (
    <Layout name='layout'>
      {
        !isSmallScreen && (
          <InfoContainer name='info-container'>
            <FlexedDiv name='flexed-div'>
              <Label>Application progress</Label>
            </FlexedDiv>
            <FlexedDiv name='flexed-div'>
              <Percentage>{`${progress}% Complete`}</Percentage>
            </FlexedDiv>
          </InfoContainer>
        )
      }
      <ProgressHolder name='progress-holder'>
        <Done name='done' percentage={ progress } />
        <Pending name='pending' percentage={ 100 - progress } />
      </ProgressHolder>
    </Layout>
  );
};

Progress.propTypes = {
  progress: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  let activeSectionQuestions;
  let progress = 0;
  const breadcrumbs = getCurrentBreadcrumbsList(state);
  const [activeBc] = getBreadcrumbStatus(breadcrumbs || []);
  if (activeBc.length > 0) {
    activeSectionQuestions = state.questions.sections[activeBc[0].id];
    progress =
      activeSectionQuestions[activeSectionQuestions.length - 1]
        .otherParams.question_progress;
  }
  return {
    isSmallScreen: state.meta.isSmallScreen,
    progress,
  }
}


export default connect(mapStateToProps)(Progress);
