/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ChevronIcon } from './ChevronIcon';
import './Accordion.css';

const Text = styled.h4`
	color: #424242;
	@media only screen and (max-width: 767px) {
		max-width: 220px;
		overflow-wrap: break-word;
	}
`;

const IconWrapper = styled.div`
	padding: ${props => props.title ? '0px' : '3%'};
`

const Accordion = ({ title, index, len, isReview, children }) => {
	
	const [expanded, setExpanded] = React.useState(true);
	const extendChild = child => React.cloneElement(child, { showQuestionText: false })
	const extendedChildren = Array.isArray(children) ? children.map(extendChild) : extendChild(children)
	const isExpanded = expanded ? 'expanded' : 'collapsed';
	
	useEffect(() => {
		if (index !== len - 1 && !isReview) {
			setExpanded(false);
		}
	}, [index, len,  isReview])

	return (
		<div className={ `Accordion ${isExpanded}` }>
			<IconWrapper title={ title } onClick={ () => setExpanded(!expanded) }>
				<ChevronIcon />
				<Text className="padding-bottom-15">
					{title}
				</Text>
			</IconWrapper>
			<div className={ `AccordionContent ${isExpanded}` }>
				{extendedChildren}
			</div>
		</div>
	);
}

export default Accordion;