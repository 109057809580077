import React from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';
import { getListProps } from '../../redux/selector/listQuestion';
import ListQuestionAction from '../UIElements/Card/ListQuestionAction';
import GetElementFunc from '../../util/GetElement';
import TextElement from '../UIElements/TextElement';
import ListQuestionCard from '../UIElements/Card/ListQuestionCard';
import { insertReflexiveQuestions } from '../../utils';
import ScrollToSection from '../../ScrollToSection';

const ListWrapper = styled.div`
  background: rgba(226, 226, 226, 1);
  ${({ reflexLevel }) => reflexLevel < 1 && `
    padding: 20px;
    margin: 0% -5.5% 0% -5.5%;
  `}
  margin-bottom: 30px;
  @media only screen and (max-width: 1025px) {
    margin: 0% -5.5% 0% -5.5%;
    margin-bottom: 30px;
    padding: 20px;
  }

  @media only screen and (max-width: 767px) {
    margin: 0% -4.5% 0% -4.5%;
    padding: 8px;
    margin-bottom: 30px;
  }
`;

const ListQuestionWrapper = styled.div`
  background: rgba(255, 255, 255, 1);
  ${({ reflexLevel }) => reflexLevel < 1 && `
    padding: 15px 0 15px 80px;
`}
  border-radius: 8px;
  @media only screen and (max-width: 1025px) {
    padding: 15px;
  } 
  @media only screen and (max-width: 767px) {
    padding: 10px;
  } 
`;

const CloseAction = styled.div`
  @media only screen and (min-width: 768px) {
    position: absolute;
    right: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

/**
 * @description returns an array of [groupquestion, listquestion]
 * @param questionObject
 * @returns {[*, *]|*[]}
 */
const getListQuestionTitles = (questionObject) => {
  try {
    const groupQ = questionObject;
    const listQText = get(questionObject, 'questions[0].question_text', '');
    return [
      {
        ...groupQ,
        question_text: groupQ.question_text || listQText,   // this is a temporary fix,
        // the backend is not sending question text for group question in this case.
      },
      // Commenting sending list question
      // listQ
    ]
  } catch (e) {
    /* eslint-disable no-console */
    console.error('List question assumption failed.', e);
    return []
  }
}

const ListQuestions = ({
  sectionIndex,
  listDisplay,
  addButtonText,
  originalQuestions,
  questionObj,
  changeMode,
  listId,
  reflexLevel,
  isReview,
  listAddButtonLabel,
  theme,
 }) => {
  const _addButtonText = get(questionObj, 'add_button_text', addButtonText || 'Add Record');
  const listParentId = get(questionObj, 'question_id', '');
  const reflexiveListQ = insertReflexiveQuestions(originalQuestions);
  const listQIds = reflexiveListQ.map(q => q.question_id);
  const requiredListQIds = reflexiveListQ.filter(q => get(q, 'validations.required.value', false)).map(q => q.question_id);

  const {
    config: {
      theme: {
        components: {
          listWrapper: { bgColor },
        },
      },
    },
  } = theme;

  if (listDisplay) {
    return (
      <ListQuestionCard
        name='list-question-card'
        sectionIndex={ sectionIndex }
        questionList={ [questionObj] }
        addButtonText={ _addButtonText }
        changeMode={ changeMode } 
        listId={ listId }
        reflexLevel={ reflexLevel }
        isReview ={ isReview }
        listParentId={ listParentId }
        requiredListQIds={ requiredListQIds }/>
    )
  }
  return (
    <>
      {
        getListQuestionTitles(questionObj).map(q => {
          return(
          <>
            <TextElement
              key={ q.question_id }
              styleType="questions_group" // backend should send this evetually
              text={ q.question_text }
              sectionIndex={ sectionIndex } />
              <TextElement
              key={ q.question_description }
              styleType='list'
              text={ q.question_description }
              sectionIndex={ sectionIndex } />
          </>
          )
        })
      }
      <ScrollToSection/>
      <ListWrapper name='list-wrapper' reflexLevel={ 1 } bgColor={ bgColor }>
        <ListQuestionWrapper name='list-question-wrapper' reflexLevel={ 1 }>
          { !listDisplay && (
              <CloseAction name='close-action'>
                <ListQuestionAction closeButton sectionIndex={ sectionIndex } />
              </CloseAction>
          ) }
          { reflexiveListQ.map(q =>
              <GetElementFunc
                questionObj={ q }
                sectionIndex={ sectionIndex }
                required={ get(q, 'validations.required.value', false) }
                isEditMode={ false }
                listParentId={ listParentId }
                listIndex={ null }
              />)
          }
          <ListQuestionAction 
            name="list-question-action"
            sectionIndex={ sectionIndex } 
            listQIds={ listQIds } 
            requiredListQIds={ requiredListQIds } 
            listId={ listId }
            listAddButtonLabel={ listAddButtonLabel }
          />
        </ListQuestionWrapper>
      </ListWrapper>
    </>
  )
}

function mapStateToProps (state, props) {
  const listSelector = getListProps(state);
  const {  addButtonText } = listSelector(props.sectionIndex)

  return {
    addButtonText,
  }
}

export default withTheme(connect(mapStateToProps, null)(ListQuestions));
