import get from 'lodash/get';
import { 
    API_IN_PROGRESS, 
    GET_QUESTION_FAILURE, 
    SET_ACTION_FAILURE_MESSAGE,
	SET_CSRF_TOKEN
} from '../types';
import { axiosInstance } from '../../util/axios';
import LocalStorageServices from '../../util/localStorageServices';
import { showLandingPage } from '../../util/getLandingPage';

/**
 * @description Fetches Auth Tokens and stores them to LocalStorage.
 */
export const getJWTAuthToken = (
	url, 
	reqParams,
	params = {
		redirectTo: '/'
	},
) => {
	return (dispatch, getState) => {
		dispatch({
			type: API_IN_PROGRESS,
		});
		axiosInstance
			.post(url, reqParams)
			.then((response) => {
				dispatch({
					type: SET_CSRF_TOKEN,
					csrfToken: response.data.csrfToken
				});
				const state = getState();
				const isLoginEnabled = get(state, 'auth.login.enable', false);
				const accessToken = get(
					response,
					'data.access_token',
					null,
				);
				const expiresOn = get(
					response,
					'data.expires_on',
				);
				const refreshToken = get(
					response,
					'data.refresh_token',
					null,
				)
				const item = {
					value: accessToken,
					expiry: expiresOn,
					refresh: refreshToken,
				}
				if (accessToken) {
					LocalStorageServices.setToken(JSON.stringify(item))
					if(isLoginEnabled){
						window.location.replace(params.redirectTo);
					}
					else {
						showLandingPage()
					}
				}
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: GET_QUESTION_FAILURE,
				});
				if (error.response) {
					const errorMessage = get(error.response, 'data.errors.message', 'Some Error Occured!');
					dispatch({
						type: SET_ACTION_FAILURE_MESSAGE,
						payload: {
							actionMessage: {
								type: 'error',
								message: errorMessage,
							},
						},
					});
				}
			});
	}
}

/**
 * @description Performs /auth API call and URL redirection.
 */
export const triggerAuthAction = () => {
    const { pathname, search } = window.location;
    const URL = pathname.concat(search);
    return (dispatch, getState) => {
        const state = getState();
        const loginURL = get(state, 'auth.login.loginURL', '/auth');
        const reqParams = {
            carrier: process.env.REACT_APP_CARRIER_ID,
        }
        dispatch(getJWTAuthToken(loginURL, reqParams, {
            redirectTo: URL
        }));
    }
}

/**
 * @description Performs /auth API call when refresh token api fails
 */
export const triggerAuthOnRefreshTokenFailure = () => {
	return async (dispatch, getState) => {
		const state = getState();
        const loginURL = get(state, 'auth.login.loginURL', '/auth');
        const reqParams = {
            carrier: process.env.REACT_APP_CARRIER_ID,
        }
		await axiosInstance
			.post(loginURL, reqParams)
			.then((response) => {
				dispatch({
					type: SET_CSRF_TOKEN,
					csrfToken: response.data.csrfToken
				});
				const accessToken = get(response, 'data.access_token', null);
				const expiresOn = get(response, 'data.expires_on', null);
				const refreshToken = get(response, 'data.refresh_token', null);
				const item = {
					value: accessToken,
					expiry: expiresOn,
					refresh: refreshToken,
				}
				if (accessToken) {
					LocalStorageServices.setToken(JSON.stringify(item))
				}
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: GET_QUESTION_FAILURE,
				});
				if (error.response) {
					const errorMessage = get(error.response, 'data.errors.message', 'Some Error Occured!');
					dispatch({
						type: SET_ACTION_FAILURE_MESSAGE,
						payload: {
							actionMessage: {
								type: 'error',
								message: errorMessage,
							},
						},
					});
				}
			})
		return LocalStorageServices.getAccessToken()
	}
}

/**
 * @description Performs refresh_token API call, fetches new tokens and stores them in LocalStorage.
 */
export const getRefreshToken = () => {
	return async (dispatch, getState) => {
        const state = getState();
        const refreshURL = get(state, 'auth.login.refreshURL', '/auth/refresh_token');
		const refreshToken = LocalStorageServices.getRefreshToken();
		const reqParams = {
			refresh_token: refreshToken,
		}
		if (refreshToken || true) {
			await axiosInstance
			.post(refreshURL, reqParams)
			.then(res => {
				dispatch({
					type: SET_CSRF_TOKEN,
					csrfToken: res.data.csrfToken
				});
				const _accessToken = get(
				res,
				'data.access_token',
				null,
				);
				const _expiresOn = get(
				res,
				'data.expires_on',
				);
				const _refreshToken = get(
				res,
				'data.refresh_token',
				null,
				)
				const item = {
				value: _accessToken,
				expiry: _expiresOn,
				refresh: _refreshToken,
				}
				if (_accessToken) {
					LocalStorageServices.clearToken();
					LocalStorageServices.setToken(JSON.stringify(item));
                }
            })
            .catch((error) => {
				setCSRFTokenValue(error, dispatch);
                const isLoginPageEnabled = get(state, 'auth.login.enable', false);
                if (!isLoginPageEnabled) {
					return dispatch(triggerAuthOnRefreshTokenFailure());
                } else {
                    window.location.reload();
                }
            })
        }
        return LocalStorageServices.getAccessToken();
    }
}

const setCSRFTokenValue = (error, dispatch) => {
	let csrfToken = "";
	if(error.response){
		if(error.response.data) csrfToken = error.response.data.csrfToken;
		else csrfToken = error.response.csrfToken;
	}
	else csrfToken = error.csrfToken;
	dispatch({
		type: SET_CSRF_TOKEN,
		csrfToken: csrfToken
	});
}
/**
 * @description Perform logout API call and removes Auth tokens from LocalStorage.
 * Also take care of retry logics.
 */
const removeJWTAuthToken = (
	url,
	reqParams,
	params = {
		redirectTo: '/',
	},
) => {
	return (dispatch, getState) => {
		dispatch({
			type: API_IN_PROGRESS,
		});
		axiosInstance
			.post(url, reqParams)
			.then((response) => {
				if (response) {
					dispatch({
						type: SET_CSRF_TOKEN,
						csrfToken: response.data.csrfToken
					});
					LocalStorageServices.clearToken();
					window.location.replace(params.redirectTo);
				}
			})
			.catch(async(error) => {
                console.error(error);
				const refreshRetry = get(error, 'response.config._retry', false);
				if (refreshRetry) {
                    const state = getState();
                    const logoutURL = get(state, 'auth.login.logoutURL', '/auth/logout');
                    const originalRequest = error.config;
                    const accessToken = await dispatch(getRefreshToken());
                    if (accessToken) {
                        // Replace with new access_token value present in the case of logout.
                        if (originalRequest.data && originalRequest.url.includes(logoutURL)) {
                            let requestData = originalRequest.data;
                            requestData = JSON.parse(requestData);
                            requestData.access_token = accessToken;
                            originalRequest.data = requestData;
                        }
                        axiosInstance
                        .post(originalRequest.url, originalRequest.data)
                        .then((response) => {
                            if (response) {
								dispatch({
									type: SET_CSRF_TOKEN,
									csrfToken: response.data.csrfToken
								});
                                LocalStorageServices.clearToken();
                                window.location.replace(params.redirectTo);
                            }
                        })
                    }
                } else {
					dispatch({
						type: GET_QUESTION_FAILURE,
					});
					dispatch({
						type: SET_ACTION_FAILURE_MESSAGE,
					});
				}
			});
	}
}

/**
 * @description Removes Auth Token from local storage and redirects user to homepage (/ by default)
 */
export const removeAuthHeaders = () => {
	return (dispatch, getState) => {
        const state = getState();
        const logoutURL = get(state, 'auth.login.logoutURL', '/auth/logout');
        const accessToken = LocalStorageServices.getAccessToken();
		if (accessToken) {
			const reqParams = {
				access_token: accessToken,
			}
			dispatch(
				removeJWTAuthToken(logoutURL, reqParams, {
					redirectTo: '/',
				}),
			)
		} else {
			window.location.replace('/');
		}
	}
}