/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import { connect } from 'react-redux';
import { ReactSelect, Select } from 'crux';
import { withTheme } from 'styled-components';
import { isEmpty } from 'lodash';
import { axiosInstance } from '../util/axios';
import { updateAnswersArray } from '../redux/actions';
import { getLabelProps, getWidth, getTooltipProps } from './utils';
import { SelectWrapper } from './utils/styles';

const SelectOption = ({
	question,
	uid,
	theme,
	handleSelectOption,
	isEditMode,
	type,
	memberData,
	prefillData,
	memberDataIndex,
}) => {

	const inputRef = useRef();

	// Setting the initial response for the field
	useEffect(() => {
		if(question.response || isEditMode) {
			handleSelectOption(question.response, true, false, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		pageId,
		config: {
			theme: {
				pageOverrides,
				components,
				global: { colorScheme, colorOverride, reactSelect, labelStyles, selectLabel, spanText },
			},
		},
	} = theme;

	const {
		question_id: questionId,
		question_text: questionText,
		question_type: questionType,
		display_type: displayType,
		response_options: responseOptions,
		hint_title: tooltipTitle,
		hint_text: tooltipBody,
		hint_html: tooltipHTML,
		response,
		tooltip,
		validations,
		is_editable: disabled,
	} = question;

	const _defaultSelected = isPlainObject(response)
		? response.id
		: response;

	const [defaultSelected, setDefaultSelected] = useState(_defaultSelected);

	useEffect(() => {
		if(inputRef && inputRef.current && prefillData && type === 'D') {
			setDefaultSelected(prefillData.id);
			handleSelectOption(prefillData, true, false, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memberDataIndex]);

	const { floatingLabel, labelPosition, labelIsHTML, disableCapitalization=false } = getLabelProps(
		'selectOption',
		components,
		pageId,
		pageOverrides,
	);

	const themeConfiguration = colorOverride ?  {
		...colorOverride,
		selectedBgColor: colorOverride.optionSelectedBgColor,
		placeholder: { fontWeight: 'normal' },
	} : null;

	const reactSlectThemeConfiguration = reactSelect ? {
		...reactSelect,
	} : null;

	const getThemeConfiguration = () => {
		return { ...themeConfiguration, ...reactSlectThemeConfiguration }
	}

	const tooltipProps = getTooltipProps(tooltipTitle, tooltipBody, tooltipHTML || tooltip);

	let customSelectPlaceholder = get(validations, 'custom_placeholder.value', '');
	if(isEmpty(customSelectPlaceholder)) {
		customSelectPlaceholder = 'Select...';
	}

	const size = get(question, 'properties.size.value', '');

	let memberDataOptions;
	const hasMemberData = memberData && memberData.length && questionId.includes('BeneficiaryPrefill');
	if(hasMemberData) {
		memberDataOptions = memberData.map((v, idx) => {
			const year = Number(v.birthdate.split('/')[2])
			const age = new Date().getFullYear()-year
			const name = [
				...v.firstname ? [v.firstname.value] : [],
				...v.middlename ? [v.middlename.value] : [],
				...v.lastname ? [v.lastname.value] : [],
				...age ? [`(${age})`] : []
			]
			return { index: idx, id: name.join('-'), label: name.join(' ') }
		})
	}
	
	if ( displayType === 'dropdown' || displayType === 'dropdown_search' ) {
		return (
			<SelectWrapper floatingLabel= { floatingLabel }>
				<ReactSelect
					inputRef={ inputRef }
					key={ questionId }
					id={ questionId }
					floatingLabel={ floatingLabel }
					labelPosition={ labelPosition }
					labelText={ questionText }
					labelIsHTML={ labelIsHTML }
					placeholder="Please select an option"
					customSelectPlaceholder={ customSelectPlaceholder }
					options={ hasMemberData ? memberDataOptions : responseOptions }
					color={ colorScheme }
					themeConfiguration={ getThemeConfiguration() }
					defaultSelected={ defaultSelected }
					handleSelect={ (selected) => {
						if(type === 'D') setDefaultSelected(selected.id);
						handleSelectOption(selected, false, hasMemberData, true);
					} }
					type={ type }
					width={ getWidth('selectOption', pageId, pageOverrides, size) }
					isFilterable
					{ ...tooltipProps }
					disableCapitalization={ disableCapitalization }
					disabled={ !disabled }
					selectLabelProps={ selectLabel }
					labelTextStyles={ labelStyles }
					spanText={ {...spanText, fontFamily: 'Open Sans'} }
				/>
			</SelectWrapper>
		);
	}

	if (questionType === 'autocomplete-text') {
		const asyncPropsValue = {
			api: '/search',
			method: 'post',
			defaultParams: {
				uid,
				question_id: questionId,
				properties: JSON.stringify(get(question, 'properties.autocomplete', {}))
			},
			searchKey: 'key',
			fetchFn: axiosInstance,
			responseMap: {
				id: 'id',
				label: 'value',
			},
		};
		return (
			<SelectWrapper>
				<Select
					id={ questionId }
					floatingLabel={ false }
					labelPosition="top"
					labelText={ questionText }
					labelIsHTML
					placeholder="Enter 3 or more characters"
					customSelectPlaceholder={ customSelectPlaceholder }
					asyncProps={ asyncPropsValue }
					color={ colorScheme }
					handleSelect={ (selected) => {
						handleSelectOption(selected, false, false, true);
					} }
					defaultSelected={ defaultSelected }
					type="A"
					{ ...tooltipProps }
				/>
			</SelectWrapper>
		);
	}

	return null;
};

const mapStateToProps = (state, props) => {
	let prefillData;
	const questionId = get(props, 'question.question_id', '');
	const memberDataIndex = get(state, 'questions.memberDataIndex');
	const memberData = get(state, 'questions.otherParams.supplmentalresponse.member_data');
	const addressData = get(state, 'questions.otherParams.supplmentalresponse.address', {});
	if (questionId.includes('BeneState') && memberDataIndex !== null) {
		const stateName = addressData.state;
		prefillData = { id: `BeneState${stateName}`, label: stateName, value: `BeneState${stateName}` };
	}
	return {
		uid: state.uid,
		memberData,
		prefillData,
		memberDataIndex,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		handleSelectOption: (response, preventReflexiveCall, hasMemberData = false, isChangeEvent) => {
			const questionId = get(props, 'question.question_id', '');
			const { type } = props;
			// for multi-select and auto-suggest, store array.
			let updatedResponse = [response];
			if(type === 'A'){
				if(!isPlainObject(response)){
					updatedResponse = response;
				}
			}
			else if (type === 'M') {
				if(response && !isPlainObject(response)) {
					updatedResponse = response.map(
						option => option.id,
					);
				}
			}
			else {
				updatedResponse = response && response.id
			}
			dispatch(
				updateAnswersArray({
					questionId,
					response: updatedResponse,
					questionResponse: updatedResponse,
					sectionIndex: props.sectionIndex,
					preventReflexiveCall,
					propQuestion: props.question,
					listParentId: props.listParentId,
					listIndex: props.listIndex,
					isChangeEvent
				}),
			);
			if(hasMemberData) {
				dispatch({
					type: 'SET_MEMBER_DATA_INDEX',
					index: response.index,
				})
			}
		},
	};
};

export default withTheme(
	connect(mapStateToProps, mapDispatchToProps)(SelectOption),
);