import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Card } from 'crux';
import { getLoaders } from '../../../redux/selector/validations';
import Cover from '../../StyledComponents/MaskCover/Index';

const Heading = styled.div`
  flex: 9;
`;

const Edit = styled.div`
  color: #2965cc;
  flex: 1;
  cursor: pointer;
`;

const CollapsedCard = ({ children, onClick, processing }) => {
  return (
    <>
      { processing && <Cover /> }
      <Card collapsed>
        <Heading>{children}</Heading>
        <Edit onClick={ onClick }>Edit</Edit>
      </Card>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { isLoading, loadingSection } = getLoaders(state);
  return {
    processing: isLoading && loadingSection === props.sectionIndex,
  };
};
export default connect(mapStateToProps, null)(CollapsedCard);
