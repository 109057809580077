const LocalStorageServices = (() => {
    const tokenObjName = 'jwtToken';
    const ssoAccessToken = 'accessToken';

    const _getTokenItems = () => {
        const token = localStorage.getItem(tokenObjName);
        return JSON.parse(token);
    };

    const _setToken = (tokenObj) => {
        localStorage.setItem(tokenObjName, tokenObj);
    };

    const _setSSOToken = (token) => {
        localStorage.setItem(ssoAccessToken, token);
    }

    const _getAccessToken = () => {
        const tokenItems = _getTokenItems();
        return tokenItems ? tokenItems.value : null;
    };

    const _getRefreshToken = () => {
        const tokenItems = _getTokenItems();
        return tokenItems ? tokenItems.refresh : null;
    };

    const _getTokenExpirationTime = () => {
        const tokenItems = _getTokenItems();
        return tokenItems ? tokenItems.expiry : null;
    };

    const _clearToken = () => {
        localStorage.removeItem(tokenObjName);
    };

    const _getSsoAccessToken = () => {
        return localStorage.getItem(ssoAccessToken) || null;
    };

    return {
        setToken: _setToken,
        setSSOAccessToken: _setSSOToken,
        getAccessToken: _getAccessToken,
        getSSOAccessToken: _getSsoAccessToken,
        getRefreshToken: _getRefreshToken,
        getTokenExpirationTime: _getTokenExpirationTime,
        clearToken: _clearToken
    };
})();

export default LocalStorageServices;
