import styled from 'styled-components';

export const FooterNote = styled.p`
    font-size: ${({ styles })=>styles.fontSize|| '12px'};
    font-weight: 400;
    color: ${({ styles })=>styles.color || '#454545' };
    margin: 15px 0px;
    line-height: 1.33;
`;
export const FooterWrapper = styled.div`
    margin: 0 auto;
    ${({ layout }) => layout === 'single' ? `
        max-width: 80%;
    ` : `
        width: 90%;
        max-width: 780px;
        max-width:944px;
    `};
`;