/* eslint-disable no-unused-vars */
import React from 'react';

export default function ReflexiveCard({
  children,
  reflexLevel,
  hideWrapper,
}) {
  if (reflexLevel === 1) {
    return hideWrapper ? <>{ children }</> :
    (
      <div>
        <div>
          { children }
        </div>
      </div>
    )
  }
  if (reflexLevel === 2) {
    return(
      <div>
        {children}
      </div>
    )
  }
  return children;
}