import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MobileBreadCrumbs from '../../Components/UIElements/Breadcrumb/MobileBreadcrumbs';

const getWidth = (props) => {
  const { isSmallScreen, layout, children: { props: childProps } } = props;
  if (isSmallScreen) {
    return '90%';
  }
  if(childProps?.pageId === 'review'){
    return '67%'
  }
  if (layout === 'single') {
    return '80%'
  }
  return '67%';
}

const RightWrapper = styled.div`
  width: ${(props) => getWidth(props)};
  margin: 20px auto 120px auto;
  z-index: 98;
  @media (max-width: 480px) {
    z-index: 99;
  }
  margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '120px'}
  //  https://github.com/philipwalton/flexbugs/issues/157
  @media screen and (-ms-high-contrast: active) {
    margin: 20px auto 120px 0px;
  }
  @media screen and (-ms-high-contrast: none) { 
    margin: 20px auto 120px 0px;
  }
  background:  ${ (props) => props.background || '#d8d8d8' };
`;

const Right = ({
  isSmallScreen,
  content,
  layout,
  leftData,
  background,
}) => {
  if (isSmallScreen && layout === 'double' && !leftData.length) {
    return (
      <MobileBreadCrumbs name="mobile-breadcrumbs">
        <RightWrapper data-name="right" isSmallScreen={ isSmallScreen } background={ background }>
          { content }
        </RightWrapper>
      </MobileBreadCrumbs>
    )
  }
  return (
    <RightWrapper
      data-name="right"
      isSmallScreen={ isSmallScreen }
      layout={ layout }
      background={ background }
    >
      { content }
    </RightWrapper>
  )
};

Right.defaultProps = {};

Right.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  layout: PropTypes.string.isRequired,
};

export default Right;