/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import TextElement from '../TextElement';
import { 
  getNextListItemForm, 
  deleteBeneficiary, 
  addListItem, 
  setListIndex,
  setReflexiveListIndex,
  listSectionUpdates,
} from '../../../redux/actions/listQuestion';
import ListItemCard from './ListItemCard';
import { insertReflexiveQuestions } from '../../../utils';
import { getEnableAdd } from '../../../redux/selector/validations';

const Wrapper = styled.div`
  ${({ reflexLevel }) => (reflexLevel < 1 ) && `
    background: rgba(226, 226, 226, 1);
    margin-right: -100px;
`}
  margin-left:${({ isReview }) => isReview ? '0px' : '-100px'};
  margin-right:${({ isReview }) => isReview ? '0px' : '-100px'};
  padding: 20px;
  margin: 0% -5.5% 0% -5.5%;
  background:  ${( props ) => props.bgColor || 'inherit'};
  @media only screen and (max-width: 767px) {
    margin: 0% -4.5% 0% -4.5%;
    padding: 8px;
    margin-bottom: 70px;
  }
`;

const AddListItemButton = styled.button`
  border: 2px dashed rgba(41, 101, 204, 1);
  border-radius: 8px;
  background: transparent;
  padding: 20px;
  width: 100%;
  color: rgba(41, 101, 204, 1);
  text-align: left;
  font-size: 18px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:active {
    outline: 0;
  }
  &:focus {
    outline: 0;
  }
  @media only screen and (max-width: 767px) {
    font-size: 16px;
    padding: 10px;
  }
`;

const Icon = styled.i`
  padding-right: 10px;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'auto'};
`;

const toArray = (obj) => {
  return Object.keys(obj).reduce((acc, keys) => {
    if (isObject(obj[keys])) {
      acc.push(obj[keys])
    }
    return acc;
  }, [])
};

const getQuestions = (questionList) => {
  const title = questionList[0];
  const{ original_questions:originalQuestions }=questionList[0];
  let { questions } = questionList[0];

  // if already added list questions are not available, don't display
  if (!questions) {
    return {
      title,
      questions,
    }
  }
  questions = questions.map(item => toArray(item));
  questions = questions.map(item => insertReflexiveQuestions(item));
  // let totalShareAllocation = 0;
  let maxBeneficairyPercentageQuestion = 0;
  // const max_items = questions[0][questions[0].length - 1].validations.max_items;
  Object.values(questions).forEach((question) => {
    Object.values(question).forEach(q => {
      if(q && q.question_id && q.question_id.includes('BenefitPercentage')) { 
        maxBeneficairyPercentageQuestion = q;
        // totalShareAllocation += Number(q.response);
      }
    })
  })

  const validations =  get(maxBeneficairyPercentageQuestion, 'validations', 0)
  const percentage  = get(validations, 'percentage' , 0) ;
  const { error_message : percentageErrorMessage, question_id: questionId } = percentage ; 

  let enableAddBeneficiary = questions.length < 5 && questionId && questionId.includes('Benef');
  if(title.question_id.includes('Communication')) {
    enableAddBeneficiary = true;
  }

  return {
    title,
    questions,
    originalQuestions,
    enableAddBeneficiary,
    percentageErrorMessage,
    questionId,
  }
}

const capitalizeFirstLetter = (str) => {
  str = str.trim()
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getListQustionCardTitle = ({ title: parentListQuestion, questions: listCardQuestions = [], index: cardIndex= 0 }) => {
  const defaultTitle = get(parentListQuestion, ['list_titles', [cardIndex]], `${cardIndex + 1 }. ${parentListQuestion.question_text}`)
  const currentListCard = listCardQuestions[cardIndex];
  const isCommunicationGroup = parentListQuestion.question_id === "CommunicationGroup";
  if (!currentListCard) return !isCommunicationGroup ? defaultTitle : capitalizeFirstLetter(defaultTitle);

  const listTitleFormats = get(parentListQuestion, 'list_titles_formats', [])
  if (!listTitleFormats.length) return !isCommunicationGroup ? defaultTitle : capitalizeFirstLetter(defaultTitle);

  // from list_titles_format get common question id among the list cards
  const listTitleCheckQuestionId = get(parentListQuestion, ['list_titles_formats', [0], 'question_id'])
  if (!listTitleCheckQuestionId) return !isCommunicationGroup ? defaultTitle : capitalizeFirstLetter(defaultTitle);

  const listTitleCheckQuestion = currentListCard.find(
    q => (q.question_id.includes(`${listTitleCheckQuestionId  }_`)
          || q.question_id === listTitleCheckQuestionId));
  if (!listTitleCheckQuestion) return !isCommunicationGroup ? defaultTitle : capitalizeFirstLetter(defaultTitle);

  const listTitleCheckResponseId = isPlainObject(listTitleCheckQuestion.response) ? listTitleCheckQuestion.response.id : listTitleCheckQuestion.response;
  const formatMatch = listTitleFormats.find(formatObj => formatObj.response_id === listTitleCheckResponseId)
  // build list card title based on format key using questions from formatMatch
  if (formatMatch && isArray(formatMatch.questions) && formatMatch.format) {
    const titleFormat = formatMatch.format
    let titleStr = titleFormat
    formatMatch.questions.forEach(({ id, ref, format }) => {
      const refQ = currentListCard.find(
        ques => (ques.question_id.includes(`${id  }_`)
                  || ques.question_id === id));
      if (refQ) {
        let refQRes = isPlainObject(refQ.response) ? refQ.response.label : refQ.response
        if (format) {
          const [customMethod, ...args] = format.split(',')
          refQRes = typeof(refQRes[customMethod]) === 'function' && refQRes[customMethod] ? refQRes[customMethod](...args) : refQRes;
        }
        titleStr = titleStr.replace(ref, refQRes)
      } else if (ref.toLowerCase().includes('time') && format) {
        const options = {
          year: 'numeric', month: 'numeric', day: 'numeric',
          hour: 'numeric', minute: 'numeric',
          hour12: true,
          timeZone: 'America/New_York',
        };
        const date = new Date();
        titleStr = titleStr.replace(ref, new Intl.DateTimeFormat('en-US', options).format(date));
      }
    })
    return !isCommunicationGroup ? titleStr : capitalizeFirstLetter(titleStr);
  }
  return !isCommunicationGroup ? defaultTitle : capitalizeFirstLetter(defaultTitle);
}

function ListQuestionCard({
  questionList,
  handleAddListItem,
  sectionIndex,
  handleDelete,
  handleUpdate,
  updateListIndex,
  addButtonText,
  changeMode,
  reflexLevel,
  isReview,
  listParentId,
  listEditIndex,
  listEditQId,
  enableUpdate,
  handleCancel,
  updateReflexiveListIndex,
  theme,
}) {
  const [editIndex, updateEditIndex] = useState(-1);
  const { title, questions, enableAddBeneficiary, questionId } = getQuestions(questionList);
  const {
    config: {
      theme: {
        components: {
          listQuestionCardWrapper: { bgColor },
        },
      },
    },
  } = theme;


const { is_item_editable : hideEditButton, disable_add_button: disableAddButton } = questionList[0];

const getListQuestionItems = (questions) => {
  return questions.map((item, index) => (
    <ListItemCard
      index={ index }
      len={ questions.length }
      title={ getListQustionCardTitle({ title, questions, index }) }
      name='list-item-card'
      changeMode={ changeMode }
      onDeleteClick={ () => { handleDelete(index, sectionIndex) } }
      onEditClick={ () => { handleUpdate(sectionIndex, index, item) } }
      setEditIndex={ () => { updateListIndex(index) } }
      sectionIndex={ sectionIndex }
      enableUpdate={ enableUpdate }
      isEditMode = { (index === editIndex) || (listEditIndex === index && listEditQId === listParentId) }
      updateEditIndex = { updateEditIndex }
      listItem={ item } 
      isReview= { isReview }
      listParentId={ listParentId }  
      setReflexiveListIndex={ (idx, id) => { updateReflexiveListIndex(idx, id) } }
      handleCancel={ () => handleCancel(sectionIndex, item) }
      hideEditButton={ hideEditButton }
    />
  ))
}

const getListItemButton = () => (
  <AddListItemButton
    name='add-list-item-button'
    onClick={ handleAddListItem }
    disabled={ !enableAddBeneficiary }
  >
    <Icon disabled={ !enableAddBeneficiary } className='fa fa-plus-circle' />
    <span>{ addButtonText }</span>
  </AddListItemButton>
)

return (
  <div>
    <TextElement
      key={ title.question_id }
      styleType={ isReview ? 'heading' : 'questions_group' }     // this needs to be fixed.
      text={ title.question_text }
      sectionIndex={ sectionIndex } 
    />
    {
      title.question_status_message &&
      <TextElement
        key={ `${questionId}-error` }
        styleType='error'
        text={ title.question_status_message }
        sectionIndex={ sectionIndex }
      />
    }
    <Wrapper name='list-item-card-wrapper' reflexLevel={ reflexLevel } isReview={ isReview } bgColor={ bgColor }>
      { questions && getListQuestionItems(questions) }
      { !disableAddButton && editIndex === -1 && !isReview && getListItemButton() }
    </Wrapper>
  </div>
)}

const mapStateToProps = (state, props) => {
  const { sectionIndex, requiredListQIds } = props;
  const { listEditIndex, listEditQId, activeSubsectionId } = get(state, 'questions');
  const enableUpdate = getEnableAdd(
		state,
		sectionIndex,
    activeSubsectionId,
    requiredListQIds,
  );
  return {
    listEditIndex,
    listEditQId,
    enableUpdate,
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    handleAddListItem: () => {
      dispatch(getNextListItemForm(props.sectionIndex, props.listId));
      dispatch({
        type: 'SET_MEMBER_DATA_INDEX',
        index: null,
      })
    },
    handleDelete: (index, sectionIndex) => {
      dispatch(deleteBeneficiary(index, sectionIndex, props.listId))
      dispatch({
        type: 'SET_MEMBER_DATA_INDEX',
        index: null,
      })
    },
    handleUpdate: (sectionIndex, index, listQ) => {
      dispatch(setListIndex(index));
      const listQIds = listQ.map(q => q.question_id);
      dispatch(addListItem(sectionIndex, index, listQIds, props.listId));
      dispatch({
        type: 'SET_MEMBER_DATA_INDEX',
        index: null,
      })
    },
    updateListIndex: (index) => {
      dispatch(setListIndex(index));
      dispatch({
        type: 'SET_MEMBER_DATA_INDEX',
        index: null,
      })
    },
    updateReflexiveListIndex: (index, id) => {
      dispatch(setReflexiveListIndex(index, id));
      dispatch({
        type: 'SET_MEMBER_DATA_INDEX',
        index: null,
      })
    },
    handleCancel: (sectionIndex, listQ) => {
      const listQIds = listQ.map(q => q.question_id);
      dispatch(listSectionUpdates(sectionIndex, listQIds));
      dispatch({
        type: 'SET_MEMBER_DATA_INDEX',
        index: null,
      })
    },
  }
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(ListQuestionCard));

