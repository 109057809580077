import React from 'react';
import { withTheme } from 'styled-components';
import { ReactSelect } from 'crux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { updateAnswersArray } from '../redux/actions';
import { getCurrentQuestionSet } from '../redux/selector/questions';
import { getLabelProps, getWidth, getTooltipProps, getDataSelectorOptions, getMappedOptions } from './utils';

const DataSelector = ({ question, theme, updateAnswer, dataSelector }) => {

  const {
		question_id: questionId,
		question_description: questionText,
    response_options: responseOptions,
    selector_options: selectorOptions,
		hint_title: tooltipTitle,
		hint_text: tooltipBody,
		hint_html: tooltipHTML,
    tooltip,
    validations,
  } = question;

  const {
		pageId,
		config: {
			theme: {
				pageOverrides,
				components,
				global: { colorScheme },
			},
		},
  } = theme;
  
  const { floatingLabel, labelPosition, labelIsHTML } = getLabelProps(
		'selectOption',
		components,
		pageId,
		pageOverrides,
  );
  
  const tooltipProps = getTooltipProps(tooltipTitle, tooltipBody, tooltipHTML || tooltip);

  const customSelectPlaceholder = get(validations, 'custom_placeholder.value', '');
  
  const mappedOptions = getMappedOptions(responseOptions, selectorOptions);
  let dataSelectorOptions = [...mappedOptions];
  if(Array.isArray(dataSelector.get(questionId))){
    dataSelectorOptions = [...dataSelectorOptions, ...dataSelector.get(questionId)]
  }
  
  const handleSelectOption = ( response ) => {
    const options = dataSelectorOptions[response.id];
    options.forEach((option, index) => updateAnswer(option, selectorOptions[index].id, true));
  }

  return (
    <ReactSelect
      key={ questionId }
      id={ questionId }
      floatingLabel={ floatingLabel }
      labelPosition={ labelPosition }
      labelText={ questionText }
      labelIsHTML={ labelIsHTML }
      placeholder="Please select an option"
      customSelectPlaceholder={ customSelectPlaceholder }
      options={ getDataSelectorOptions(dataSelectorOptions) }
      color={ colorScheme }
      defaultSelected=''
      handleSelect={ (response) => {
        handleSelectOption(response);
      } }
      type='D'
      value = 'Select...'
      isControlled
      width={ getWidth('selectOption', pageId, pageOverrides) }
      isFilterable
      { ...tooltipProps }
    />
  )
};

const mapStateToProps = (state, props) => {
  const currentQuestionSet = getCurrentQuestionSet(state, props);
  const { dataSelector } = currentQuestionSet;
  return { dataSelector };
}
const mapDispatchToProps = (dispatch, props) => {
	return {
		updateAnswer: (option, id, isChangeEvent) => {
       dispatch(
				updateAnswersArray({
					questionId: id,
					response: option.response.value,
					questionResponse: option.response.value,
					sectionIndex: props.sectionIndex,
					preventReflexiveCall: false,
          propQuestion: props.question,
          isControlledInput: true,
          isChangeEvent
				}),
			);
		 },
	};
};

export default withTheme(
	connect(mapStateToProps, mapDispatchToProps)(DataSelector),
);
