/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle }  from 'styled-components';
import { Provider } from 'react-redux';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import Layout from './layout';
import { history } from './redux/configureStore';
import initApp from './initApp';
import { resizeListener } from './utils';
import HealthCheck from './healthCheck';
import { getNextQuestionSet } from './redux/actions';
import { getParams } from './util';

const GlobalStyle = createGlobalStyle`
  body {
	font-family: ${(props) => props.fontFamily };
  }
`
const Main = ({ config, store, ssoAuthConfig }) => {

	const _resizeListener = useCallback(resizeListener.bind(window, store), []);
	const fontFamily = get(config, 'theme.global.fontFamily', '');

	useEffect(() => {
		initApp(store, config, ssoAuthConfig);
		window.addEventListener('resize', _resizeListener);
		return () => {
			window.removeEventListener('resize', _resizeListener);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [config]);

	useEffect(() => {
		return history.listen(() => {
		  if (history.action === 'POP') {
			  const uid = getParams('uid');
			  if(uid === null) {
				  // this covers the case when user clicks on browser back button 
				  // at the landing page.
				  // https://sureify.atlassian.net/browse/NAT-1573
				window.history.back()
			  } else {
				store.dispatch(
					getNextQuestionSet('/questions', { uid }, { reload: true })
				)
			  }
			}
		})
	    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);
	  
	return (
		<Provider store={ store }>
			<Helmet>
				<title>
					{get(config, 'global.appTitle', 'Acquire')}
				</title>
			</Helmet>
			<HealthCheck>
				<ThemeProvider theme={ { config } }>
					<GlobalStyle fontFamily={ fontFamily } />
					<Layout />
				</ThemeProvider>
			</HealthCheck>
		</Provider>
	);
};

Main.propTypes = {
	config: PropTypes.any.isRequired,
};
export default withRouter(Main);
