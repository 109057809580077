/* eslint-disable no-unused-vars */
import get from 'lodash/get';
import { UPDATE_LIST_SECTION } from '../types';
import { getNextQuestionSet, getSectionAnswers } from './questions';

export const getAnswersArray = (state, sectionIndex) => {
  const currentBreadcrumbId = get(state, 'questions.activeSubsectionId', '');
  const currentSection = state.questions.sections[currentBreadcrumbId][sectionIndex]
  const pageSeqNumber = get(state, 'questions.currentPageSeqNumber', 1);
  const { completed, answersArray, questionList } = currentSection;
  return {
    completed,
    answersArray,
    questionList,
    pageSeqNumber,
  };
}

export const getListAnswersArray = (state) => {
  const currentBreadcrumbId = get(state, 'questions.activeSubsectionId', '');
  const currentSection = state.questions.sections[currentBreadcrumbId];
  const { questionList: questionTest } = getSectionAnswers(currentSection);
  return questionTest.map(question => {
    if(question.display_type === 'list') {
      return {
        question_id: question.question_id,
        answer: [],
        extra_params: { list_completed_flag: true },
      };
    }
    else if(question.display_type === 'questions_group') {
      return {
        question_id: question.question_id,
        answer: '',
      };
    }
  });
}

export const addListItem = (sectionIndex, index, qIds, listId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { uid } = state;
    const { answersArray, questionList, pageSeqNumber } = getAnswersArray(state, sectionIndex, true);

    let extraParams = { list_completed_flag: false }

    // In case of edit and update, index will be defined
    if (Number.isInteger(index) && index >= 0) {
      extraParams = {
        ...extraParams,
        current_list_answer_index: index,
      }
    }

    const answer = [];
    const listQs = answersArray.filter(question => qIds.indexOf(question.question_id) > -1);
    listQs.forEach(q => {
      const temp = { question_id: q.question_id, answer: q.answer };
      answer.push(temp);
    })

    const questionParams = [
      {
        question_id: listId,
        answer,
        extra_params: extraParams,
      },
    ];

    const reqParams = {
      uid,
      questions: questionParams,
      page_sequence_number: pageSeqNumber,
      index_modify_flag: 1,
      list_question_id: listId,
    }

    /**
     * Adding this block to track the usage of prefill data
     * If memberDataIndex in questions has any integer value then 
     * we are sending used_prefill as true in reqParams else reqParams are unchanged
    */
    const { questions } = state;
    if (questions.memberDataIndex !== null && questions.memberDataIndex !== undefined) {
			reqParams.used_prefill = true;
		}

    if(!questions.isLoading){
      dispatch(getNextQuestionSet('/questions', reqParams, {
        isReflexive: true,
        sectionIndex,
        questionId: get(questionList[0],'question_id',''),
        isList: true,
        ...reqParams,
      }))
    }
  }
}

export const getNextListItemForm = (sectionIndex, listId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { uid, questions } = state;
    const { currentPageSeqNumber } = questions;

    const reqParams = {
      uid,
      page_sequence_number: currentPageSeqNumber,
      index_modify_flag: 1,
      next_page_flag: 1,
      list_question_id: listId,
    }
    dispatch(getNextQuestionSet('/questions', reqParams, {
      isReflexive: true,
      sectionIndex,
      isListAddAction: true,
      ...reqParams,
    }));
  }
}

export const submitListAnswers = (sectionIndex, action='', listId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { uid } = state;
    const { questionList, pageSeqNumber} = getAnswersArray(state, sectionIndex, true);

    // for beneficiaries and contingent beneficiary
    const groupQuestion = questionList.filter(question => question.display_type === 'questions_group')[0];
    const listQuestion = questionList.filter(question => question.display_type === 'list')[0];

    let questionParams = [
      {
        question_id: listId || listQuestion.question_id,
        answer: [],
        extra_params: {
          list_completed_flag: true,
        },
      },
      {
        question_id: groupQuestion.question_id,
        answer: '',
      },
    ];
    
    if (action === 'CONFIRM_UPDATE') {
      questionParams = getListAnswersArray(state);
    }

    const extraParams = action ? { action }  : { next_page_flag: 1 }

    let reqParams = {
      uid,
      page_sequence_number: pageSeqNumber,
      questions: questionParams,
      ...extraParams,
    };

    if(action === 'CANCEL_UPDATE'){
      reqParams = {
        uid,
        page_sequence_number: pageSeqNumber,
        ...extraParams,
      }
    }

    dispatch(getNextQuestionSet('/questions', reqParams, {
      sectionIndex, 
      sectionId: get(questionList[0],'question_id',''),
      ...reqParams,
    }));
  }
}

export const listSectionUpdates = (sectionIndex, listQIds) => {
  return (dispatch, getState) => {
    const state = getState();
    const activeSubsectionId = get(state, 'questions.activeSubsectionId', '');
    dispatch({
      type: UPDATE_LIST_SECTION,
      activeSubsectionId,
      payload: {
        sectionIndex,
        listQIds,
      },
    })
  }
}

export const deleteBeneficiary = (index, sectionIndex, listId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { uid } = state;
    const { questionList, pageSeqNumber } = getAnswersArray(state, sectionIndex, true);

  // for beneficiaries and contingent beneficiary
    const listQuestion = questionList.filter(question => question.display_type === 'list')[0];
    const groupQuestion = questionList.filter(question => question.display_type === 'questions_group')[0];

    const questionParams = [
      {
        question_id: listId || listQuestion.question_id,
        answer: [],
        extra_params: {
          list_completed_flag: false,
          current_list_answer_index: index,
        },
      },
      {
        question_id: groupQuestion.question_id,
        answer: '',
      },
    ];

    const reqParams = {
      uid,
      page_sequence_number: pageSeqNumber,
      delete_index_flag: 1,
      questions: questionParams,
    }

    dispatch(getNextQuestionSet('/questions', reqParams, { isReflexive: true }));
  }
}

export const setListIndex = (index) => ({
  type: 'SET_LIST_INDEX',
  index,
})

export const setReflexiveListIndex = (index, id) => ({
  type: 'SET_REFLEXIVE_LIST_INDEX',
  index,
  id,
})

export const closeAddListForm = (sectionIndex) => {
  return (dispatch, getState) => {
    const state = getState();
    const { uid } = state;
    const reqParams = {
      uid,
    }
    // adding isReflexive as we need to override the redux data to new questions
    dispatch(getNextQuestionSet('/questions', reqParams,{ sectionIndex, isReflexive:true }))
  }
}
