import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ data }) => (
	<a className="footer-links" href={ data.linkUrl } title={ data.linkText } rel="noopener noreferrer" target="_blank">
		{ data.linkText }
	</a>
);

export default function FooterBottomLinks({ links }) {
	return (
		<div className="footer-links-container">
			{ links.map(link => <Link href= ' ' key={ link.id } data={ link } />) }
		</div>
	);
}

FooterBottomLinks.propTypes = {
	links: PropTypes.arrayOf(PropTypes.object).isRequired,
};