/* eslint-disable import/no-cycle */
import Axios from 'axios';
import get from 'lodash/get';
import { convertQueryStringToObject } from '.';
import LocalStorageServices from './localStorageServices';
import host from './host';
import { store } from './getLandingPage';

const { REACT_APP_BASE_URL } = host();

export const axiosInstance = Axios.create({
  baseURL: REACT_APP_BASE_URL,
  method: 'post',
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  request => {
    if (request && request.data) {
      const searchParams = window.location.search.replace('?', '');
      const queryObject = convertQueryStringToObject(searchParams);
      const accessToken = LocalStorageServices.getAccessToken() || LocalStorageServices.getSSOAccessToken();
      Object.keys(queryObject).forEach(key => {
        try {
          if (!(key in request.data)) {
            request.data[key] = queryObject[key];
          }
        } catch (e) {
          console.error(e);
        }
      });
      if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    request.headers['x-csrf-protection'] = store.getState().questions.csrfToken;
    return request;

  },
  err => Promise.reject(err),
);

/**
 *  @description Axios Interceptor to fetch new access token via 
 * refresh token (if the response code comes as unauthorized 401).
 */ 
axiosInstance.interceptors.response.use(
  (response) => {
    const questionnaireData = get(response, 'data.response.data.questionnaire', false);
    
    if (!questionnaireData) return response;

    response.data.response.data.questionnarie = questionnaireData;
    delete response.data.response.data.questionnaire;

    return response;
  }, (error) => {
    const originalRequest = error.config;

    // If the refresh token is not valid
    if (error.response && 
      (error.response.status === 400 || error.response.status === 401) && 
      originalRequest.url.includes('/auth/refresh_token')) 
    {
      console.error(error);
      LocalStorageServices.clearToken();
    }

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    if (response.data && response.data.response) {
      const { tag_manager: tagManager } = get(
        response,
        'data.tag_manager.pages',
        [],
      );
      const { tag_manager_fields: tagManagerFields } = response.data;

      if (tagManager && tagManager.length > 0) {
        window.initialTagManager = tagManager;
      } else {
        window.initialTagManager = window.initialTagManager || [];
      }

      window.uniqueTransactionId =
        response.data.transactionId ||
        (response.data.tag_manager &&
          response.data.tag_manager.transaction_id) ||
        window.uniqueTransactionId;
      if (tagManagerFields) {
        if (
          tagManagerFields.gtmQuestionsList &&
          tagManagerFields.gtmQuestionsList.length > 0
        ) {
          window.gtmQuestionsList = tagManagerFields.gtmQuestionsList;
        } else {
          window.gtmQuestionsList = window.gtmQuestionsList || [];
        }

        if (
          tagManagerFields.virtualPageVisitsQuestionsList &&
          tagManagerFields.virtualPageVisitsQuestionsList.length > 0
        ) {
          window.virtualPageVisitsQuestionsList =
            tagManagerFields.virtualPageVisitsQuestionsList;
        } else {
          window.virtualPageVisitsQuestionsList =
            window.virtualPageVisitsQuestionsList || [];
        }

        if (
          tagManagerFields.virtualPageVisitsQuestionsWithAnsConsidered &&
          tagManagerFields.virtualPageVisitsQuestionsWithAnsConsidered.length >
            0
        ) {
          window.virtualPageVisitsQuestionsWithAnsConsidered =
            tagManagerFields.virtualPageVisitsQuestionsWithAnsConsidered;
        } else {
          window.virtualPageVisitsQuestionsWithAnsConsidered =
            window.virtualPageVisitsQuestionsWithAnsConsidered || [];
        }
      }
    } else {
      window.initialTagManager = window.initialTagManager || [];
      window.gtmQuestionsList = window.gtmQuestionsList || [];
      window.virtualPageVisitsQuestionsList =
        window.virtualPageVisitsQuestionsList || [];
      window.virtualPageVisitsQuestionsWithAnsConsidered =
        window.virtualPageVisitsQuestionsWithAnsConsidered || [];
    }
    return response;
  },
  err => Promise.reject(err),
);


export const TEST = 'THIS IS A FAKE EXPORT, NEED TO REFACTOR BEFORE MAKING axiosInstance DEFAULT EXPORT';
