import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Card } from 'crux';
import Section from './Section';

const ReviewQuestions = ({
  sectionData,
  index,
  processing,
  theme,
  isSmallScreen,
}) => {

  const {
    id,
    questionList,
    review,
  } = sectionData;

  if(questionList.length === 1 && questionList[0].question_type === 'group')
    return <div />;

  const {
    pageId,
    pageLayout,
    config: {
      theme: {
        components: {
          card: { padding },
        },
      },
    },
  } = theme

  let otherProps = {};
  let imgUrl = '';
  if (questionList.length === 1 && questionList[0].isBreadcrumbCard) {
    otherProps = {
      padding: '0',
    }
  }
  if(questionList.length === 1){
      imgUrl = questionList[0].img_url;
  }
  let hiddenQuestions = questionList.filter(question => question.is_hidden);
  let isReviewCardHidden = hiddenQuestions.length === questionList.length;
  return !isReviewCardHidden ? (
    <Card 
        imgUrl= { imgUrl }
        key={ id }
        layout={ pageLayout }
        { ...otherProps }
        padding={ imgUrl ? '0' : (isSmallScreen ? padding : '60px 100px 40px 100px') }
    >
      <Section
        questionList={ questionList }
        key={ id }
        sectionIndex={ index }
        pageId={ pageId }
        reviewProps={ review }
        processing={ processing }
      />
    </Card>
  ) : null;
}

ReviewQuestions.propTypes = {
  sectionData: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(ReviewQuestions);
