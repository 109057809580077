import React from 'react';
import styled from 'styled-components';
import BreadcrumbWrapper from '../../Components/UIElements/Breadcrumb/wrapper';
import GlobalProgress from '../../Components/UIElements/GlobalProgress';
import LeftPanelQuestions from '../../Pages/LandingPage/leftPanelQuestions';

const SmallScreenWrapper = styled.div`
  background: ${({ background }) => background || '#f7f7f7'};
  width: 100%;
  top: 0;
  position: sticky;
  z-index: 100;
`;

const NormalScreenWrapper = styled.div`
  background: ${({ background }) => background || '#f7f7f7'};
  width: ${({ width }) => width};
  margin-top: 0;
  position: relative;
  /* z-index: 99; */
  @media (min-width: 768px) and (max-width: 840px) {
    width: 40%;
  }
  @media (min-width: 840px) and (max-width: 868px) {
    width: 35%;
  }
`;

const Left = ({ isSmallScreen, layout, leftData, leftSidebarColor }) => {
  if (isSmallScreen && layout === 'double' && !leftData.length) {
    return (
      <SmallScreenWrapper
        background={ leftSidebarColor }
        isSmallScreen={ isSmallScreen }
        data-name='left'
      >
        <GlobalProgress isSmallScreen={ isSmallScreen } />
      </SmallScreenWrapper>
    )
  }

  let LeftContent = (leftData.length) ? <LeftPanelQuestions leftData={ leftData } /> :
                    (layout === 'double') ? <BreadcrumbWrapper /> : null
  return LeftContent ? (
    <NormalScreenWrapper
      background={ leftSidebarColor }
      isSmallScreen={ isSmallScreen }
      data-name='left'
      width={ isSmallScreen && leftData.length ? '100%' : '33%' }
    >
      {LeftContent}
    </NormalScreenWrapper>
  ) : null;
}

export default Left;
