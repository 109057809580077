import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
	overflow: hidden;
	margin: 20px auto;
	width: 100%;
	box-shadow: 0 0 20px rgba(0, 0, 0, .05), 0 0px 40px rgba(0, 0, 0, .08);
	border-radius: 8px 8px;
	background-color: rgba(255, 255, 255, 1);
	display: flex;
	flex-flow: column;
	flex: 1;
	position: relative;
	overflow: visible;
	
	@media (min-width: 1025px) {

	}

	@media (min-width: 768px) and (max-width: 1024px) {

	}

	@media only screen (max-width: 767px) {
    flex-flow: column;
		padding: 5px 20px;
		padding-bottom: 60px;
	}
`;

const CardBody = styled.div`
    width: 100%;
	height: 100%;
	overflow: visible;

	@media (min-width: 1025px) {
		padding: ${props => props.footer ? '20px 20px 20px 0' : '100px'};
		padding-top: ${props => props.footer ? '20px' : '80px'}
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		padding: ${props => props.footer ? '20px 20px 20px 0' : '70px'};
		padding-top: ${props => props.footer ? '20px' : '60px'}
	}

	@media (max-width: 767px) {
		padding: 60px 20px;
	}

	@media (max-width: 480px) {
		padding: 40px 20px;
	}
`;

const CardHeaderContent = styled.div`
    border-radius: 8px 8px 0px 0px;
    font-size: 18px;
    color: rgba(69, 69, 69, 1);
    line-height: 26px;
    width: 100%;
`;

const CardComponent = ({ heading, body, children, onlyChildren, footer }) => {
	if (onlyChildren) {
		return children;
	}

	return (
		<CardWrapper name='card-wrapper'>
			{ heading && <CardHeaderContent name='card-content'> {heading} </CardHeaderContent> }
			<CardBody name='card-body' footer={ footer }>{body || children}</CardBody>
		</CardWrapper>
	)
};

export default CardComponent;
