import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { ButtonBlue } from '../../Components/UIElements';
import './landingPage.css';
import { getNextQuestionSet } from '../../redux/actions';
import { getValidators } from '../../cruxComponents/utils';

const ItemContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const TableHeading = styled.div`
	font-size: 24px;
	padding: 0 10px;
`;

const TableItem = styled.div`
	margin: 20px 0;
	font-size: 16px;
	padding: 0 10px;
`;

const SolidLine = styled.hr`
	border-top: '1px solid rgba(216, 216, 216, 1)';
`;

const DashedLine = styled.div`
	height: 1px;
	background-image: linear-gradient(
		to right,
		rgba(179, 179, 179, 1) 40%,
		rgba(255, 255, 255, 0) 0%
	);
	background-position: top;
	background-size: 15px 1px;
	background-repeat: repeat-x;
`;

const TableWrapper = styled.div`
  margin-top: 330px;
  margin-bottom: 60px;
  width: 65%;
  margin-left: auto;
  margin-right: 30px;
  @media only screen and (max-width: 767px) {
    margin: auto;
    margin-top: 20px;
    background: white;
    padding: 30px 20px;
    border-radius: 8px;
  }
`;

const QuestionItem = (props) => {
  const {
    question_text: questionText,
    response,
    display_type: displayType,
    is_edit_icon_visible: isEditable,
  } = get(props, 'question', {});
  const { makeAPICall, question } = props;
  let res = response;
  const validators = getValidators(question)
  const { currency } = validators
  if( res && currency && currency.preserveFormattingInCb ) {
      res = new Intl.NumberFormat(currency.format).format(res)
  }
  const editClickHandler = () => {
    /** The formData passed in  should be fetched from backend
      will change the logic later */
    const { planPageId, uid } = props
    const editUrl = '/questions';
    const formData = {
      uid,
     q_id: planPageId,
    }
    const params = {
      sectionId:'quoteEdit',
    }
    makeAPICall(editUrl, formData, params);
  };

  return (
    <>
      {displayType === 'questions_group' ? (
        <ItemContentWrapper>
          <TableHeading>{questionText}</TableHeading>
          {isEditable && (
            <ButtonBlue
              label="Edit"
              btnType="left-panel-link"
              btnClickHandler={ () => editClickHandler() }
            />
          )}
        </ItemContentWrapper>
      ) : (
        <ItemContentWrapper>
          <TableItem>{questionText}</TableItem>
          <TableItem>
            {typeof res === 'object' ? res.label : res}
          </TableItem>
        </ItemContentWrapper>
      )}
    </>
  );
};

const LeftPanelQuestions = (props) => {
  const { leftData, makeAPICall, pageSeqNumber, uid, planPageId } = props;

  return (
    <div>
      <TableWrapper>
        {leftData.map((question, index) => {
          const { display_type: displayType, is_hidden: isHidden } = question;
          if(isHidden) {
            return null
          } return (
            <div>
              <QuestionItem
                uid={ uid }
                pageSeqNumber={ pageSeqNumber }
                question={ question }
                index={ index }
                makeAPICall={ makeAPICall }
                planPageId={ planPageId }
              />
              { index+1 !== leftData.length && (displayType === 'questions_group' ? <SolidLine /> : <DashedLine />) }

            </div>
          );
        })}
      </TableWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { uid } = state;
  const activeSubsectionId = get(state.questions, 'activeSubsectionId', '');
  const sectionIndex = get(state.questions, 'sectionIndex', '')
  const section = state.questions.sections[activeSubsectionId];
  const questionObj = get(section[sectionIndex], 'questionObj' )
	const planPageId = get(questionObj,'plan_page_id', '');
	return {
		uid,
		planPageId,
	};
};

const mapDispatchToProps = (dispatch) => {
  return {
    makeAPICall: (url, formData, params) => {
      dispatch(
        getNextQuestionSet(url, formData, params),
      );
    },
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LeftPanelQuestions);
