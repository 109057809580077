/* eslint-disable import/no-cycle */
import React, { useRef } from 'react';
import styled from 'styled-components';
import './button.css';
import { scrollUP } from '../../../util';

// this function is used to trun the subsection button to blue or green
function checkFormFilled(isBtnEnabled, isSubsectionBtnClicked, isCancelButton) {
  // if the form is filled and :
  // 1) if the subsection button is clicked then button is turned to green
  // 2) if the subsection button is not clicked then the button is highlighted as blue
  if(isCancelButton){
    return 'red';
  }
  if (isBtnEnabled) {
    return isSubsectionBtnClicked ? 'green' : 'blue'
  }
  // if the form is not filled then we return grey
  return 'grey';
}

// StyledButton is the base component for all remaining Buttons
const StyledButton = styled.button.attrs(props => ({
  type: props.type,
  tabIndex: props.tabIndex,
  className: props.className,
  onClick: props.onClick,
  style: props.style,
  isDisabled: props.isDisabled,
}))`
  width: ${props => props.width};
  color: ${props => props.color};
  ${props => props.className};
`;

const FooterButton = styled(StyledButton)`
  ${props => !props.disabled ? 'background-color :rgba(0, 115, 208, 1); color: white;' :
  'background-color: rgba(247, 247, 247, 1); color:  rgba(118, 118, 118, 1);'};
	border-radius: ${({ btnType }) =>
		btnType === 'rectangle' ? '4px' : '16px'};
	border: ${({ disabled }) =>
		disabled
			? '1px solid #cccccc'
			: '1px solid rgba(153, 153, 153, 1);'};
	font-size: 18px;
	padding: 10px 34px;
	font-weight: 600;
	position: initial;
	height: 46px;
	text-align: center;
	cursor: pointer;
	margin: 10px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-html-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-transform: none;
	${({ page })=> page && 'margin:\'0px\''};
  &:focus {
      outline: 0;
  }

  &:active {
    background-color: rgba(0, 115, 208, 1);
    color: white;
  }

  @media only screen and (max-width: 480px) {
      margin: 0px;
  }
`;

const ButtonPillLink = styled(StyledButton)`
  color: rgba(0, 115, 208, 1);
  background: transparent;
  border: 0;
  outline: none;
  padding: 10px;
  font-weight: 600;
  font-size: 18px;
  & {
    margin-right: 111px !important;
  };

  @media only screen and (max-width: 480px) {
    margin-right: 0px;
  }
`

const ButtonLeftPanelLink = styled(StyledButton)`
  color: rgba(41, 101, 204, 1);
  background: transparent;
  border: 0;
  padding: 10px;
  font-weight: 200;
  font-size: 16px;
  & {
    margin-right: 0 !important;
    margin-left: 10px;
  };

  @media only screen and (max-width: 480px) {
    margin-right: 0px;
  }
`

const SubSectionButton = styled(StyledButton)`
  background: none;
  border: none;
  outline: none;
  font-size: 18px;
  color: ${({ color, completed }) => completed ? '#00ad5e' : color};
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const FaIcon = styled.i`
  display: ${({ isCancelButton }) => isCancelButton ? 'none' : 'inline-block'};
  margin-left: 10px;
`;

const FaCrossIcon = styled.i`
  display: ${({ isCancelButton }) => !isCancelButton ? 'none' : 'inline-block'};
  margin-left: 10px;
`


const ButtonBlue = ({
  label,
  btnType,
  disabled,
  btnClickHandler,
  className,
  width,
  isCancelButton = false,
}) => {
  // this ref is used to check if sub section button is clicked or not.
  const isButtonClicked = useRef(false);
  
  function handleButtonClick() {
    // we pass second parameter 'true' to tell the checkFormFilled() that
    // sub section button is clicked which is used to color the button green
    isButtonClicked.current = true;
    checkFormFilled(!disabled, isButtonClicked.current, isCancelButton);
    // making this false because if there is any error or if there are fields
    // that are required to fill we should not make the button green
    isButtonClicked.current = false;
    btnClickHandler();
  }

  switch (btnType) {
    case 'pill': {
      return (
        <FooterButton
          type="submit"
          className={ `${disabled && 'disabled'} ${className}` }
          onClick={ btnClickHandler }
          style={ { width } }
          tabIndex={ disabled ? '-1' : '' }
        >
          {label}
        </FooterButton>
      );
    }

    case 'rounded': {
      return (
        <FooterButton
          type="submit"
          tabIndex={ disabled ? '-1' : '' }
          className={ `${disabled && 'disabled'}` }
        >
          {label}
        </FooterButton>
      );
    }

    case 'plain': {
      return (
        <StyledButton
          type="submit"
          className={ `${disabled && 'disabled'}` }
          tabIndex={ disabled ? '-1' : '' }
        >
          {label}
        </StyledButton>
      );
    }

    case 'link': {
      return (
        <ButtonPillLink
          type="submit"
          className={ `${className}` }
          tabIndex={ disabled ? '-1' : '' }
          onClick={ () => {
            scrollUP();
            if (disabled) return false;
            return btnClickHandler() || null;
          } }
        >
          {label}
        </ButtonPillLink>
      );
    }

    case 'left-panel-link': {
      return (
        <ButtonLeftPanelLink
          type="submit"
          className={ `${className}` }
          tabIndex={ disabled ? '-1' : '' }
          onClick={ () => {
            scrollUP();
            if (disabled) return false;
            return btnClickHandler() || null;
          } }
        >
          {label}
        </ButtonLeftPanelLink>
      );
    }

		case 'sub-section': {
			return (
				<SubSectionButton
					onClick={ handleButtonClick }
					width={ width }
					isDisabled={ disabled }
          disabled={ disabled }
					// if 'disbled' prop is true then do not enable the button and the color of button
					// should be grey, icon is fa-chevron-circle-right else if 'disabled' prop is false
					// then we need to enable the button and in this case the color of button is blue
					// and icon is fa-check-circle and when the user presses this enabled button turn
					// it to green which of course we cannot see as of now as it nvigates to next page.
					isButtonClicked={ isButtonClicked.current }
					color={ (props) =>
						props.disabled
							? '#808080'
							: checkFormFilled(
									!props.isDisabled,
                  props.isButtonClicked,
                  isCancelButton,
							  )
					}
				>
					{label}
					<FaIcon
            isCancelButton={ isCancelButton }
						className={
							disabled
								? 'fa fa-chevron-circle-right'
								: 'fa fa-check-circle'
						}
						aria-hidden="true"
					/>
          <FaCrossIcon
            isCancelButton={ isCancelButton }
						className='fa fa-times'
						aria-hidden="true"
					/>
				</SubSectionButton>
			);
    }
		case 'rectangle':
			return (
				<FooterButton
					type="submit"
					btnType={ btnType }
					disabled={ disabled }
					tabIndex={ disabled ? '-1' : '' }
					onClick={ () => {
						if (disabled) return false;
						return btnClickHandler() || null;
					} }
				>
					{label}
				</FooterButton>
			);

    case 'page':{
      return (
        <FooterButton
          type="submit"
          tabIndex={ disabled ? '-1' : '' }
          className={ `${disabled && 'disabled'}` }
          id="submit-button"
          onClick={ () => {
            if (disabled) return false;
            return btnClickHandler() || null;
          } }
          disabled={ disabled }
          page
        >
          {label}
        </FooterButton>
      );
    }

    default: {
      return (
        <FooterButton
          type="submit"
          tabIndex={ disabled ? '-1' : '' }
          className={ `${disabled && 'disabled'}` }
          id="submit-button"
          onClick={ () => {
            scrollUP();
            if (disabled) return false;
            return btnClickHandler() || null;
          } }
          disabled={ disabled }
        >
          {label}
        </FooterButton>
      );
    }
  }
};

export default ButtonBlue;
