/* eslint-disable max-len */
/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import findLast from 'lodash/findLast';
import moment from 'moment';
import momenttz from 'moment-timezone';

import { getParams } from '../util';
import host from '../util/host';
import router from '../util/router';

export const currentSocket = null;
class HealthCheck extends React.PureComponent {
	constructor(props) {
		super(props);
		this.healthCheckInitiated = false;
		this.uid = '';
		this.excludePages = ['timeout', 'authcode'];
		this._connectSocket = this._connectSocket.bind(this);
	}

	componentDidMount() {
		const { uid, sessionId, sessionType } = this._getIds();

		if (uid || sessionId) {
			this.uid = uid || sessionId;
			this.sessionType = sessionType;
			if (this._shouldStartSocketConnection()) {
				this._connectSocket.call(this);
				this.healthCheckInitiated = true;
				console.log(
					'HEALTH CHECK::: Initiating Health Check in Component Did Mount.',
				);
				console.log('HEALTH CHECK::: UID: ', this.uid);
			}
		}
		if (this.healthCheckInitiated) {
			this._emitUpdatePageVisits();
		}
	}

	componentDidUpdate() {
		const { uid, sessionId, sessionType } = this._getIds();
		if (!this.healthCheckInitiated && (uid || sessionId)) {
			this.uid = uid || sessionId;
			this.sessionType = sessionType;
			if (this._shouldStartSocketConnection()) {
				this._connectSocket.call(this);
				this.healthCheckInitiated = true;
				console.log(
					'HEALTH CHECK::: Initiating Health Check in Component Did Update.',
				);
				console.log('HEALTH CHECK::: UID: ', this.uid);
			}
		}
	}


	_getIds = () => {
		const { location } = window;
		let uid = '';
		let sessionId = '';
		let sessionType = '';
		if (location.search.match(/uid/)) {
			uid = getParams('uid');
			sessionType = 'uid';
		}
		if (location.search.match(/session_id/)) {
			sessionId = getParams('session_id');
			sessionType = 'session_id';
		}
		return {
			uid,
			sessionId,
			sessionType,
		};
	}

	_emitUpdatePageVisits() {
		this.socket.emit(
			'UPDATE_PAGE_VISITS',
			JSON.stringify({
				uid: this.uid,
				last_visited_page: findLast(window.initialTagManager, (e) => {
					return e;
				}),
				tag_manager: uniqBy(window.initialTagManager, (e) => {
					return e.page_id;
				}),
			}),
		);
	}

	_shouldStartSocketConnection() {
		const currentPath = window.location.pathname.replace('/', '');
		if (currentPath === '') {
			return true;
		}
		return !this.excludePages.filter((pagePath) =>
			pagePath.match(currentPath),
		).length;
	}

	// eslint-disable-next-line class-methods-use-this
	_getUserData() {
		return {
			userAgent: navigator.userAgent,
			sourcePage: window.location.pathname,
			sourceUrl: window.location.search,
			userTimeZone: moment(),
			currentDateTime: momenttz.tz.guess(),
		};
	}

	_connectSocket() {
		console.log(
			'Current Socket available before creating new socket connection?',
		);
		const { WS_BASE_URL } = host();
		this.socket = io.connect(WS_BASE_URL, {
			transports: ['websocket'],
			upgrade: true,
			query: {
				...this._getUserData(),
				uid: this.uid,
				sessionType: this.sessionType,
			},
			reconnection: true,
		});
		this.socket.on('VERSION_CHECK', (data) => {
			const currBuildVersion = data['X-Sureify-Version'];
			const localBuildVersion = localStorage.getItem('X-Sureify-Version');
			const { handleNewBuildAvailable } = this.props;

			if (localBuildVersion === null) {
				localStorage.setItem('X-Sureify-Version', currBuildVersion);
			} else if (currBuildVersion !== localBuildVersion) {
				handleNewBuildAvailable();
				localStorage.removeItem('X-Sureify-Version');
				localStorage.setItem('X-Sureify-Version', currBuildVersion);
			}

			this.socket.emit('VERSION_INFO', {
				'Local-Build-Version': localBuildVersion,
			});
		});
		this.socket.on('disconnected', () => {
			this.socket.emit('DISCONNECTED', { data: '12345' });
		});
		console.log('Connecting : ', WS_BASE_URL);
		this.socket.emit(
			'START_SESSION',
			JSON.stringify({
				...this._getUserData(),
				uid: this.uid,
				sessionType: this.sessionType,
			}),
		);
		this.socket.on('reconnect', () => {
			console.log('Reconnecting : ', WS_BASE_URL);
			this.socket.emit(
				'START_SESSION',
				JSON.stringify({
					...this._getUserData(),
					uid: this.uid,
					sessionType: this.sessionType,
					reconnect: true,
				}),
			);
		});
		this.socket.on('PING_EXPIRE_SESSION', (data) => {
			const modifiedResponse = data.res;
			console.log(
				'HEALTH CHECK::: SOCKET Data received',
				JSON.stringify(data),
			);
			const uid = getParams('uid');
			const sessionId = getParams('session_id');
			if (uid === data.uid || sessionId === data.uid) {
				const questionnaireData = get(modifiedResponse, 'response.data.questionnaire', false);

				if (questionnaireData) {
					modifiedResponse.response.data.questionnarie = questionnaireData;
				    delete modifiedResponse.response.data.questionnaire;
				}

				const currentStatus = get(
					data,
					'res.response.other_params.current_status',
					'',
				);
				const { pathname } = window.location;
				if (pathname && currentStatus) {
					let searchParam = '';
					if (uid) {
						searchParam = `?uid=${uid}`;
					} else {
						searchParam = `?session_id=${sessionId}`;
					}
					this.socket.emit(
						'PONG_EXPIRE_SESSION',
						JSON.stringify({
							uid: this.uid,
							searchParam,
							...this._getUserData(),
						}),
					);
					const { handleTimeoutResponse } = this.props;
					handleTimeoutResponse({
						data: modifiedResponse,
					});
				}
			}
		});
		console.log('Creating socket connection.');
	}

	render() {
		const { children } = this.props;
		return children;
	}
}

const mapStateToProps = (state) => {
	return {
		uid: state.uid,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		handleTimeoutResponse: (response) => {
			const uid = get(response, 'data.response.other_params.uid', '');
			const currentStatus = get(
				response,
				'data.response.other_params.current_status',
				'',
			).toUpperCase();
			router(dispatch, uid, false, response);
			dispatch({
				type: `GET_${currentStatus}_SUCCESS`,
				payload: get(response, 'data.response', null),
				params: {},
			});
		},
		handleNewBuildAvailable: () => {
			dispatch({
				type: 'SET_NEW_BUILD_AVAILABLE',
				payload: {
					isNewBuildAvailable: true,
				},
			});
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(HealthCheck),
);
