import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'crux';
import styled from 'styled-components';
import TextElement from './index';
import { getNextQuestionSet } from '../../../redux/actions';
import ReviewBreadcrumb from '../../QuestionsLib/ReviewBreadcrumb';

const ButtonWrapper = styled.div`
  margin-right: 10%;
  margin-bottom: 30px;
  & > button {
    padding-right: 0;
  }
`;

const GroupQuestionTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GroupQuestionText = ({ questionObj, uid, makeAPICall, handlePopup }) => {
  const {
    question_id: questionId,
    question_text: questionText,
    question_type: questionType,
    question_description: questionDescription,
    isBreadcrumbCard,
    is_edit_icon_visible: isEditable,
  } = questionObj;

  if (!questionText && !questionDescription) {
    return null;
  }
  if (isBreadcrumbCard) {
    return <ReviewBreadcrumb question={ questionObj } />;
  }

  const displayUpdateButton = questionType === 'group' && isEditable;
  const handleUpdate = () => {
    const params = {
      uid,
      q_id: questionId,
    };
    makeAPICall(params);
  };

  if (!questionText && !questionDescription) {
    return null;
  }
  return (
    <>
      <GroupQuestionTextWrapper name='group-question-text-wrapper'>
        { questionText && <TextElement text={ questionText } styleType="questions_group" /> }
        {displayUpdateButton && (
          <ButtonWrapper name="button-wrapper">
            <Button label="Update" type="text" handleButtonClick={ handleUpdate } />
          </ButtonWrapper>
        )}
      </GroupQuestionTextWrapper>
      { questionDescription && <TextElement text={ questionDescription } styleType="normal" handlePopup={ handlePopup }/> }
    </>
  );
};

GroupQuestionText.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  questionObj: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    uid: state.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    makeAPICall: (params) => {
      dispatch(getNextQuestionSet('/questions', params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupQuestionText);
