export const mockAuthQuestions = {
  data: {
    code: 200,
    status: true,
    response: {
      other_params: {
        prev_button_state: 'questions',
        next_button_text: 'Login',
        prev_button_text: 'Back',
        is_prev_btn_hidden: true,
        is_next_btn_hidden: false,
        session_id: '',
        uid: '',
        type: 'plain',
        current_status: 'login',
      },
      breadcrumbs: [
        {
          breadcrumb_id: 'LoginPage',
          section_id: '21',
          title: 'Login Page',
          state: 'active',
          source_id: 1,
          img_url:
            'https://acqcdn.s3.amazonaws.com/allstate/breadcrumb/LandingPage.svg',
          show: false,
          clickable: false,
          section_progress: {
            total_sections: 1,
            current_section_number: 0,
          },
        },
      ],
      data: {
        questionnarie: {
          page_sequence_number: 1,
          questions: {
            question_id: 'LoginPage',
            question_type: 'group',
            display_type: 'breadcrumb',
            orientation: '',
            question_text: 'Login Page',
            index: 1,
            response: '',
            sequence_number: 1,
            is_reviewable: true,
            is_editable: true,
            is_edit_icon_visible: false,
            is_hidden: false,
            presentation_type: 'hide',
            validations: [],
            properties: [],
            response_options: [],
            parent_question_answer: [],
            question_description: '',
            child_questions: false,
            question_status: '',
            question_status_message: '',
            hint_text: '',
            sureify_meta: {
              source_id: 1,
            },
            questions: [
              {
                question_id: 'LoginPageGroup',
                question_type: 'group',
                display_type: 'questions_group',
                orientation: '',
                question_text: 'Login to Continue',
                index: 1.1,
                response: '',
                sequence_number: 1,
                is_reviewable: true,
                is_editable: true,
                is_edit_icon_visible: false,
                is_hidden: false,
                presentation_type: '',
                validations: [],
                properties: '',
                response_options: [],
                parent_question_answer: [],
                question_description: '',
                child_questions: false,
                question_status: '',
                question_status_message: '',
                hint_text: '',
                sureify_meta: {
                  source_id: 1,
                },
                questions: [
                  {
                    question_id: 'userName',
                    question_type: 'text',
                    display_type: 'text',
                    orientation: 'rows',
                    question_text: 'User Name',
                    sequence_number: 1,
                    is_reviewable: true,
                    is_editable: true,
                    is_edit_icon_visible: false,
                    is_hidden: false,
                    presentation_type: '',
                    properties: [],
                    response_options: [],
                    parent_question_answer: [],
                    question_description: '',
                    question_status: '',
                    question_status_message: '',
                    hint_text: '',
                    response: '',
                    validations: {
                      required: {
                        value: true,
                        error_message: 'This is a required field.',
                      },
                    },
                    sureify_meta: {
                      source_id: 1,
                    },
                    questions: [],
                  },
                  {
                    question_id: 'password',
                    question_type: 'password',
                    display_type: 'text',
                    orientation: 'rows',
                    question_text: 'Password',
                    response: '',
                    sequence_number: 1,
                    is_reviewable: true,
                    is_editable: true,
                    is_edit_icon_visible: false,
                    is_hidden: false,
                    presentation_type: '',
                    properties: [],
                    response_options: [],
                    parent_question_answer: [],
                    question_description: '',
                    question_status: '',
                    question_status_message: '',
                    hint_text: '',
                    validations: {
                      required: {
                        value: true,
                        error_message: 'This is a required field.',
                      },
                    },
                    sureify_meta: {
                      source_id: 1,
                    },
                    questions: [],
                  },
                ],
              },
            ],
            section_progress: {
              total_sections: 1,
              current_section_number: 0,
            },
          },
          completed: false,
        },
      },
      page_desc: {
        desc: null,
        title: null,
        heading: null,
      },
    },
  },
};

export const mockQuestions = [];
