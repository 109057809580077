import { get } from 'lodash';
import { SET_SMALL_SCREEN, SET_LIST_RELATION } from '../actions/meta';

const initialState = {
  isSmallScreen: window.innerWidth < 768,
  listRelation: {},
}

const getRelation = (question) => {
  const pId = get(question, 'properties.parent_list_id', '');
  const qId = question.question_id.split('_$ureify_')[1];
  return {
    [qId]: pId,
  }
}

const updateListRelation = (listRelation, payload) => {
  const { question, activeSubsectionId } = payload;
  if (listRelation[activeSubsectionId]) {
   return {
     ...listRelation,
     [activeSubsectionId]: {
       ...listRelation[activeSubsectionId],
       ...getRelation(question),
     },
   }
  }
  return {
    ...listRelation,
    [activeSubsectionId]: getRelation(question),
  }
}

const meta = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SMALL_SCREEN:
      return {
        ...state,
        isSmallScreen: payload,
      };
    case SET_LIST_RELATION:
      return {
        ...state,
        listRelation: updateListRelation(state.listRelation, action.payload),
      }
    default:
      return state;
  }
}

export default meta;
