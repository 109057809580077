import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';

const Wrapper = styled.div`
  padding: 40px 40px 30px 100px;
  width:100%;
  h1 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    color: ${({ color }) => color};
  }
  @media (min-width: 320px) and (max-width: 768px) {
    padding: 5px;
  }
`;

const BreadCrumbHeader = styled.h1 `
  @media (max-width: 640px) {
    font-size: 28px !important;
  }
  @media (max-width: 420px) {
    font-size: 22px !important;
  }
`

const ReviewBreadcrumb = ({
  question,
  theme,
}) => {

  const color = get(theme, 'config.theme.pageOverrides.review.headerColor', '#ffffff');

  const {
    question_text: questionText,
  } = question;
  return (
    <Wrapper name='wrapper' color={ color }>
      <BreadCrumbHeader name='breadcrumb-text'>
        {questionText}
      </BreadCrumbHeader>
  </Wrapper>
  )
};

ReviewBreadcrumb.propTypes = {
  question: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ReviewBreadcrumb);
