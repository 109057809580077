/* eslint-disable react/no-danger */
import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { Card } from 'crux';
import ScrollToTop from '../ScrollToTop';
import { validateInnerHtml } from '../utils';

const LoaderWrapper = styled.div`
	margin-top: 120px;
	margin-bottom: 40px;
	div {
		min-height: 560px;
	}
	img {
		max-width: 425px;
		margin: 0 auto;
		display: block;
		margin-top: -50px; // move it up a bit.
	}
	p {
		margin: 0;
		padding: 50px;
		font-size: 36px;
		font-weight: 400;
		color: rgba(71, 100, 126, 1);
		text-align: center;
	}
	@media only screen and (max-width: 768px) {
		img {
			max-width: 100%;
			height: auto;
		}
	}
`;

const Loader = (props) => {
	const { 
		theme : {
			config: {
				global: {
					loader,
				},
			},
		}, message,
	} = props;

	return (
		<ScrollToTop>
			<LoaderWrapper>
				<Card>
					<img src={ loader } alt="Please wait..." />
					<p>
						<span dangerouslySetInnerHTML={ { __html: validateInnerHtml(message) } } />
			  		</p>
				</Card>
			</LoaderWrapper>
		</ScrollToTop>
	);
};

const mapStateToProps = (state) => {
	const message = get(state, 'questions.loader.message', 'Just a moment...');

    return {
		message,
    }
};

export default withTheme(connect(mapStateToProps, {})(Loader));
