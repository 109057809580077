import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const CustomHeaderWrapper = styled.div`
  display: flex;
  padding: 8px;
  height: 128px;
  /* justify-content:space-around; */
  align-items: center;
  background: #00534c;
  & > div {
    margin: ${({ bannerInfo }) => bannerInfo ? '0 auto' : '0 0 0 48px'};
    display: flex;
  }
  [name='custom-logo'] {
    /* margin-left:220px; */
    height: auto;
    width: 128px;
    /* flex-grow:1; */
  }
`;
const Banner = styled.div`
  color: ${({ bannerStyles }) => bannerStyles.color};
  padding: 10px 0;
  margin: 0 0 0 30px;
  font-weight: ${({ bannerStyles }) => bannerStyles.fontWeight};
  line-height: 24px;
  font-size: 26px;
`;

const CustomHeader = ({
  bannerStyles,
  bannerInfo,
  background,
  branding,
  layout,
}) => {
  return (
    <CustomHeaderWrapper background={ background } bannerInfo={ bannerInfo }>
      <div>
        <div name="custom-logo">
          <a href={ branding.link } target="_blank" rel="noopener noreferrer">
            <img src={ layout === 'double' ? branding.darkLogo : branding.lightLogo } alt={ branding.altText }/>
          </a>
        </div>
        {bannerInfo && 
        <Banner bannerStyles={ bannerStyles }>
          <span dangerouslySetInnerHTML={ { __html: bannerInfo } } />
        </Banner>
        }
      </div>
    </CustomHeaderWrapper>
  );
};

const mapStateToProps = ({ questions }) => {
  return {
    bannerInfo: questions.bannerInfo,
  };
}

export default connect(mapStateToProps)(CustomHeader);