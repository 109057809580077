// eslint-disable react/jsx-curly-spacing
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FieldSet from '../FieldSet';
import Accordion from '../Accordion'
import { getListTitle } from '../../../redux/selector/listQuestion';

const Body = styled.div`
  background: rgba(255, 255, 255, 1);
  padding: 30px 40px;
  border-radius: 8px;
  margin-bottom: 20px;
  flex-direction: row;
  @media only screen and (max-width: 767px) {
    padding: 30px 25px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -10px;
`;

const Text = styled.span`
  text-align: left;
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: ${(props) => props.fontWeight || '300'};
  color: #424242;
	max-width: 80%;
	overflow-wrap: break-word;
`;

const ButtonContainer = styled.span`
  margin-top: 7px;
  margin-left: auto;
  float: right;
  @media only screen and (max-width: 767px) {
    margin-top: 10px;
  }
`;

const ButtonLink = styled.button`
  border: 0;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  &:focus {
    outline: none;
  }
`;

const EditLink = styled(ButtonLink)`
  color: ${({ disabled }) => disabled ? '#424242' : '#5482D5'};
  font-size: 14px;
  @media only screen and (max-width: 767px) {
    padding-left: 0px;
  }
`;

const DeleteLink = styled(ButtonLink)`
  color: rgba(255, 0, 0, 1);
  font-size: 14px;
`;

const DeleteCover = styled.div`
  position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(255,255,255, 0.8);
  border-radius: 8px;
  display: flex;
	flex-direction: column;
	justify-content: center;
  align-items: center;
  z-index: 1;
  @media (max-width: 767px){
    background: rgba(255, 255, 255, 1);
  }
`;

const Message = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #424242;
`;

const ViewMode = styled.div`
  display:${props=> props.isEditMode ? 'none': 'inherit'}
`;

const EditMode = styled.div`
  display:${props=> props.isEditMode ? 'inherit': 'none'}
`;

const beneficiaryProps = {
  fontSize: '24px',
  fontWeight: '300',
};

const titleProps = {
  fontSize: '14px',
  fontWeight: '400',
};

const CoverContent = styled.div`
  position: absolute;
  z-index: 5;
  width: 50%;
  margin-left: 20%;
  margin-top: 5%;
`;

const ConfirmButtons = styled.div`
  width: 50%;
  margin: auto;
`;

export default function ListItemCard ({
  listItem,
  sectionIndex,
  onEditClick,
  onDeleteClick,
  setEditIndex,
  len,
  index,
  title,
  changeMode,
  isEditMode,
  updateEditIndex,
  isReview,
  listParentId,
  enableUpdate,
  handleCancel,
  setReflexiveListIndex,
  hideEditButton,
}) {
  const [showDeleteCover, setDeleteCover] = useState(false);
  const hideEdit = hideEditButton !== undefined ? hideEditButton : !isReview;
  const customTitleProps = isReview || listParentId.match(/primarybeneficiarygroup/i) ? beneficiaryProps : titleProps;

  useEffect(() => {
    // If a list item is in edit mode and unmounted accedentally / due to page load
    // do the necessary cleanup
    return () => {
      if(isEditMode){
        changeMode(''); 
        //updateEditIndex(-1); updates editIndex with -1 when 'isEditMode' becomes true for index >= 0
        setReflexiveListIndex(-1, ''); 
        handleCancel();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode])

  const listTitle = title + getListTitle(listItem, isReview);
  return (
    <Body name='list-item-card'>
      {
        showDeleteCover &&
        <>
        <DeleteCover name='delete-cover'/>
        <CoverContent>
          <Message>Are you sure you want to delete this?</Message>
          <ConfirmButtons name='confirm-buttons'>
            <DeleteLink onClick={ onDeleteClick }>Yes</DeleteLink>
            <EditLink onClick={ () => setDeleteCover(false) }>No</EditLink>
          </ConfirmButtons>
        </CoverContent>
        </>
      }
      <ViewMode name='viewmode' isEditMode={ isEditMode }>
        <Header name='header'>
          <Text {...customTitleProps}>{ listTitle }</Text>
          { hideEdit && 
            <ButtonContainer>
              <EditLink onClick={ () => {
                 setEditIndex();
                 changeMode('listEdit');
                 updateEditIndex(index);
                 setReflexiveListIndex(-1, '');
              } }>
                Edit
              </EditLink>
              <DeleteLink onClick={ () => {
                updateEditIndex(-1)
                setDeleteCover(true) 
              } }>
                Delete
              </DeleteLink>
          </ButtonContainer>
          }
        </Header>
        { !listParentId.includes('Communication') && 
          <Accordion index={ index } len={ len } isReview={ isReview }>
            <FieldSet fields={ listItem } isReview={ isReview } index={ index }/>
          </Accordion> 
        }
      </ViewMode>
      <EditMode name='editmode' isEditMode={ isEditMode }>
        <FieldSet 
          sectionIndex={ sectionIndex } 
          isEditMode={ isEditMode } 
          fields={ listItem } 
          index={ index }
          listParentId={ listParentId } 
        />
        <ButtonContainer>
          <EditLink onClick={ () => { 
            changeMode(''); 
            onEditClick(); 
            setReflexiveListIndex(-1, ''); 
            } }
            disabled={ !enableUpdate }>
            Update
          </EditLink>
          <DeleteLink onClick={ () => { 
            changeMode(''); 
            updateEditIndex(-1); 
            setReflexiveListIndex(-1, ''); 
            handleCancel();
            } }>
            Cancel
          </DeleteLink>
        </ButtonContainer>
      </EditMode>
    </Body>
  )
}
