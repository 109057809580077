/* eslint-disable no-continue */
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import cloneDeep from 'lodash/cloneDeep';

const HELPER = {
	LABEL_QUESTION_DISPLAY_TYPES: [
		'list',
		'questions_group',
		'breadcrumb',
	],
	getRandomId: () => {
		return window.crypto.getRandomValues(new Uint32Array(1)).toString(36).substring(7);
	},
	extractAnswer: (answer, currentAnswer = []) => {
		if (typeof answer !== 'object') {
			return answer;
		}
		if (isPlainObject(answer) && answer.type === 'array') {
			if (answer.checked) {
				return [...currentAnswer, answer.id];
			}
			return currentAnswer.filter((a) => a !== answer.id);
		}
		if (typeof answer === 'object') {
			return answer;
		}
		return null;
	},
	/**
	 * @description - reset the answers array
	 * @param obj - contains reset values
	 * @param currentAnswer - contains the current answers array
	 */
	updateResetAnswer: (obj, currentAnswer) => {
		const { reset, answer } = obj;
		const isReset = reset.filter(item => item.id === answer.id)[0];
		if (answer.checked) {
			if (isReset) {
				return [answer.id];
			}
			if (currentAnswer.length) {
				return [
					...currentAnswer.filter(a => reset.filter(item => item.id !== a).length),
					answer.id,
				];
			}
			return [
				answer.id,
			]
		}
		return currentAnswer.filter(a => a !== answer.id);
	},

	/**
	 * @description - adds or replaces the list based on obj and key recieved
	 * @param list - array of items
	 * @param obj - object used to add/replace data
	 * @param key - string used to add/replace specific item
	 * **/
	addOrReplace: (list = [], obj, key) => {
		let matchFound = false;
		let newList = [];
		newList = list.map((item) => {
			if (item.question_id === obj.question_id) {
				matchFound = true;
				return {
					...item,
					question_id: obj.question_id,
					[key]: obj.reset ? HELPER.updateResetAnswer(obj, item.answer) : HELPER.extractAnswer(obj[key], item.answer),
					...(obj.parentId && {parentId: obj.parentId}),
					...(obj.immediateParent && {immediateParent: obj.immediateParent}),
					...(obj.parentListQuestionId && {parentListQuestionId: obj.parentListQuestionId}),
					...(obj.listParentId && {listParentId: obj.listParentId}),
					...((Number.isInteger(obj.listIndex) && obj.listIndex >= 0) && {listIndex: obj.listIndex})
				}
			}
			return item;
		});
		if (!matchFound) {
			newList.push(obj);
		}
		return newList;
	},

  /**
   * 
   */
  getRequiredSchema: (list) => {
    return list.reduce((acc, question) => {
      if (HELPER.LABEL_QUESTION_DISPLAY_TYPES.indexOf(question.display_type) > -1) {
        return acc;
      }
      if (get(question, 'validations.required.value', false) && !get(question, 'is_hidden', false)) {
        acc.push(question.question_id);
      }
      return acc;
    }, []);
  },

  /**
   * 
   */
  isDisplayTypeQuestion: (question) => {
    return question.question_type !== 'group';
  },

  /**
   * 
   */
  parseArray: (list, rex = 0)=> {
    const parentList = list;
    for (let index = 0; index < parentList.length; index+=1) {
		const question = parentList[index];
		if (question.submission_type === 'all') continue;
      	if (HELPER.isDisplayTypeQuestion(question) && question.questions && question.questions.length) {
        	if (question.child_questions) {
          		const rIndex = question.reflexive_index === undefined ? 0 : question.reflexive_index
          		parentList.splice(index + 1, 0, ...question.questions.map(q => ({ ...q, reflexive_index: rIndex + 1 })))
        	} else {
          		parentList.splice(index + 1, 0, ...question.questions);
        	}
        	question.questions.length = 0;
      	}
      	if (question.questions && question.questions.length) {
        	HELPER.parseArray(question.questions, question.reflexive_index);
      	}
      	question.reflexive_index = question.reflexive_index || rex;
    }
  },

  /**
   * @description function to extract and insert reflexive questions in the question list.
   * @param {Object} questionObject - the parent (group) question after the breadcrumb.
   */
  insertReflexiveQuestion: (questionObject) => {
    const questionList = [cloneDeep(questionObject)];
    HELPER.parseArray(questionList)
    const temp = []
    HELPER.flattenQuestions(questionList, temp, 0);
    return temp
  },

	/**
	 * @description funtion to flatten the questions.
	 * @param questions question list - array
	 * @param newList array
	 * @param shouldFlatListQ boolean
	 */
	flattenQuestions: (questions, newList, shouldFlatListQ = false) => {
		if (questions.length) {
			questions.forEach((question) => {
				newList.push({...question});
				if (question.questions && question.submission_type !== 'all' && (shouldFlatListQ || question.display_type !== 'list')) {
					HELPER.flattenQuestions(question.questions, newList, shouldFlatListQ);
				}
			});
		}
	},
	/**
	 * @description functione extracts the current breadcrumb name and transforms it
	 * to a keyword.
	 * @param {Object} data received from the backend
	 * @returns {String}
	 */
	getCurrentBreadcrumb: (breadcrumbs, id) => {
		const breadcrumb = breadcrumbs.filter((item) => {
			if (id && item.state === 'active') {
				return item.title.replace(/ /g, '').toLowerCase() === id;
			}
			return item.state === 'active';
		})[0];
		if (breadcrumb) {
			return {
				id: breadcrumb.breadcrumb_id.toLowerCase() || breadcrumb.title.replace(/ /g, '').toLowerCase(),
				index: breadcrumbs.findIndex((obj) => obj.breadcrumb_id === breadcrumb.breadcrumb_id),
				active: true,
				...breadcrumb,
			};
		}
		return null;
	},

  /**
   * 
   */
  shouldEnableContinue: (requiredSchema, allAnswers, allErrors = [], isList=false, listDisplay=false, listError=false, eSign) => {
    let enable = true;
    if (allErrors.length !== 0) { return false }
	if(listError) { return false }

	for (let index = 0; index < requiredSchema.length; index += 1) {
		const answeredQuestion = allAnswers.filter(
			(item) =>
				item.question_id === requiredSchema[index] && (item.answer || item.answer === 0),
		);
		if (!answeredQuestion.length) {
			enable = false;
			break;
		}
		
		const { answer } = answeredQuestion[0];
		if(isArray(answer) && !answer.length){
			enable = false;
			break;
		}
	}

    if(isList && !listDisplay){
      enable = false
    }
	
	if(eSign) {
		const { hasESign, eSignEnable } = eSign;
		if (hasESign) {
			enable = enable && eSignEnable;
		}
	}
	
    return enable;
  },

  isValidAnswer: (answer) => {
		if (typeof answer === 'string' || typeof answer === 'number') {
			return !!answer;
		}
		if (isArray(answer) && answer.length) {
			return true;
		}
		if (isPlainObject(answer) && answer.id) {
			return true;
		}
		return false;
	},

	findAndUpdateResponse: (
		questionList,
		questionId,
		response,
		questionResponse,
		isControlledInput = false,
	) => {
		return questionList.map((question) => {
			if (question.question_id === questionId) {
				return {
					...question,
					isControlledInput,
					response: HELPER.isValidAnswer(questionResponse)
						? questionResponse
						: HELPER.extractAnswer(response, question.response),
				};
			}
			return question;
		});
	},
    // sort 2 JSON arrays by prop value
	getSortOrder(prop) {    
		return (a, b) => {    
			if (a[prop] > b[prop]) {    
				return 1;    
			} if (a[prop] < b[prop]) {    
				return -1;    
			}    
			return 0;    
		}    
	},  
	
	// returns the answers array of matching id
	getAnswersArray(answersArray, questionId ){
	   return answersArray.filter(question => question.question_id === questionId);
	},

  removeDuplicate: (sectionAllQuestions, currentQuestions) => {
    // check for two layout property for question
	const layout = get(currentQuestions, 'properties.layout', undefined);
	let currentQuestionId;
	if(!currentQuestions.questions) {
		currentQuestionId = currentQuestions.questionId;
	} else {
		currentQuestionId = layout ? currentQuestions.questions[1].question_id :
		currentQuestions.questions[0].question_id; // get the questions_groups question id
	}
    return sectionAllQuestions.reduce((acc, questions) => {
      // check index 2 for first question in the list and for index 1 fro two-column question
      if (questions.questionList[1].question_id === currentQuestionId ||
         (layout && questions.questionList[0].question_id === currentQuestionId)) {
        return acc;
      }
      acc.push({
        ...questions,
        completed: true,
      });
      return acc;
    }, [])
  },

  replaceSectionQuestion: (oldQuestions, newQuestionsList, index) => {
    return oldQuestions.map((quesObj, i) => {
      if (i === index) {
       const requiredSchema = HELPER.getRequiredSchema(newQuestionsList)
        return {
          ...quesObj,
          questionList: newQuestionsList,
          requiredSchema,
          enableContinue: HELPER.shouldEnableContinue(requiredSchema, quesObj.answersArray),
        }
      }
      return quesObj;
    })
  },

  	buildAnswersArrayReduceFunc: (Q, arr) => {
		return Q.reduce((acc, q) => {
			if (
				q.display_type === 'list' ||
				q.display_type === 'questions_group' ||
				q.display_type === 'breadcrumb'
			) {
				return acc;
			}
			if (q.question_id) {
				let { response } = q;
				if (isPlainObject(response)) {
					response = response.id;
				}
				if (isArray(response)) {
					response = response.map((r) => r.id);
				}
				acc.push({
					question_id: q.question_id,
					answer: response,
				});
			}
			return acc;
		}, arr);
	},

	buildAnswersArray: (questionList, originalQ = []) => {
		const answersArray = []
		HELPER.buildAnswersArrayReduceFunc(questionList, answersArray);
		HELPER.buildAnswersArrayReduceFunc(originalQ, answersArray);
		return answersArray
	},

  	getOriginalQuestions: (questionObject) => {
		const listQuestion = questionObject.questions[0];
		if (listQuestion.display_type === 'list') {
			// Adds reflexive questions support for list questions
			// Related Task - https://sureify.atlassian.net/browse/AS-323
			const reflexiveListQ = []
			HELPER.flattenQuestions(listQuestion.original_questions || listQuestion.questions, reflexiveListQ, true)
			return reflexiveListQ
		}
		return [];
	},
	
	getAnsweredLisQuestionIds: (questionObj) => {
		return questionObj
		.filter(question => question.display_type === 'list' && question.child_questions_completed_flag)
		.map(q => q.question_id)
	},
}

export const { getCurrentBreadcrumb } = HELPER;

export default HELPER;


