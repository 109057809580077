import React from 'react';

const Link = ({ data }) => (
	<a className='social-media-link' href={ data.linkUrl } title={ data.linkFor } rel="noopener noreferrer" target="_blank">
		<i className={ `fa ${data.icon}` } />
	</a>
);

export default function SocialLinks({ links }) {
	return (
		<div className="social-media-details">
			{ links.map(link => <Link key={ link.id } href={ `${link}` } data={ link } />) }
		</div>
	);
}