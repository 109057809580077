import get from 'lodash/get';
import { SET_CSRF_TOKEN, API_IN_PROGRESS, GET_QUESTION_FAILURE } from '../types';
import { getRefreshToken } from './authHandler';
import axios from 'axios';
import host from '../../util/host';

export const axiosInstance = axios.create(axiosConfig);
const { REACT_APP_BASE_URL } = host();


axiosInstance.interceptors.request.use((request) => {
  // set access_token if present
  request.headers.Authorization = 'Basic YWRtaW46NyVkUkdyZVQ=';
  return request;
});
const axiosConfig = {
    baseURL: REACT_APP_BASE_URL,
    method: 'post',
    timeout: 1000 * 60 * 60, // timeouts, if API is not resolved in 10 seconds
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
      Accept: 'application/json',
    },
  };

const setCSRFTokenValue = (error, dispatch) => {
    let csrfToken = "";
    if(error.response){
        if(error.response.data) csrfToken = error.response.data.csrfToken;
        else csrfToken = error.response.csrfToken;
    }
    else csrfToken = error.csrfToken;
    dispatch({
        type: SET_CSRF_TOKEN,
        csrfToken: csrfToken
    });
}

const deathClaimActions = (formdata, submitted, setSubmitted, setResponse) => {
    const env =  process.env.REACT_APP_NODE_ENV;
    let url = '';
    if (env === 'local') {
        url = 'http://localhost/v1/deathclaim'
    } else {
        url = `${window.location.origin}/v1/deathclaim`
    }
    return (dispatch) => {
        dispatch({
			type: API_IN_PROGRESS,
		});
        axiosInstance.post(url, formdata)
            .then(res => {
                dispatch({
                    type: SET_CSRF_TOKEN,
                    csrfToken: res.data.csrfToken
                });
                setSubmitted(!submitted)
                setResponse(res.data.response)
                dispatch({
                    type: GET_QUESTION_FAILURE,
                });
            })
            .catch(async (error) => {
                setSubmitted(false)
                if(error && error.response.request.status === 401){
                    setSubmitted(false)
                }
                // setResponse(error)
                setCSRFTokenValue(error,dispatch)
                const refreshRetry = get(error, 'response.config._retry', false);
                if (refreshRetry) {
                    const accessToken = await dispatch(getRefreshToken());
                    if (accessToken) {
                        axiosInstance
                            .post(url, formdata)
                            .then((res) => {
                                dispatch({
                                    type: SET_CSRF_TOKEN,
                                    csrfToken: res.data.csrfToken
                                });
                                setResponse(res.data.response)
                                setSubmitted(!submitted)
                            })
                            .catch(err => {
                                setSubmitted(false)
                                setCSRFTokenValue(error,dispatch)
                                if(error && error.response.data.code === 401){
                                    setSubmitted(false)
                                }
                                // setResponse(err.message)
                            })
                    }
                }
                dispatch({
                    type: GET_QUESTION_FAILURE,
                })
            })
    }
}

export default deathClaimActions;