import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { axiosInstance } from './axios';
import Main from '../Main';
import configureStore from '../redux/configureStore';
import { SET_CSRF_TOKEN } from '../redux/types';
import { getQueryParamValue } from '../util';

export const store = configureStore();
let carrierConfig;
let ssoAuthConfig;

/**
 * @description This method renders the Main component on to ReactDOM 
 * @param config - contains the carrier config
**/

const renderMainComponent = (config, ssoAuthConfig) => {
	ReactDOM.render(
		<BrowserRouter>
			<Main config={ config } store = {store} ssoAuthConfig={ssoAuthConfig} />
		</BrowserRouter>,
		document.getElementById('root'),
	);
}

/**
 * @description This method calls Carrier config api and renders Main component on 
 * successful api call
 */
const callConfigApi = () => {
	const pathname = window.location.pathname;
	const uid = getQueryParamValue(window.location.search, 'uid');
	const challenge_id = getQueryParamValue(window.location.search, 'challenge_id');
	let pathQuery = ''
	if(pathname !== '/'){
		if(uid){
			pathQuery = `&uid=${uid}`
		}
		else if(pathname.includes('/deathclaim')){
			pathQuery = `&uid=1234`
		}
	}
	else if(challenge_id){
		pathQuery = `&challenge_id=${challenge_id}`
	}
	axiosInstance
	.get(`/config?carrier=${process.env.REACT_APP_CARRIER_ID}${pathQuery}`)
	.then((response) => {
		if (response.data.success) {
			carrierConfig = response.data.config;
			ssoAuthConfig = response.data.ssoAuthConfig;
			renderMainComponent(response.data.config, response.data.ssoAuthConfig);
		} else {
			throw response.data;
		}
	})
	.catch((err) => {
		renderPageNotFoundComponent(err);
	});
}

/**
 * @description This method will validate the existence of accesstoken and
 * carrier config data. If validation results true then it will render Main component
 */
const validateConfigAndRenderMainComponent = () => {
	if(!carrierConfig || !ssoAuthConfig){
		callConfigApi(store);
	}
	else{
		renderMainComponent({...carrierConfig}, {...ssoAuthConfig});
	}
}

/**
 * @description - This method is used to show Page Not Found response when ever there is an error
 * @param err - contains error object
 */
const renderPageNotFoundComponent = (err) => {
	window.location.href = '/page-not-found.html';
	// eslint-disable-next-line no-console
	console.error('App could not load');
	// eslint-disable-next-line no-console
	console.trace(err);
}

/**
 * @description This method checks for validity of accesstoken and csrf_token
 * if both the tokens are found valid then it fetches carrier config and invokes Main component
 * else it will try to generate new accesstoken and csrf_token
 **/

export const showLandingPage = () => {

	if(localStorage.getItem('CSRF_TOKEN')){
		localStorage.removeItem('CSRF_TOKEN');
	}

	const { dispatch } = store;
	if(store.getState().questions.csrfToken === "<default-token>"){
		axiosInstance
		.get('/getCSRFToken')
		.then((response) => {
			dispatch({
				type: SET_CSRF_TOKEN,
				csrfToken: response.data.csrfToken
			});
			validateConfigAndRenderMainComponent();
		})
		.catch((err) => {
			renderPageNotFoundComponent(err);
		});
	}
	else{
		validateConfigAndRenderMainComponent();
	}
}