import React from 'react';
import './primary.css';

const ButtonPrimary = ({ label, btnType, disabled, onClick, className, style }) => {
  switch (btnType) {
    case 'pill': {
      return (
        <button
          type="submit"
          className={ `btnPill ${disabled && 'disabled'}` }
          tabIndex={ disabled ? '-1' : '' }
        >
          {label}
        </button>
      );
    }

    case 'rounded': {
      return (
        <button
          type="submit"
          style={ style || {} }
          tabIndex={ disabled ? '-1' : '' }
          className={ `btnPill btnRound ${disabled && 'disabled'} ${className} ` }
          onClick={ onClick }
        >
          {label}
        </button>
      );
    }

    case 'link': {
      return (
        <button
          type="submit"
          className={ `btnLinkPrimary ${className || ''}` }
          tabIndex={ disabled ? '-1' : '' }
          onClick={ onClick }
        >
          {label}
        </button>
      );
    }

    default: {
      return null;
    }
  }
};

export default ButtonPrimary;
