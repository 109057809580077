/* eslint-disable comma-dangle */
import { SET_DISPLAY_AS_MOBILE } from '../types';

const INITIAL_STATE = {
  isSmallScreen: false,
  config: {}
};


export default function utilReducer(state = INITIAL_STATE, action) {

  const { type,payload } = action;
  switch (type) {

    case SET_DISPLAY_AS_MOBILE:
      return {
        ...state,
        isSmallScreen: payload
      };
    case 'SET_CONFIG':
      return {
        ...state,
        config: action.payload
      };
    default:
      return { ...state };
  }
}