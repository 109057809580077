/* eslint-disable no-unused-vars */
import React from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';

// Three card sizes are defined in current designs
// S / M / L Card Sizes
export const Size = {
  S: 'Small',
  M: 'Medium',
  L: 'Large',
  B: 'Banner',
};

// Card behaviour is distinguished in three states
export const State = {
  INCOMPLETE: 'incomplete',
  ACTIVE: 'active',
  COMPLETED: 'completed',
};

const ProgressLabel = styled.p`
  font-size: ${props => (props.size === Size.S ? '12px' : '14px')};
  color: ${props =>
    props.isActive ? 'rgba(41, 101, 204, 1)' : 'rgba(93, 93, 93, 1)'};
  margin-left: 10px;
  margin-bottom: 10px;
`;

const cardStyles = css`
  position: relative;
  ${({ isActive, size }) =>
    (isActive || size === Size.B) &&
    `
    cursor: pointer;
  `};
  ${({ isActive, size }) =>
    isActive &&
    size !== Size.B &&
    `
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.2);
  `};
  ${({ isActive, size }) =>
    isActive &&
    size !== Size.L &&
    size !== Size.B &&
    `
    border: 2px solid #98ACE6;
    background: rgba(255, 255, 255,1);
  `};
  ${({ isActive, size }) =>
    !isActive &&
    size !== Size.B &&
    `
    display: -ms-grid !important;
  `};
`;

const BreadCrumbCard = styled.div`
  ${cardStyles};
  ${props => props.theme};
`;

const LeftSection = styled.div`
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    font-size: ${props => props.size === Size.L && '30px'};
    margin: ${props => props.size === Size.L && '10px 0 10px 15px'};
    flex-direction: column;
    padding: 10px;
    margin: auto 0;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-size: ${props => props.size === Size.L && '30px'};
    margin: ${props => props.size === Size.L && '10px 0 10px 15px'};
    flex-direction: column;
    margin: auto 0;
  }
`;

const LabelWrapper = styled.div`
  padding: 42px;
  ${({ size }) =>
    size !== Size.L &&
    size !== Size.B &&
    `
    padding: 14px;
    padding-left: 20px;
    position: relative;
    @media only screen and (min-width:320px) and (max-width:480px) {
      padding:0;
    }
  `}
  @media only screen and (min-width:1024px) and (max-width:1280px) {
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
  }
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    padding: 0;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 0;
  }
`;

const Title = styled.p`
  && {
    /* font-weight: bold; */
    font-size: ${props => (props.size === Size.M ? '18px' : '18px')};
    font-size: ${props => props.size === Size.L && '48px'};
    font-size: ${props => props.size === Size.B && '40px'};
    color: ${props =>
      props.isActive || props.size === Size.B
        ? 'rgba(59, 83, 105, 1)'
        : 'rgba(0, 0, 0, 1)'};
    margin: 0 !important;
    line-height: 1;
    position: relative;
    ${({ size }) =>
      size !== Size.L &&
      `
      height: 45px;
      max-height: 45px;
    `}
    @media only screen and (min-width:1024px) and (max-width:1280px) {
      && {
        /* font-weight: bold; */
        font-size: ${props => (props.size === Size.M ? '18px' : '18px')};
        font-size: ${props => props.size === Size.L && '48px'};
        font-size: ${props => props.size === Size.B && '40px'};
        color: ${props =>
          props.isActive ? 'rgba(59, 83, 105, 1)' : 'rgba(0, 0, 0, 1)'};
        margin: 0 !important;
        line-height: 1;
        position: relative;
        ${({ size }) =>
          size !== Size.L &&
          `
          height: 45px;
          max-height: 45px;
        `}
      }
    }
    @media only screen and (min-width: 787px) and (max-width: 1024px) {
      && {
        /* font-weight: bold; */
        font-size: ${props => (props.size === Size.M ? '18px' : '18px')};
        font-size: ${props => props.size === Size.L && '48px'};
        font-size: ${props => props.size === Size.B && '40px'};
        color: ${props =>
          props.isActive ? 'rgba(59, 83, 105, 1)' : 'rgba(0, 0, 0, 1)'};
        margin: 0 !important;
        line-height: 1;
        position: relative;
        ${({ size }) =>
          size !== Size.L &&
          `
          height: 36px;
          max-height: 36px;
        `}
      }
    }
    @media only screen and (min-width: 481px) and (max-width: 767px) {
      font-size: ${props => props.size === Size.L && '30px'};
      font-size: ${props => props.size === Size.B && '24px'};
      max-width: 60%;
    }
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      font-size: ${props => props.size === Size.L && '30px'};
      font-size: ${props => props.size === Size.B && '24px'};
      max-width: 70%;
      ${({ size }) =>
          size === Size.B &&
          `
          height: auto;
          max-height: unset;
        `}
    }
  }
`;

const TitleWrapper = styled.span`
  ${({ size }) =>
    size !== Size.L &&
    size !== Size.B &&
    `
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (min-width: 320px) and (max-width: 480px) {
      padding: 10px;
    }
    @media (min-width: 768px) and (max-width: 1120px) {
      text-overflow: normal;
      white-space: normal;
    }
  `}
`;

const Footer = styled.span`
  font-size: 16px;
  color: rgba(93, 93, 93, 1);
`;

const Line = styled.hr`
  border: 1px solid rgba(216, 216, 216, 1);
  width: 80px;
  margin: 10px 0;
`;

const DisabledDiv = styled.div`
  cursor: ${props =>
    props.state === State.INCOMPLETE ? 'not-allowed' : 'default'};
  height: 100%;
  width: 100%;
  background: rgba(179, 179, 179, 1);
  border-radius: 8px;
  position: absolute;
`;

const CompletedDiv = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
  border: 2px solid rgba(176, 176, 176, .4);
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  position: absolute;
`;

const Progess = styled.span`
  color: #2965cc;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
  }
`;

const Image = styled.img`
  position: absolute;
  height: 100%;
  right: 0;
  z-index: 99;       // to display "inactive" image as per the configured image.
  @media (max-width: 320px) {
    width: 80px;
  }
`;

const CardWrapper = styled.div`
  name: ${props => props.name};
  width: ${({ override }) => override ? '100%' : '80%'};
  margin: ${({ override }) => override ? '5px 0' : '0'};
  z-index: 95;
`;

const showSectionProgressLabel = (totalSections, currentSectionNumber) => {
  if (typeof totalSections !== 'number' || typeof currentSectionNumber !== 'number') {
    return false;
  }
  if (typeof totalSections === 'number' && totalSections < 0) {
    return false;
  }
  if (typeof currentSectionNumber === 'number' && currentSectionNumber < 0) {
    return false;
  }
  return true;
}

const Breadcrumb = props => {
  const { breadcrumb, imgUrl, isActive, isComplete, theme, size, onClick, override } = props;

  const commonProps = {
    breadcrumb,
    onClick,
    isActive,
    isComplete,
    imgUrl,
    theme,
    size,
  };
  const {
    total_sections: totalSections,
    current_section_number: currentSectionNumber,
  } = get(breadcrumb, 'section_progress', 0);

  return (
    <CardWrapper name='card-wrapper' override={ override }>
      <BreadCrumbCard
        name="breadcrumb-card"
        size={ size }
        theme={ theme }
        { ...commonProps }
        onClick={ () => breadcrumb.clickable && onClick(breadcrumb.id) }
      >
        <LeftSection name="left-section" size={ size }>
          {(size === Size.S && isActive) && (
            <Progess name="progress">
              {
                showSectionProgressLabel(totalSections, currentSectionNumber)
                ? `${currentSectionNumber} of ${totalSections} Sections completed`
                : <p />
              }
            </Progess>
          )}
          <LabelWrapper name="label" size={ size }>
            <Title name="title" size={ size } isActive={ isActive }>
              <TitleWrapper name="title-wrapper" size={ size }>{breadcrumb.title}</TitleWrapper>
            </Title>
            {size === Size.L && (
              <Footer name="footer">
                <Line name="line" />
                {
                  showSectionProgressLabel(totalSections, currentSectionNumber)
                  ? `${currentSectionNumber} of ${totalSections} Sections completed`
                  : <p />
                }
              </Footer>
            )}
          </LabelWrapper>
        </LeftSection>
        {!isActive && !isComplete && size !== Size.L && size !== Size.B && <DisabledDiv name="disabled-div" state={ breadcrumb.state } /> }
        {!isActive && isComplete && size !== Size.L && size !== Size.B && <CompletedDiv name="completed-div" state={ breadcrumb.state } /> }
      </BreadCrumbCard>
      {!override &&
        <ProgressLabel name='progress-label' isActive={ isActive } size={ size }>
        {
          showSectionProgressLabel(totalSections, currentSectionNumber)
          ? `${currentSectionNumber} of ${totalSections} Sections completed`
          : <p />
        }
        </ProgressLabel>
      }
    </CardWrapper>
  );
};

export default Breadcrumb;
