import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal as CruxModal, Button } from 'crux';
import { get/* , isEmpty */ } from 'lodash';
import { withTheme } from 'styled-components';
import { submitSectionQuestion, updateAnswersArray } from '../../../redux/actions/questions';
import { getLoaders } from '../../../redux/selector/validations';
import { ButtonWrapper, Message } from './ModalStyles'

const Modal = (props) => {
  const { questionObj, handleModalButtonClick, isSmallScreen /* isLoading */ } = props;
  const [response/* , setResponse */] = useState('');
  const [selectedId/* , setSelectedId */] = useState('');
  
  // eslint-disable-next-line no-unused-vars
  const [error,setError] = useState(false);
  const {
    question_text: questionText,
    question_description: QuestionDesc,
    message,
  } = questionObj;
  
  const handleClick = () => {
    if(response === '') {
      setError(false);
      handleModalButtonClick('', true, selectedId);
    } else {
      setError(false);
      handleModalButtonClick(response, true, selectedId);
    }
  };

  const colorOverride = get(props, 'theme.config.theme.global.colorOverride', null );
  const themeConfiguration = colorOverride ? {
    selectedBgColor: colorOverride.selectedBgColor,
    bgColor: '#ffffff',
    border: `1px solid ${colorOverride.selectedBgColor}`,
    fontColor: '#333333',
    borderRadius: '22px',
  } : null;

  return (
    <div style={ { marginLeft: '20%' } }>
      <CruxModal 
        modalHeader={ questionText }
        modalBody={ QuestionDesc }
        alignContent="left"
        showModalClose={ false }
        disableOverlayClick
        overLaytop="0"
        overlayLeft="0"
        overlayRight="0"
        width={ isSmallScreen ? 'L' : 'M' }
        height="auto"
        overlayBgColor = 'rgb(52 58 64 / 30%)'
        margin = { isSmallScreen ? '35% auto' : '7% auto' }
        bodyFontSize='14px'
        bodyPadding='0px' // In Mobile devices
      >
        <Message dangerouslySetInnerHTML={ { __html: message } } />
        <ButtonWrapper name='button-wrapper'>
          <Button
              name='confirm-button'
              label="Close"
              type="filled"
              shape="rounded"
              handleButtonClick={ (e) => {
                e.preventDefault();
                handleClick();
              } }
              width="50%"
              disabled={ false }
              color="blue"
              themeConfiguration={ themeConfiguration }
              alignment="center"
            />
        </ButtonWrapper>
       </CruxModal>
    </div>
  );
};

function mapStateToProps(state) {
  const { isLoading } = getLoaders(state);
  const { isSmallScreen } = state.meta
  return {
    isLoading,
    isSmallScreen,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleModalButtonClick: (response, preventReflexiveCall, id) => {
      const questionId = get(ownProps, 'questionObj.question_id', '');
      const isHidden = !get(ownProps, 'questionObj.is_hidden', false);
      const sendIndividualQuestions = get(ownProps, 'questionObj.send_individual_questions', false)
      if(response === '') {
          dispatch(
            updateAnswersArray({
              questionId,
              response: '',
              sectionIndex: ownProps.sectionIndex,
              preventReflexiveCall,
              isControlledInput: true,
              isHidden,
              modal_complete: true,
            }),
          );
        }
      if(sendIndividualQuestions) {
        Object.values(response).forEach(res => {
          dispatch(
            updateAnswersArray({
              questionId: res.question_id,
              response: res.value,
              sectionIndex: ownProps.sectionIndex,
              preventReflexiveCall,
              isControlledInput: true,
              isHidden: false,
            }),
          );
        })
      }
      dispatch(
        updateAnswersArray({
          questionId,
          response: id,
          sectionIndex: ownProps.sectionIndex,
          preventReflexiveCall,
          isHidden,
        }),
      );
      dispatch(submitSectionQuestion(ownProps.sectionIndex));
    },
  };
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Modal));
