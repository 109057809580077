import { Button } from 'crux';
import get from 'lodash/get';
import React from 'react';
import { FooterBannerWrapper,Info,ImgWrapper } from './styles'

const FooterBanner = ({ footerBanner, primaryRoute = '/employee', theme }) => {
    const { 
        info_text: infoText,
        button_text: btnText,
        image_url: imgUrl 
    } = footerBanner;
    const btnTheme = get(theme, 'config.theme.global.footerBanner.button', null);

    const handleButtonClick = () => {
        window.location.href = `${window.location.origin}${primaryRoute}`;
    }

    return (
        <FooterBannerWrapper>
            <Info>{infoText}</Info>
            <Button label={ btnText } themeConfiguration={ btnTheme } handleButtonClick={ handleButtonClick } />
            <ImgWrapper>
                <img src={ imgUrl } alt="footer-banner" />
            </ImgWrapper>
        </FooterBannerWrapper>
    )

}

export default FooterBanner