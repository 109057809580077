/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';

const Scroller = ({
	sectionIndex,
	pageId,
	listDisplay,
	isList,
	skipAutoScroll,
	isUpdateMode,
	collapse,
}) => {
	const ref = useRef(null);
	
	useEffect(() => {

		if (skipAutoScroll) {
			return;
		}
		if (sectionIndex === 0 && isList && listDisplay) {
			return;
		}
		if (sectionIndex === 0) {
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 100);
			return;
		}
		setTimeout(() => {
			const el = ref.current;
			if(isUpdateMode) {
				if(collapse) {
				  return;
				}
			}
			if (!el ) {
				return;
			}
			el.scrollIntoView({ behavior: 'smooth' });
		}, 100);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sectionIndex]);
	if (pageId === 'quote') {
		return null;
	}
	return (
		<div style={ { position: 'relative', top: '-200px' } } ref={ ref } />
	);
};

const ScrollToSection = React.memo(Scroller);

ScrollToSection.propTypes = {
	sectionIndex: PropTypes.number.isRequired,
	pageId: PropTypes.string.isRequired,
	listDisplay: PropTypes.bool.isRequired,
	isList: PropTypes.bool.isRequired,
	skipAutoScroll: PropTypes.bool.isRequired,
};

export default ScrollToSection;
