import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { withTheme, ThemeProvider } from 'styled-components';
import Header from './header';
import Footer from '../Components/Footer/footer';
import Toast from '../cruxComponents/Toast';
import AppRouter from '../AppRouter';

const Body = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	${({ background }) => `
		background: ${background}
	`}
`;

const Layout = ({ 
	theme, 
	loading, 
	currentPageSeqNumber, 
	isSmallScreen, 
	actionMessage, 
	activeSubsectionId,
	questionsLayout,
	custCareTel,
	policyExpiry,
	agentParams,
	questions,
}) => {
	const {
		config: {
			theme: { global },
			footer,
			layout,
			customHeader,
		},
	} = theme;
	const { otherParams : { role } } = questions;

	const pageId = window.location.pathname.split('/')[1] || '';
	let pageLayout = pageId ? layout[pageId] : 'auto';
	if (loading && currentPageSeqNumber < 1) {
		pageLayout = 'single';
	}
	if (!layout[pageId]) {
		pageLayout = 'auto';
	}
	if (questionsLayout) {
		pageLayout = questionsLayout;
	}

	// Adds toast message only if action message is of type success
	const hasSuccessActionMessage = actionMessage && actionMessage.type === 'success';

	return (
		<ThemeProvider theme={ { ...theme, pageId, pageLayout, isSmallScreen, activeSubsectionId } }>
			{hasSuccessActionMessage && <Toast type='success' message={ actionMessage.message } /> }
			<Body background={ global.background }>
				{
					pageId === 'login' ? <AppRouter /> : 
				 	<>
				 		<Header 
							name='header'
							layout={ pageLayout } 
							background={ global.headerBgColor }
							height={ global.headerHeight } 
							isSmallScreen={ isSmallScreen } 
							loading={ loading }
							customHeader={  customHeader }	
							agentParams={ agentParams }	
							role={ role }			
						/>
						<AppRouter />
						<Footer
							footer={ { ...footer, background: global.footerBackground, color: global.footerTextColor } }
							custCareTel={ custCareTel } 
							policyExpiry={ policyExpiry }
							agentParams= { agentParams }
						/>
					</>
				}
			</Body>
		</ThemeProvider>
	);
};

Layout.propTypes = {
	theme: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ questions, meta, agentParams }) => ({
	loading: questions.isLoading,
	actionMessage: questions.actionMessage,
	currentPageSeqNumber: questions.currentPageSeqNumber,
	isSmallScreen: meta.isSmallScreen,
	activeSubsectionId: questions.activeSubsectionId,
	questionsLayout: questions.layout,
	custCareTel:questions.custCareTel,
	policyExpiry:questions.policyExpiry,
	agentParams,
	questions,
});

export default withTheme(connect(mapStateToProps, null)(Layout));
