import { SET_AGENT_PARAMETERS } from '../types';

const initialState = {
  agentName:'',
  agentCode:'',
  agentPhone:'',
  alanNumber:'',
}


function agentParams(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_AGENT_PARAMETERS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export default agentParams;
