import queryString from 'query-string';
import { 
	getNextQuestionSet, 
	getAuthQuestionSet, 
	setDestinationURL, 
	triggerAuthAction,
	setClubCode
} from './redux/actions';
import { SET_LOGIN_CONFIG, SET_SSO_LOGIN_CONFIG } from './redux/types'
import { getUid, getSessionId, getParams,getClubCode } from './util';
import LocalStorageServices from './util/localStorageServices';
import router from './util/router';
import { mockAuthQuestions } from './mockResponse';
import ssoAuth  from './ssoAuth';

export default function initApp(store, config, ssoAuthConfig ) {
	const { getState, dispatch } = store;
	const { uid,clubCode: storeClubCode } = getState();
	const urlUid = getUid(window.location.search);
	const clubCode = getClubCode(window.location.href) || storeClubCode;
	const sessionId = getSessionId(window.location.search);
	const event = getParams('event');
	const envelopId = getParams('envelop_id')
	const documentType = getParams('document_type');

	dispatch(setClubCode(clubCode))
	/**
	 * @description Checks if login is enabled as per carrier's config.
	 */
	let loginRedirection = false;
	const { features : { login, ssoLogin } } = config;
	
	/**
	 * @description Sets login related configs in the reducer.
	 */
	if (login) {
		dispatch({
			type: SET_LOGIN_CONFIG,
			payload: login,
		})
	}

	const accessToken = LocalStorageServices.getAccessToken();

	if (login.enable) {
		if (!accessToken) loginRedirection = true;
	}

	/**
	 * @description Stores the URL requested by the user.
	 */
	const { pathname, search } = window.location;
	const URL = pathname.concat(search);

	// Skip /questions API call if route contains document_type as url param
	if (documentType) {
		return;
	}
	
	/**
	 * @description Redirects the user to loginPage if loginRedirection is true,
	 * else gets the access_tokens by making the /auth API call (carrier specific).
	 */
	const { routes: carrierRoutes = [] } = config;
	const { IS_SSO_IDP_INITIATED_FLOW } = ssoAuthConfig;
	const isIdpInitiatedScenario = IS_SSO_IDP_INITIATED_FLOW && (pathname.includes('/login') || (pathname.includes('ssodone')));
	const isSpInitiatedScenario = !IS_SSO_IDP_INITIATED_FLOW && !pathname.includes('/login') && !accessToken;

	dispatch({
		type: SET_SSO_LOGIN_CONFIG,
		payload: ssoLogin
	})

	if (loginRedirection) {
		dispatch(setDestinationURL(URL));
		dispatch(getAuthQuestionSet(mockAuthQuestions));
	}
	else if(ssoLogin.enable && (isIdpInitiatedScenario || isSpInitiatedScenario)) {
		ssoAuth(store,config,ssoAuthConfig);
		return;
	}
	else if (!accessToken && !ssoLogin.enable) {
		dispatch(triggerAuthAction());
	} else {
		if (sessionId) {
			dispatch(
				getNextQuestionSet('/questions', {
					session_id: sessionId,
				}),
			);
			return;
		}
		/**
		 * if URL does not contain URL, then it's a new application.
		 * Change it to /questions connect with APIs
		 */
		if (urlUid) {
			if (urlUid !== uid) {
				if(event && event.search('ttl_expired') < 0) {
						dispatch(getNextQuestionSet('/questions', {
							uid : urlUid,
							event,
							envelop_id: envelopId,
							next_page_flag: 1,
						}));
					}
					else {
						const queryParams = queryString.parse(search);
						if(queryParams.m && queryParams.f) {
							const payload = [{ question_id: 'm', answer: queryParams.m }, 
								{ question_id: 'f', answer: queryParams.f }];
							dispatch(
								getNextQuestionSet('/questions', {
									uid: urlUid,
									next_page_flag: 1,
									external_redirection: 1,
									questions: payload,
								}),
							);
						} else {
							dispatch(
								getNextQuestionSet('/questions', {
									uid: urlUid,
								}),
							);
						}
					}
			}
		} else if (pathname === '/init' || pathname.includes('/login') || 
			(pathname ==='/' && window.location.href.includes('/?challenge_id'))) {
			dispatch(getNextQuestionSet('/questions', {}, {}));
		} else if (carrierRoutes.includes(pathname)) {
			const queryParams = queryString.parse(search);
			const params = {
				isEmployee: ['/employee', '/employee/'].includes(pathname),
				route: pathname,
				...queryParams,
			}
			router(dispatch, null, false, null);
			dispatch(getNextQuestionSet('/questions', { ...params }, {}));
		}
	}
}
