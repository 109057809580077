/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import {
	getSectionHeader,
	getSectionMemo,
	getErrorDetail,
} from '../../redux/selector/questions';
import SectionHeader from '../../cruxComponents/SectionHeader';
import CollapsedCard from '../UIElements/Card/CollapsedCard';
import ActionButtons from './ActionButtons';
import { singleButtonPage } from '../../utils';
import Questions from './Questions';
import ReviewQuestions from './ReviewQuestions';
import FooterBanner from '../UIElements/FooterBanner';

function QuestionLibrary(props) {
	const {
		sectionData,
		sectionTitle,
		sectionSubTitle,
		isSmallScreen,
		pageId,
		errors,
		layout,
		processing,
		reviseButtonLabel,
		sectionIndex,
		footerBanner,
		primaryRoute,
		theme,
	} = props;
	const [editFlag, setEditFlag] = useState([]);
	const [mode, setMode] = useState('');

  	useEffect(() => {
		const updateEditFlag = Array(sectionData.length);
		// Make sure the last section is expanded by default
		updateEditFlag[sectionData.length - 1] = true
		setEditFlag(updateEditFlag);
	}, [sectionData]);

	const collapseCard = (index)=>{
		// make sures current section does not collapse
		if(isSmallScreen && index < sectionData.length-1) {
			const updateEditFlag = Array(sectionData.length);
			setEditFlag(updateEditFlag)
		}
	}

	const editClicked = (index, value) => {
		const newEditFlag = Array(sectionData.length);
		newEditFlag[index] = value;
		setEditFlag(newEditFlag);
	};

	const renderSectionContent = (sectionData) => {
		return sectionData.map((section, index) => {
			if (
				!singleButtonPage(pageId) 
				&& isSmallScreen 
				&& section.enableContinue
				&& !editFlag[index]
			)
				return (
					<CollapsedCard
						// eslint-disable-next-line react/no-array-index-key
						key={ index }
						onClick={ () => editClicked(index, true) }
						sectionIndex={ index }
					>
						{/* the section header will be changed in future */}
						<strong>{section.questionList[0].question_text}</strong>
					</CollapsedCard>
				);
			if (pageId.indexOf('review') > -1)
				return (
					<ReviewQuestions
						sectionData={ section }
						index={ index }
						processing={ processing }
						isSmallScreen
					/>
				);
			return (
				<Questions
					sectionData={ section }
					errors={ errors }
					index={ index }
					onContinue={ collapseCard }
					processing={ processing }
					changeMode={ (newMode) => setMode(newMode) } 
					isSmallScreen
				/>
			);
		})
	};

	return (
		<>
			<SectionHeader
				pageId={ pageId }
				layout={ layout }
				name="section-header"
				title={ sectionTitle }
				subTitle={ sectionSubTitle }
				reviseButtonLabel={ reviseButtonLabel }
				sectionData={ sectionData }
				sectionIndex={ sectionIndex }
				
			/>
			{ renderSectionContent(sectionData) }
			<ActionButtons { ...props } mode={ mode } onContinue = { collapseCard } />
			{footerBanner && <FooterBanner footerBanner={ footerBanner } theme={ theme } primaryRoute={ primaryRoute } />}
		</>
	);
}

const mapStateToProps = (state, props) => {
	const sectionMem = getSectionMemo(state);
	const sectionData = sectionMem(props);
	const { sectionTitle, sectionSubTitle } = getSectionHeader(
		sectionData,
	);
	let errors = getErrorDetail(
		sectionData,
	);

	if (!errors.hasError) {
		const { actionMessage } = state.questions;
		if (actionMessage && actionMessage.message) {
			errors = {
				hasError: true,
				errorMessage: actionMessage.message,
			}
		}
	}
	const reviseButtonLabel = get(state,'questions.reviseButtonLabel',null);
	const footerBanner = get(state,'questions.footerBanner',null);
	const activeSection = sectionData && sectionData.length ? sectionData[0] : {};
	const primaryRoute = get(activeSection, 'otherParams.route');

	return {
		sectionData,
		sectionTitle,
		sectionSubTitle,
		isSmallScreen: state.meta.isSmallScreen,
		errors,
		reviseButtonLabel,
		footerBanner,
		primaryRoute,
	};
};
export default withRouter(
	connect(mapStateToProps, null)(QuestionLibrary),
);
