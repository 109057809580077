const Traits = {
    EMAIL : 'email',
    ROLE : 'role',
    USERID : 'salesforce_user_id',
    AGENTNUMBER : 'agentNumber',
    FIRSTNAME : 'firstName',
    LASTNAME : 'lastName',
    PHONE: 'phone'
}

export const traitsAlias = {
    "phone" : "agentPhone",
    "agentNumber": "agentNumber",
    "email": "agentEmail",
    "firstName": "agentFirstName",
    "lastName": "agentLastName",
    "role": "role",
    "salesforce_user_id": "salesforce_user_id"
}

export const RoleMap = {
    A:'agent',
    C:'customer',
}
export { Traits as default }