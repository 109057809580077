/* eslint-disable import/no-extraneous-dependencies,react/no-danger */
import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import TEXT_STYLE_MAP from '../../../constants/textStyleMap';
import { parseHtml } from '../../../utils';

const H1 = styled.h1`
  color: ${({ color }) => color};
  font-size: 30px;
  margin-bottom: 30px;
`;

const H2 = styled.h1`
  color: ${({ color }) => color};
  font-size: 24px;
  margin-bottom: 10px;
`;

const H3 = styled.h3`
  color: ${({ color }) => color};
  font-size: 18px;
  margin-bottom: 60px;
`;

const Error = styled.h6`
  color: ${({ color }) => color || 'rgb(255, 0, 0, 1)'};
  font-size: ${({ fontSize }) => fontSize || '16px'};
`;

const Label = styled.div`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  /* font-weight: bold; */
  line-height: 21px;
  margin-bottom: ${({ margin }) => margin || '15px'};
`;

const TextElement = (props) => {
	const { text, styleType, handlePopup, fontFamily, fontSize, color } = props;
	const { headingColor } = get(props, 'theme.config.theme.global.text');
	const { errorMessageColor, errorFontSize } = get(props, 'theme.config.theme.global');

	if (!text) {
		return null;
	}
	switch (TEXT_STYLE_MAP[styleType]) {
		case 'H1':
			return (
				<H1 color={ headingColor }>
					<span>{parseHtml(text, handlePopup)}</span>
				</H1>
			);
		case 'H2':
			return (
				<H2 color={ headingColor }>
					<span>{parseHtml(text, handlePopup)}</span>
				</H2>
			);
		case 'H3':
			return (
				<H3 color={ headingColor }>
					<span>{parseHtml(text, handlePopup)}</span>
				</H3>
			);
		case 'LABEL':
			return (
				<Label color={ color } fontSize={ fontSize } fontFamily={ fontFamily }>
					<span>{parseHtml(text, handlePopup)}</span>
				</Label>
			);
		case 'ERROR':
			return (
				<Error color={ errorMessageColor } fontSize={ errorFontSize }>
					<span>{parseHtml(text, handlePopup)}</span>
				</Error>
			);
		case 'NORMAL':
		default:
			return (
				<p>
					<span>{parseHtml(text, handlePopup)}</span>
				</p>
			);
	}
}

TextElement.propTypes = {
  styleType: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default withTheme(TextElement);
