import React from 'react';
import get from 'lodash/get'
import { Button } from 'crux';
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import styled, { withTheme } from 'styled-components';
import { 
  getEnableContinueBreadCrumb, 
  getLoaders,
  getContinueState,
} from '../../redux/selector/validations';
import { 
  getNextPage, 
  submitAuthQuestion, 
  submitSectionQuestion, 
  goToPreviousBreadCrumb,
} from '../../redux/actions/questions';
import { 
  addListItem, 
  submitListAnswers,
} from '../../redux/actions/listQuestion';
import { 
  getBreadCrumbInfo, 
  getCurrentQuestionSet,
} from '../../redux/selector/questions';
import { 
  singleButtonPage, 
  buttonLabelMap,
  scrollTop,
} from '../../utils';
import { getListProps } from '../../redux/selector/listQuestion';

const ButtonWrapper = styled.div`
	margin-left: 8px;
`;

const FullWidthButton = styled.div`
  display: flex;
  ${({ fullWidthBtnTheme }) => fullWidthBtnTheme || 'flex-direction: row;'}
	width: 100%;
	& > button {
    width: ${({ fullWidthBtnTheme }) => fullWidthBtnTheme ? fullWidthBtnTheme.width : '100%' };
		max-width: 560px;
	}
  margin-left: 20%;
`;

const SubSectionButton = styled.div`
  display: flex;
  position:relative;
  flex-direction:column;
  align-items:flex-end;
  flex: ${({ isUpdateMode }) => (isUpdateMode ? 4 : 8)};
`;

const PageButton = styled.div`
  max-width: ${props => props.layout === 'double' ? '780px' : 'initial'};
  width:  ${props => (props.layout === 'double' || props.pageId === 'quote') ? '90%' : '80%'};
  margin: 0;
  margin-left: ${props => props.marginLeft};
  background: #ffffff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  @media (max-width: 780px) {
    width: 100%;
    margin-left: 0%;
  }
  @media (min-width: 1310px) {
    width:  ${props => props.layout === 'double' ? '90%' : (props.pageId === 'quote' ? '780px' : '80%')};
  }
`;

const SubSection = styled.div`
  display: flex;
  position:relative;
  flex-direction:row;
  align-items:flex-end;
  flex:1;
`;

const SubSectionLink = styled.div`
  display: flex;
  position:relative;
  flex-direction:column;
  align-items:flex-start;
  flex: ${({ isUpdateMode }) => (isUpdateMode ? 4 : '0 auto')};
`;

const ActionButtons = (props) => {
  const {
    pageId,
    actionProps,
    enableContinue,
    handleAuthSubmit,
    handleNextButton,
    handleSectionContinue,
    sectionContinue,
    isList,
    enableAdd,
    handleListAdd,
    handleListSubmit, 
    handleEndSession,
    processing, 
    currentStatus,
    isLastSection, 
    handleBackLink, 
    otherParams, 
    isUpdateMode,
    prevButtonText,
    nextButtonText,
    nextButtonDescription,
    redirectUrl,
    listId,
    prevBtnScrollTop,
    sectionData,
    questionList,
    listAddButtonLabel,
    theme,
    initialSectionQuestionId,
    ...otherProps
  } = props

  // Label Config for page with single button
  const getSingleButtonLabel = () => {
		if (processing) return 'PROCESSING...';
		if (nextButtonText) return nextButtonText;
		return buttonLabelMap[pageId]
  };
  
  const getButtonLabel = () => {
    if (processing) return 'Processing';
    const { location : { pathname } } = otherProps;
    if(pathname.includes('product details')) return 'Accept and continue';
   /*  if (isLastSection && 
      currentStatus === 'questions') return 'Continue'; */
    if (nextButtonText) return nextButtonText;
    return 'Continue';
  }

  const isListAddorEditMode = () => {
      if(questionList && Array.isArray(questionList) && questionList.length > 1){
        const listQ = questionList.filter(q => q.display_type === 'list');
        if (listQ.length) {
          const childQuestionsCompletedFlag = get(listQ[0], 'child_questions_completed_flag');
          const isEditMode = props.mode === 'listEdit';
          return !childQuestionsCompletedFlag || isEditMode;
        }
      }
    return false;
  }

  const nextButtonHide = get(otherParams, 'is_next_btn_hidden', false);
  let backButtonHide = get(otherParams, 'is_prev_btn_hidden', false) || isListAddorEditMode();
  const primaryButton = get(props, 'theme.config.theme.global.primaryButton');
  const colorOverride = get(props, 'theme.config.theme.global.colorOverride', null );
  const marginLeftValue = get(props, 'theme.config.theme.components.card.marginLeft', 'auto');
  const themeConfiguration = colorOverride ? {
    selectedBgColor: colorOverride.selectedBgColor,
    bgColor: '#ffffff',
    border: `1px solid ${colorOverride.selectedBgColor}`,
    fontColor: '#333333',
    borderRadius: '19px',
  } : null;
  let backButtonThemeConfig = null;
  if(colorOverride.backButtonColor && !backButtonHide) {
    backButtonThemeConfig =  {
      ...themeConfiguration,
      selectedBgColor : colorOverride.backButtonColor,
  }
}
  // Full Button theme & Description theme
  const { pageOverrides } = get(props, 'theme.config.theme', {});
  const fullWidthBtnTheme = pageOverrides[pageId] ? pageOverrides[pageId].fullWidthButton : {};
  const buttonDescriptionTheme = get(props, 'theme.config.theme.global.buttonDescriptionTheme')

  // Config to handle signature page button show/hide condition
  if (currentStatus === 'signature') {
    const signatureConf = get(props, 'theme.config.theme.pageOverrides.signature');
    backButtonHide = signatureConf.is_prev_btn_hidden;
  }

  // Handles prev button action
  let prevButtonAction = handleBackLink;
  if (prevBtnScrollTop) prevButtonAction = scrollTop;

  // Handles Action Buttons
  if (!isEmpty(actionProps)) {
		return (
			<>
				<ButtonWrapper>
					<Button 
						handleButtonClick={ handleSectionContinue }
            actionProps={ actionProps }
            color={ primaryButton.color }
            disabled={ !sectionContinue || processing }
            themeConfiguration={ themeConfiguration }
            { ...otherProps }
					/> 
				</ButtonWrapper>
			</>
		);
  }
  
  // Single Button Display Exception Cases
  // Task - https://sureify.atlassian.net/browse/AS-577
  const singleButtonExceptions = () => {
    if (pageId === 'quote' && !backButtonHide) return true;
    return false;
  }

  // Configs for handling pages with single button
  if (singleButtonPage(pageId) && !singleButtonExceptions() && pageId !== 'review') {
		return (
			<FullWidthButton name="full-width-button" fullWidthBtnTheme={ fullWidthBtnTheme }>
				<Button
					label={ getSingleButtonLabel() }
          btnType="rectangle"
          color={ primaryButton.color }
					disabled={ !enableContinue || processing }
					handleButtonClick={ () => {
						if (pageId === 'login') {
							handleAuthSubmit();
						} else if (pageId === 'review') {
							handleNextButton(pageId);
						} else if(pageId === 'signature' && redirectUrl){
							window.location.replace(redirectUrl)
						} else {
							handleSectionContinue()
						}
          } }
          themeConfiguration={ themeConfiguration }
          description={ nextButtonDescription }
          buttonDescriptionTheme={ buttonDescriptionTheme }
				/>
			</FullWidthButton>
		);
  }
  
  // Handles List Add Button
  if (listId) {
    // for Nationwide we use 'listAddButtonLabel' that is sent from Back end
    const defaultButtonText = listId.match(/primarybeneficiarygroup/i) ? 'Add beneficiary' : 'Add';
		return (
      <Button
        label={ listAddButtonLabel || defaultButtonText }
        type="subsection"
        disabled={ !enableAdd }
        handleButtonClick={ handleListAdd }
        themeConfiguration={ themeConfiguration }
      />
		);
	}

  const { config : { layout } } = theme;
  let _pageId = pageId;
  if(_pageId === 'q') {
    _pageId = 'questions';
  } 
  const pageLayout = layout && layout[_pageId];

  if (!nextButtonHide || !backButtonHide || isUpdateMode)
    return (
      <PageButton name="pagebutton" layout={ pageLayout } marginLeft={ marginLeftValue } pageId={pageId}>
        <SubSection name='subsection'>
          <SubSectionLink name='link' isUpdateMode={ isUpdateMode }>
            {isUpdateMode && pageId !== 'review' ? (
              <Button
                color='red'
                label='Cancel'
                disabled={ processing }
                type='cancel'
                handleButtonClick={
                  isList
                    ? () => handleListSubmit('CANCEL_UPDATE')
                    : () => handleSectionContinue('CANCEL_UPDATE')
                }
              themeConfiguration={ themeConfiguration }
              />
            ) : (
              !backButtonHide && (
                <Button
                  shape='rounded'
                  label={ prevButtonText }
                  type='text'
                  color={ primaryButton.color }
                  handleButtonClick={ prevButtonAction }
                  themeConfiguration={ backButtonThemeConfig || themeConfiguration }
                />
              )
            )}
          </SubSectionLink>
          <SubSectionButton
            name="page-section-button"
            id='page-section-button'
            isUpdateMode={ isUpdateMode }
          >
            <div>
              {isUpdateMode && pageId !== 'review' ? (
                <Button
                  label='Update'
                  type='subsection'
                  disabled={ !enableContinue || processing }
                  themeConfiguration={ themeConfiguration }
                  handleButtonClick={
                    isList
                      ? () => handleListSubmit('CONFIRM_UPDATE')
                      : () => handleSectionContinue('CONFIRM_UPDATE')
                  }
                />
              ) : (
                !nextButtonHide && (
                  <Button
                    shape="rectangle"
                    label={ getButtonLabel() }
                    color={ primaryButton.color }
                    themeConfiguration={ themeConfiguration }
                    handleButtonClick={
                      ()=>{
                        if(otherParams?.next_button_action==='BROWSER_CLOSE'){
                          handleEndSession()
                        }  
                        else if(isList){
                          handleListSubmit('')
                        }
                        else{
                          handleSectionContinue('')
                        }
                      }
                    }
                    disabled={ !enableContinue || processing }
                  />
                )
              )}
            </div>
          </SubSectionButton>
        </SubSection>
      </PageButton>
    );
  return null;
};

const mapStateToProps = (state, props)=> {
  const { isLoading, loadingSection } = getLoaders(state);
  const { otherParams, questionList } = getCurrentQuestionSet(state);
  const getCurrentBreadCrumb = getBreadCrumbInfo(state)
  const listSelector = getListProps(state);
  const { activeSubsectionId } = state.questions;

  const { sectionData } = props  
  const sectionIndex = sectionData ? 
    sectionData.length -1 : 
    props.sectionIndex;

  // If id is not present then takes the path
  const id = get(props, 'match.params.id', null) || get(props,'match.url','').replace('/','')

  // Section / Breadcrumb Level Continue Status
  const { enableContinue: sectionContinue } = getContinueState(state, sectionIndex, activeSubsectionId) 
  const enableContinue = getEnableContinueBreadCrumb(state, id, props.mode)

  const currentStatus = get(otherParams, 'current_status', '')
  const showEditPageFlag = get(otherParams, 'show_edit_page_flag', false)
  const prevButtonText = get(otherParams, 'prev_button_text', 'Back');
  const nextButtonText = get(otherParams, 'next_button_text', '');
  const redirectUrl = get(otherParams, 'next_button_redirect_url', '');
  
  // Config for Button Events (e.g scroll to top on click)
  let prevBtnScrollTop = false;
  const prevButtonEvent = get(otherParams, 'buttons.prev.event');
  if (prevButtonEvent) {
    const { type, scrollTo } = prevButtonEvent;
    if (type === 'scroll' && scrollTo === 'top') {
      prevBtnScrollTop = true;
    }
  }

  // single button description
  const nextButtonDescription = get(otherParams, 'buttons.next.description', '');

  const currentBreadcrumb = getCurrentBreadCrumb(id);
  let total = 0;
  let currentSection = 0;

  if (isPlainObject(currentBreadcrumb) && currentBreadcrumb.section_progress) {
    total = currentBreadcrumb.section_progress.total_sections;
    currentSection = currentBreadcrumb.section_progress.current_section_number;
  }

  const { isList, listDisplay } = listSelector(sectionIndex);
  const isUpdateMode = showEditPageFlag

  return {
      enableContinue,
      sectionContinue, 
      isList,
      listDisplay,
      processing: (isLoading && loadingSection === sectionIndex),
      currentStatus,
      isLastSection: total-1 <= currentSection,
      otherParams,
      isUpdateMode,
      prevButtonText,
      nextButtonText,
      nextButtonDescription,
      redirectUrl,
      prevBtnScrollTop,
      questionList,
  }
}

const mapDispatchToProps = (dispatch, props)=>{
  const { sectionData, onContinue, questionIds, listId } = props
  const sectionIndex = sectionData ? 
    sectionData.length -1 : 
    props.sectionIndex;
  return {
    handleAuthSubmit: () => {
			dispatch(submitAuthQuestion());
		},
    handleSectionContinue: (action) => {
      let actionProps = {};
      if (props.actionProps) actionProps = props.actionProps
      if (onContinue) onContinue(sectionIndex);
      dispatch(submitSectionQuestion(sectionIndex, { ...actionProps, reset_sections_data: true }, action));
    },
    handleListAdd: () => {
      dispatch(addListItem(sectionIndex,  null, questionIds, listId))
    },
    handleListSubmit: (action) => {
      dispatch(submitListAnswers(sectionIndex, action, listId));
    },
    handleBackLink: ()=>{
      dispatch(goToPreviousBreadCrumb(sectionIndex))
    },
    handleNextButton: () => {
			dispatch(getNextPage({
				next_page_flag: 1,
				review_completed: true,
			}));
		},
    handleEndSession: () =>{
      setTimeout(()=>{
        // eslint-disable-next-line no-alert,no-undef
        alert('You are closing this browser.')
      },1000)
    },
  }
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(ActionButtons));