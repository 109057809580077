import React from 'react';
import get from 'lodash/get';
import { Modal, Button } from 'crux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { setPopupDetails } from '../../redux/actions/questions';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const FooterNote = styled.div`
  text-align: left;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 20px;
`;

const TooltipModal = ({ popupDetails, updatePopupDetails }) => {
  const { question } = popupDetails;
  const {
    question_text: heading,
    question_description: body,
    footer_note: footerNote,
  } = question;

  const onModalClose = () => {
    updatePopupDetails({
      ...popupDetails,
      show: false,
    });
  };

  const modalFooterComponent = (
    <>
      <hr />
      <ButtonWrapper onClick={ onModalClose } >
        <Button shape='rounded' label='Close' width='300px'/>
      </ButtonWrapper>
      { footerNote && <FooterNote dangerouslySetInnerHTML={ { __html: footerNote } } /> }
    </>
  );

  return (
    <Modal
      modalHeader={ heading }
      modalBody={ body }
      modalFooter={ modalFooterComponent }
      handleClose={ onModalClose }
      overlayBgColor='#013b5b'
    />
  );
};

const mapStateToProps = (state) => {
  const popupDetails = get(state, 'questions.popupDetails', { show: false, question: '' });
  return {
    popupDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePopupDetails: (payload) => {
			dispatch(setPopupDetails(payload));
		},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TooltipModal);