import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ data,last }) => (
	<div className="footer-links footer-links-alignment">
		<a href={ data.linkUrl } title={ data.linkText } rel="noopener noreferrer" target="_blank">
			{ data.linkText }
		</a>
		{!last && <span className="divider"> |</span>}
	</div>
);
export default function FooterLinks({ links }) {
	return (
		<div className="footer-links-container">
			{ links.map((link,idx) => <Link href= ' ' key={ link.id } data={ link } last={ idx===links.length-1 }/>) }
		</div>
	);
}

FooterLinks.propTypes = {
	links: PropTypes.arrayOf(PropTypes.object).isRequired,
};
