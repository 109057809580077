import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import get from 'lodash/get';
import Breadcrumb, { Size, State as BreadcrumbState } from './index';
import {
  smCardStyles,
  mdCardStyles,
  lgCardStyles,
  bnCardStyles,
} from './breadcrumbStyles';
import { setCurrentBreadcrumb } from '../../../redux/actions/questions';
import GlobalProgress from '../GlobalProgress';
import { getNextQuestionSet } from '../../../redux/actions';

const CrumbsWrapper = styled.div`
	padding: 0px 30px 0px 30px;
	margin-top: ${({ override }) => override ? '0' : '120px'};
	background: ${({ override, complete }) => override && complete ? '#ffffff' : 'transparent'};
	display: flex;
	flex-direction: column;
  align-items: center;
	${({ isSmallScreen }) => !isSmallScreen && `
	  position: sticky;
	  top: 25px;`
  };
	@media (max-width: 767px) {
		padding: ${({ size }) => (size === Size.B ? '0' : '0 30px')};
		padding-bottom: ${({ override }) => override ? '20px' : '0'};
	}
`;

const progressLabelText = (state) => {
  let labelText = '';
  if (state === BreadcrumbState.ACTIVE) labelText = 'In Progress';
  else if (state === BreadcrumbState.INCOMPLETE)
    labelText = 'Not yet Started';
  else if (state === BreadcrumbState.COMPLETED)
    labelText = 'Completed!';
  else labelText = '';
  return labelText;
};

const getTheme = (size) => {
  switch (size) {
    case Size.S:
      return smCardStyles;
    case Size.M:
      return mdCardStyles;
    case Size.L:
      return lgCardStyles;
    case Size.B:
      return bnCardStyles;
    default:
      return smCardStyles;
  }
};

const isActiveState = (state, active) => {
  if (typeof active === 'undefined') {
    return state === BreadcrumbState.ACTIVE;
  }
  return active;
};

/**
 * @description returns the image path of the breadcrumbs if sent from backend else.
 * Precedence: 1. active or inactive urls are present 2. normal image url
 * use local.
 * @param breadcrumb
 * @returns {*|string}
 */
const getImageUrl = (breadcrumb) => {
  const {
    state,
    img_url: imgUrl,
    active_img_url: activeImgUrl,
    inactive_img_url: inactiveImgUrl,
  } = breadcrumb;

  /**
   * For active and complete - return activeImgUrl
   * For incomplete - return inactiveImgUrl
   */
  if (activeImgUrl && inactiveImgUrl) {
    return state === BreadcrumbState.INCOMPLETE
      ? inactiveImgUrl
      : activeImgUrl;
  }

  // otherwise return imgUrl with a fallback for image stored on the front end server.
  return imgUrl || '/images/svg/placeholder.svg';
};

const BreadcrumbWrapper = (props) => {
  const {
    breadcrumbs,
    imgUrl,
    size,
    uid,
    makeQuestionsCall,
    pageSeqNumber,
    onClick,
    override,
    complete,
    isSmallScreen,
  } = props;
  
  const availableBreadcrumbs = (breadcrumbs && breadcrumbs.filter(crumb => crumb.show)) || [];
  
  const breadcrumbClickHandler = (id, breadcrumbId) => {
		const params = {
      uid,
      breadcrumb_id: breadcrumbId,
      breadcrumb_nav_flag: true,
      page_sequence_number: pageSeqNumber,
		};
		makeQuestionsCall(params, id);
  };
  
  return (
    <CrumbsWrapper
      name="crumbs-wrapper"
      size={ size }
      override={ override }
      complete={ complete }
      isSmallScreen={ isSmallScreen }
    >
      {!override && <GlobalProgress/>}
      {availableBreadcrumbs.map((breadcrumb) => (
        <Fragment key={ breadcrumb.id }>
          <Breadcrumb
            override={ override }
            id={ breadcrumb.id }
            breadcrumb={ breadcrumb }
            imgUrl={ getImageUrl(breadcrumb) || imgUrl || 'https://bit.ly/38R2acR' }
            isActive={ isActiveState(breadcrumb.state, breadcrumb.active) }
            isComplete = { breadcrumb.clickable }
            theme={ getTheme(size) }
            size={ size }
            progressLabel={ progressLabelText(breadcrumb.state) }
            onClick={ size === Size.B ? onClick : () => breadcrumbClickHandler(breadcrumb.id, breadcrumb.breadcrumb_id) }
          />
        </Fragment>
      ))}
    </CrumbsWrapper>
  );
};

const mapStateToProps = ({ questions, meta, uid }, { overrideBreadcrumbs, complete }) => {
  const pageSeqNumber = get(questions, 'currentPageSeqNumber', -1);
  let breadcrumbs = questions.breadcrumbs.breadcrumbList;
  if (overrideBreadcrumbs && overrideBreadcrumbs.length) {
    breadcrumbs = overrideBreadcrumbs;
  }
  return {
    breadcrumbs,
    isSmallScreen: meta.isSmallScreen,
    uid,
    pageSeqNumber,
    override: !!overrideBreadcrumbs,
    complete,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    makeQuestionsCall: (params, id) => {
      dispatch(getNextQuestionSet('/questions', params, {
        ...params,
        breadcrumbNavigation: true,
      }));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbWrapper);
