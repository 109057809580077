/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { getLabelProps, shouldShowPageInfo } from './utils';
import { validateInnerHtml } from '../utils';
import ReviseButton from '../Components/UIElements/ReviseButton';

const Heading = styled.div`
  position: relative;
  margin-top: ${({ layout }) => layout === 'single' ? '0' : '140px'};
  font-size: 28px;
  color: ${({ color }) => color};
  line-height: ${(props) => props.lineHeight || '36px' };
  @media (min-width: 1281px) {
    position: relative;
    color: ${({ color }) => color};
    line-height: 36px;
    font-weight: ${(props) => props.fontWeight || 600};
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    position: relative;
    color: ${({ color }) => color};
    line-height: 36px;
    font-weight: 600;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    position: relative;
    color: ${({ color }) => color};
    line-height: 36px;
    font-weight: 600;
  }
`;

const Header = styled.div`
  margin-top: 60px;
  margin-bottom: 50px;
  max-width: 80%;
	margin-left: auto;
	margin-right: auto;
  @media (min-width: 767px) {
    margin-top: 120px;
    margin-left: 10%;
  }
  ${props => props.pageId === 'review' && `
    padding: clamp(24px,5%,80px) clamp(16px,5%,100px) clamp(24px,5%,40px) clamp(16px,5%,100px);
    background: #ffffff;
    text-align: center;
  `}
  @media (max-width: 767px) {
    margin-left: ${
      props => (props.layout === 'single' && props.pageId !== 'review') ? '0px' : 'auto'
    }
  }
`;

const SubHeading = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #424242;
  line-height: 26px;
  margin: 1%;
  ${props => props.pageId === 'review' && `
    line-height: 16px;
    & > span {
      font-size: 14px;
    }
`}
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const FakeHeader = styled.div`
	margin-top: 140px;
`;

const SectionHeader = ({ title, subTitle, layout, theme, pageId ,reviseButtonLabel ,sectionData ,sectionIndex }) => {
  const {
    isSmallScreen,
    config: {
    theme: {
      global: {
        showPageInfo,
        text: {
          sectionHeaderColor,
        },
      },
      components,
      pageOverrides,
    },
    customHeader: { isPresent: isCustomHeaderPresent } = {},
  } } = theme;

  const pageOverrideShowPageInfo = shouldShowPageInfo(pageId, pageOverrides);
  const quoteHeaderProps=getLabelProps('quoteHeader',components,pageId,pageOverrides);
  const sectionHeaderStyles = {
    components : {
      SectionHeader,
    },
  }

  if (isSmallScreen && layout === 'double') {
    return null
  }

  if (!pageOverrideShowPageInfo && layout !== 'auto' && !showPageInfo) {
    return <FakeHeader />
  }

  const QuoteHeader = styled.div`
    margin: ${({ margin }) => margin ||'20px auto'};
    max-width: ${({ maxWidth }) => maxWidth || '780px'};
  `;
  const QuoteHeading = styled(Heading)`margin-top: 0`;
  
  if (isCustomHeaderPresent) {
    return (
      <QuoteHeader { ...quoteHeaderProps }>
        { reviseButtonLabel && 
          <ReviseButton 
            reviseButtonLabel={ reviseButtonLabel }
            sectionData={ sectionData }
            sectionIndex={ sectionIndex }
          />
        }
        <QuoteHeading color={ sectionHeaderColor } layout={ layout } name="header">
          <span dangerouslySetInnerHTML={ { __html: validateInnerHtml(title) } } />
        </QuoteHeading>
        <SubHeading name="header">
          <span dangerouslySetInnerHTML={ { __html: validateInnerHtml(subTitle) } } />
        </SubHeading>
      </QuoteHeader>
    );
  }

  if (layout !== 'auto') {
    return (
      <Header name="header" pageId={ pageId } layout={ layout }>
        <Heading color={ sectionHeaderColor } layout={ layout } name="heading"
          fontWeight={ sectionHeaderStyles.fontWeight } lineHeight={ sectionHeaderStyles.lineHeight }>
          <span dangerouslySetInnerHTML={ { __html: validateInnerHtml(title) } } />
        </Heading>
        <SubHeading name="sub-heading" pageId={ pageId }>
          <span dangerouslySetInnerHTML={ { __html: validateInnerHtml(subTitle) } } />
        </SubHeading>
      </Header>
    );
  }

  return null;
}

SectionHeader.propTypes = {
  pageId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(SectionHeader);
