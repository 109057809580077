const TEXT_STYLE_MAP = {
  'breadcrumb': 'H2',
  'questions_group': 'H1',
  'list': 'H3',
  'error': 'ERROR',
  'normal': 'NORMAL',
  'label': 'LABEL',
  'heading': 'H2',
};

export default TEXT_STYLE_MAP;
