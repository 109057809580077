import get from 'lodash/get';

export const test = '';

export const getContinueState = (state, sectionIndex, currentBreadcrumbId) => {
	const { questions } = state;
	const currentSection = questions.sections[currentBreadcrumbId];
	if (currentSection && currentSection.length) {
		const currentQuestions = currentSection[sectionIndex];
		const { enableContinue, completed } = currentQuestions;
		return {
			enableContinue,
			completed,
		};
	}
	return {
		enableContinue: false,
		completed: false,
	};
};

export const getPopupQuestions = (state) => {
	const { questions } = state;
	return get(questions, 'popupQuestions', []);
}

export const getEnableAdd = (state, sectionIndex, currentBreadcrumbId, questionIds) => {
	const { questions } = state;
	const currentSection = questions.sections[currentBreadcrumbId];
	if(!currentSection) {
		return false;
	}
	const currentQuestions = currentSection[sectionIndex];
	const { answersArray, errorsArray } = currentQuestions
	const enableAdd = questionIds && !errorsArray.length && 
		questionIds.every(id => answersArray.find(ans => ans.question_id === id && (ans.answer || ans.answer === 0)));
	return enableAdd;
};

export const getLoaders = (state) => {
  const { questions } = state;
  const { loadingSection, isLoading } = questions;
  return { loadingSection, isLoading };
}

export const getEnableContinueBreadCrumb = (state, currentBreadcrumbId, mode) => {
  if(mode === 'listEdit'){
	  return false;
  }
  const { questions, nList } = state;
  const { requiredSchema, enableAdd } = nList;
  const currentSection = questions.sections[currentBreadcrumbId];
  const enableContinue = currentSection && currentSection.reduce((acc, section) => acc && section.enableContinue, true)  
  if (requiredSchema.length) {
	return enableAdd && enableContinue
  }
  return enableContinue;
}
