const SET_SMALL_SCREEN = 'SET_DISPLAY_WIDTH';
const SET_LIST_RELATION = 'SET_LIST_RELATION';

const setSmallScreen = (payload) => ({
  type: SET_SMALL_SCREEN,
  payload,
})

const setListRelation = (payload) => ({
  type: SET_LIST_RELATION,
  payload, 
});

export {
  SET_SMALL_SCREEN,
  setSmallScreen,
  SET_LIST_RELATION,
  setListRelation,
}
