/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RadioGroup, Tooltip } from 'crux';
import styled, { withTheme } from 'styled-components';

import get from 'lodash/get';
import TextElement from '../Components/UIElements/TextElement';
import { questionType } from '../types';

import { getShape, getWidth, getTooltipProps, getLabelProps } from './utils';
import { updateAnswersArray } from '../redux/actions';
import { setSingleSelectFocus } from '../redux/actions/questions';
import SelectWrapper, { TooltipWrapper } from './SelectWrapper';


const ErrorMessage = styled.div`
  color: ${({ color }) => color || '#ff0000'};
  font-size: ${({ fontSize }) => fontSize || '12px'}
  padding: 0.25em;
  width: 100%;
`

const showErrorQuestionIds = ['ReplaceCoverage', 'ReplaceInsuranceOrAnnuityCA'];
const showErrorOptionIds = ['ReplaceCoverageYes', 'ReplaceInsuranceOrAnnuityCAYes'];

const SingleSelect = ({
	question,
	theme,
	sectionIndex,
	handleChange,
	isEditMode,
	setSingleSelectFocus,
	shoudlFocusSingleSelect
}) => {
	const {
		display_type: displayType,
		question_id: questionId,
		response_options: options,
		question_text: questionText, 
		hint_title: tooltipTitle,
		hint_text: tooltipBody,
		hint_html: tooltipHTML,
		is_readonly:isReadonly,
		response,
		orientation,
		tooltip,
		validations,
		question_status: questionStatus,
		question_status_message: questionStatusMessage,
	} = question;

	// const checked = isPlainObject(response) ? response.id : response;
	const [showError, setShowError] = useState(false);
	let modifiedQuestionStatus = questionStatus;

	if (showErrorQuestionIds.includes(questionId) &&
		response && showErrorOptionIds.includes(response.id)) {
			modifiedQuestionStatus = 'invalid';
	}

	useEffect(() => {
		if (isEditMode || question.response) {
			handleChange(question.response, true);
		}
		if(question.question_id === 'DesignateSecondaryAddressee'){
			if(shoudlFocusSingleSelect && shoudlFocusSingleSelect.id && shoudlFocusSingleSelect.value){
				const element =  document.getElementById(`${question.question_id}_${shoudlFocusSingleSelect.id}`);
				if(element){
					element.focus();
				}
			}
		}
	}, []);

	const {
		pageId,
		config: {
			theme: {
				pageOverrides,
				components,
				global: { colorScheme, colorOverride, labelStyles, errorMessageColor, errorFontSize },
			},
		},
	} = theme;
	const { singleSelect } = components;
	const { shape, overlap, labelWidth } = getShape({
		questionId,
		displayType,
		singleSelect,
	});

	const tooltipProps = getTooltipProps(tooltipTitle, tooltipBody, tooltipHTML || tooltip);

	const labelProps = getLabelProps(
		'singleSelect',
		components,
		pageId,
		pageOverrides,
	);

	const themeConfiguration = colorOverride ?  {
		...colorOverride,
	}: null;
	
	const errorMsg = (questionStatus === 'invalid') ? questionStatusMessage : get(validations, 'required.error_message', '');

	return (
		<SelectWrapper width={ getWidth('singleSelect', pageId, pageOverrides) }>
			<TooltipWrapper>
				<TextElement
					styleType="label"
					text={ questionText }
					sectionIndex={ sectionIndex }
					fontFamily={ labelStyles && labelStyles.fontFamily }
					fontSize={ labelStyles && labelStyles.fontSize }
					color={ labelStyles && labelStyles.fontColor }
				/>
				{ tooltipProps.hasTooltip && <Tooltip { ...tooltipProps }/> }
			</TooltipWrapper>
			<RadioGroup
				id={ questionId }
				onChange={ (e, option) => {
					if(showErrorOptionIds.includes(option.id) && showErrorQuestionIds.includes(questionId)) {
						setShowError(true)
					} else {
						setShowError(false)
					}
					const bool = showErrorOptionIds.includes(option.id) && showErrorQuestionIds.includes(questionId);
					setSingleSelectFocus(option);
					handleChange(option, false, bool, true);
				} }
				options={ options }
				name={ questionId }
				shape={ shape }
				overlap={ overlap }
				checked={ response ? response.id : '' }
				color={ colorScheme }
				themeConfiguration={ themeConfiguration }
				orientation={ orientation || 'rows' }
				labelWidth={ questionId === 'SelectedCoverage' ? '100%' : labelWidth }
				{ ...labelProps }
				disabled={ isReadonly }
				displayCardHeader={ displayType === 'cards' && Array.isArray(options) && options[0].card_header }
			/>
			{ (showError || modifiedQuestionStatus === 'invalid') && 
				(
				<ErrorMessage 
					id={ `${questionId  }-error-message` }
					data-testid='error-message' 
					color={ errorMessageColor }
					fontSize={ errorFontSize }
				>
					{ errorMsg }
				</ErrorMessage>
				)
			}
		</SelectWrapper>
	);
};

SingleSelect.propTypes = {
	question: questionType.isRequired,
	sectionIndex: PropTypes.number.isRequired,
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		handleChange: (response, preventReflexiveCall = false, hasError, isChangeEvent) => {
			const questionId = get(props, 'question.question_id', '');
			dispatch(
				updateAnswersArray({
					questionId,
					response: response.id,
					isError: hasError,
					questionResponse: response,
					sectionIndex: props.sectionIndex,
					preventReflexiveCall,
					listParentId: props.listParentId,
					listIndex: props.listIndex,
					isChangeEvent,
				}),
			);
		},
		setSingleSelectFocus: (response) => {
			dispatch(
				setSingleSelectFocus({
					id: response.id,
				}),
			);
		},
	};
};

const mapStateToProps = (state, props) => {
	const shoudlFocusSingleSelect = state.questions.shouldFocusSingleSelect;
	return { shoudlFocusSingleSelect };
}

export default withTheme(
	connect(mapStateToProps, mapDispatchToProps)(SingleSelect),
);
