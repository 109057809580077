/* eslint-disable no-unused-vars */
import React, { useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { getListProps } from '../../redux/selector/listQuestion';
import ScrollToSection from '../../ScrollToSection';
import Cover from '../StyledComponents/MaskCover/Index';
import QuestionRenderer from '../QuestionRenderer';

function Section({
	questionList,
	sectionIndex,
	processing,
	listDisplay,
	isList,
	isReflexive,
	validate,
	pageId,
    breadcrumbNavigation,
    changeMode,
}) {
	let isUpdateMode = false;
	let collapse = true;
	if( questionList && questionList.length > 0) {
	  const question = questionList[0];
	  isUpdateMode = ('collapse' in question);
	  collapse = get(question, 'collapse', false); 
	}
	const skipScroll = isReflexive ||
    validate  ||
    pageId === 'review' ||
    pageId === 'quotereview' ||
    breadcrumbNavigation;
	return (
		<>
      <ScrollToSection
        isUpdateMode = { isUpdateMode }
        collapse = { collapse }
        skipAutoScroll={ skipScroll }
        isList={ isList }
        pageId={ pageId }
        listDisplay={ listDisplay }
        sectionIndex={ sectionIndex }
      />
			{processing && <Cover name="cover" />}
      <Fragment key={ sectionIndex }>
				<QuestionRenderer
					pageId={ pageId }
					questionList={ Array.isArray(questionList) ? questionList : [questionList] }
					sectionIndex={ sectionIndex }
					changeMode={ changeMode }
				/>
		  </Fragment>
    </>
	);
}

const mapStateToProps = (state, props) => {
  const listSelector = getListProps(state);
  const { isList, listDisplay, addButtonText } = listSelector(
    props.sectionIndex,
  );

  const isReflexive = get(state, 'questions.isReflexive', false);
  const validate = get(state, 'questions.validate', false);

  return {
    isList,
    listDisplay,
    isReflexive,
    validate,
    addButtonText,
    breadcrumbNavigation: state.questions.breadcrumbNavigation,
  };
};

export default withRouter(connect(mapStateToProps, null)(Section));
